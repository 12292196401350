import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useCallback } from 'react';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useFlagInputFileForUnmap = (
  projectId: string,
  iModelId: string
): [
  boolean,
  (jobId: string, inputFileId: string) => Promise<ResponseData<null>>
] => {
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = { initialData: null, initialIsLoading: true };
  const [, isLoading, , fetchData] = useDataApi<null>(useDataSettings);

  const flagInputFileForUnmap = useCallback(
    async (jobId: string, inputFileId: string) => {
      const requestData: RequestData<null> = {
        url: `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions('${jobId}')/InputFiles('${inputFileId}')?contextId=${projectId}&iModelId=${iModelId}`,
        method: 'PATCH',
        body: JSON.stringify([
          {
            value: 'UnMap',
            path: '/executionAction',
            op: 'replace',
          },
        ]),
      };

      return fetchData(requestData);
    },
    [fetchData]
  );

  return [isLoading, flagInputFileForUnmap];
};

import { useGraphQl } from '../useGraphQL/useGraphQL';
import { Item } from '../../entities/item';
import { Repository } from '../../entities/repository';
import { ResponseData } from '../useDataApi/requestData';
import { useCallback, useState } from 'react';
import {
  getDocumentServiceRepositoryType,
  getFileExtension,
  useBridgeForFile,
} from '../../services/bridgeLogic/bridgeLogic';
import { BridgeType } from '../../entities/bridge';
import { BridgeSelection } from '../useBridgeSelection/useBridgeSelection';
import {
  AffinityFileType,
  AffinityReport,
  mapProductStampToBridgeType,
} from '../../entities/affinityReport';
import { useGetAffinityReport } from '../useGetAffinityReport/useGetAffinityReport';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';

export interface MasterItem extends Item {
  references: Item[] | undefined;
  masterParentId?: string;
}

export interface MastersMap {
  [key: string]: {
    masterItem: MasterItem;
    bridgeSelection: { [key: string]: BridgeSelection };
  };
}

export interface MasterContext {
  master: MasterItem;
  mastersMap: MastersMap;
}

export const useMastersContext = (
  repository?: Repository,
  initialIsLoading = false
): [
  MasterContext[],
  boolean,
  any,
  Item[],
  (filterString: string) => MasterContext[],
  (
    mastersConext: MasterContext,
    masterId: string,
    selectedId: string,
    bridgeSelection: BridgeSelection
  ) => void,
  (items: Item[]) => void,
  (mastersContext: MasterContext[], bridge: BridgeType) => void,
  (rootItems: Item[]) => void,
  (mastersContext: MasterContext) => void,
  (
    rootItems: Item[],
    selectedMastersContext?: MasterContext[],
    jobDefId?: string,
    isAffinityConnection?: boolean
  ) => Promise<MasterContext[]>,
  () => boolean,
  any
] => {
  const [isLoading, setIsLoading] = useState(initialIsLoading);
  const [gqerror, setGqError] = useState(null);
  const [map, setMap] = useState<MasterContext[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);

  const [affinityReport, , getReport] = useGetAffinityReport();
  const [, , , fetchReferences] = useGetMasterItems(repository);
  const { getAllowedBridgeTypesForFile, getBridgeSelectOptions } =
    useBridgeForFile();
  const { hideFileReferences } = useFeatureToggleContext();

  const setMastersContext = (mastersContext: MasterContext[]) => {
    setMap(mastersContext);
  };

  const fetchTree = useCallback(
    async (
      rootItems: Item[],
      selectedMastersContext?: MasterContext[],
      jobDefId?: string,
      isAffinityConnection?: boolean
    ) => {
      if (repository) {
        setIsLoading(true);
        let mastersContext: MasterContext[] = [];
        let report = null;
        try {
          if (jobDefId && isAffinityConnection) {
            report = await getReport(jobDefId);
            if (report == null || report.data == null) {
              setIsLoading(false);
              return [];
            }
          }

          for (let i = 0; i < rootItems.length; i++) {
            const foundSelectedMasterMap = selectedMastersContext?.find(
              (selectedMasterContext: MasterContext) =>
                selectedMasterContext.mastersMap[rootItems[i].id]
            );

            const fetchedMasters: MastersMap = await fetchMasterReferences(
              rootItems[i],
              {},
              report?.data ? report.data : null
            );
            mastersContext = [
              ...mastersContext,
              {
                master: rootItems[i],
                mastersMap: foundSelectedMasterMap
                  ? foundSelectedMasterMap.mastersMap
                  : treeShakeMasterMap(fetchedMasters),
              } as MasterContext,
            ];
          }
          setMap(mastersContext);
          setIsLoading(false);

          return mastersContext;
        } catch {
          return [];
        }
      }

      return [];
    },
    [repository]
  );

  const fetchMasterItemReferences = async (masterItemId: string) => {
    const referencesReponse: ResponseData<MasterItem> = await fetchReferences(
      masterItemId
    );

    if (
      referencesReponse.data == null ||
      referencesReponse.error ||
      !referencesReponse.ok
    ) {
      setGqError(referencesReponse.error);
      setMap([]);
      setIsLoading(false);

      throw new Error();
    }

    return referencesReponse;
  };

  const fetchMasterReferences = async (
    masterItem: Item,
    mastersMap: MastersMap,
    affinityReport: AffinityReport | null,
    parentId?: string,
    parentBridgeSelectionMap?: BridgeSelection
  ): Promise<MastersMap> => {
    try {
      if (repository) {
        const master = hideFileReferences
          ? {
              ...masterItem,
              references: [],
            }
          : (await fetchMasterItemReferences(masterItem.id))!.data!;

        const masterFileExtension = getFileExtension(master.name);
        const bridgeSelectionMap = {
          [masterItem.id]: {
            selectable: true,
            selectedBridge:
              masterFileExtension === 'dgn' || !parentBridgeSelectionMap
                ? getSelectedBridge(
                    master.name,
                    masterItem.id,
                    AffinityFileType.Master,
                    affinityReport
                  )
                : parentBridgeSelectionMap?.selectedBridge,
            bridges:
              masterFileExtension === 'dgn' || !parentBridgeSelectionMap
                ? getBridgeSelectOptions(master.name, repository)
                : parentBridgeSelectionMap?.bridges,
          },
        };

        const refs = master?.references ?? [];
        let isCircularDependent = false;
        for (const ref of refs) {
          const referenceFileExtension = getFileExtension(ref.name);
          bridgeSelectionMap[ref.id] = {
            selectable:
              referenceFileExtension === 'dgn' && masterFileExtension === 'dgn'
                ? true
                : false,
            selectedBridge:
              (referenceFileExtension === 'dgn' &&
                masterFileExtension === 'dgn') ||
              !parentBridgeSelectionMap
                ? getSelectedBridge(
                    master.name,
                    ref.id,
                    AffinityFileType.Reference,
                    affinityReport
                  )
                : parentBridgeSelectionMap.selectedBridge,
            bridges:
              (referenceFileExtension === 'dgn' &&
                masterFileExtension === 'dgn') ||
              !parentBridgeSelectionMap
                ? getBridgeSelectOptions(master.name, repository)
                : parentBridgeSelectionMap.bridges,
          };

          if (mastersMap[ref.id]) {
            isCircularDependent = true;
          }
        }

        if (parentId) {
          master.masterParentId = parentId;
        }

        // self references
        if (master.references?.map(ref => ref.id).includes(masterItem.id)) {
          master.references = master?.references?.filter(
            ref => ref.id !== masterItem.id
          );
        }

        // circular dependancies
        if (isCircularDependent) {
          master.references = master?.references?.filter(ref => {
            return !mastersMap[ref.id];
          });
        }

        master.references?.sort((a, b) => a.name.localeCompare(b.name));

        mastersMap = {
          ...mastersMap,
          [masterItem.id]: {
            masterItem: master as MasterItem,
            bridgeSelection: bridgeSelectionMap,
          },
        };

        for (let i = 0; i < refs.length; i++) {
          if (refs[i].itemType === 'LogicalSet' && !mastersMap[refs[i].id]) {
            const m = await fetchMasterReferences(
              refs[i],
              mastersMap,
              affinityReport?.Results ? affinityReport : null,
              masterItem.id,
              bridgeSelectionMap[masterItem.id]
            );
            mastersMap = { ...mastersMap, ...m };
          }
        }

        return mastersMap;
      }

      return {} as MastersMap;
    } catch (err) {
      throw new Error();
    }
  };

  const filterMasterContexts = (filterString: string) => {
    let filteredMasterContextDocuments: MasterContext[] = [];
    let filteredMasterContextsLogicalSets: MasterContext[] = [];

    if (filterString) {
      // takes care of non-nested
      filteredMasterContextDocuments = map.filter(
        mc =>
          (mc.master.name
            .toLocaleLowerCase()
            .includes(filterString.toLocaleLowerCase()) ||
            mc.mastersMap[mc.master.id].bridgeSelection[mc.master.id].bridges[
              mc.mastersMap[mc.master.id].bridgeSelection[mc.master.id]
                .selectedBridge
            ]
              .toString()
              .toLocaleLowerCase()
              .includes(filterString.toLocaleLowerCase())) &&
          mc.master.itemType !== 'LogicalSet'
      );

      filteredMasterContextsLogicalSets = JSON.parse(
        JSON.stringify(map.filter(mc => mc.master.itemType === 'LogicalSet'))
      );

      // remove all master contexts or master maps
      // that do not match filter string or that no longer has references
      for (const masterContext of map) {
        const filteredMasterContext = filteredMasterContextsLogicalSets.find(
          mc => masterContext.master.id === mc.master.id
        );

        if (filteredMasterContext) {
          // filter out all the references that do not match the filter string
          Object.entries(filteredMasterContext.mastersMap).forEach(
            ([key, masterMap]) => {
              const references = masterMap.masterItem.references;
              const filteredReferences = references
                ? [
                    ...references.filter(
                      r =>
                        r.name
                          .toLocaleLowerCase()
                          .includes(filterString.toLocaleLowerCase()) ||
                        masterMap.bridgeSelection[r.id].bridges[
                          masterMap.bridgeSelection[r.id].selectedBridge
                        ]
                          .toString()
                          .toLocaleLowerCase()
                          .includes(filterString.toLocaleLowerCase()) ||
                        r.itemType === 'LogicalSet'
                    )!,
                  ]
                : [];

              filteredMasterContext.mastersMap[key].masterItem.references =
                filteredReferences;

              // have the master item become unselectable if it does not match filter string
              if (
                !masterMap.masterItem.name
                  .toLocaleLowerCase()
                  .includes(filterString.toLocaleLowerCase()) &&
                !masterMap.bridgeSelection[masterMap.masterItem.id].bridges[
                  masterMap.bridgeSelection[masterMap.masterItem.id]
                    .selectedBridge
                ]
                  .toString()
                  .toLocaleLowerCase()
                  .includes(filterString.toLocaleLowerCase())
              ) {
                masterMap.bridgeSelection[masterMap.masterItem.id].selectable =
                  false;
              }
            }
          );

          // filter out the master items that do not match
          Object.entries(filteredMasterContext.mastersMap).forEach(
            ([masterKey, masterMap]) => {
              if (
                !masterMap.masterItem.name
                  .toLocaleLowerCase()
                  .includes(filterString.toLocaleLowerCase()) &&
                !masterMap.bridgeSelection[masterMap.masterItem.id].bridges[
                  masterMap.bridgeSelection[masterMap.masterItem.id]
                    .selectedBridge
                ]
                  .toString()
                  .toLocaleLowerCase()
                  .includes(filterString.toLocaleLowerCase()) &&
                masterMap.masterItem.references?.length === 0
              ) {
                delete filteredMasterContext.mastersMap[masterKey];
              }
            }
          );

          // filter out the reference files that references no longer meet criteria
          Object.entries(filteredMasterContext.mastersMap).forEach(
            ([masterKey, masterMap]) => {
              const logicalSetReferences =
                masterMap.masterItem.references?.filter(
                  r => r.itemType === 'LogicalSet'
                );

              if (logicalSetReferences) {
                for (const logicalSetReference of logicalSetReferences) {
                  if (
                    !filteredMasterContext.mastersMap[logicalSetReference.id]
                  ) {
                    filteredMasterContext.mastersMap[
                      masterKey
                    ].masterItem.references = filteredMasterContext.mastersMap[
                      masterKey
                    ].masterItem.references!.filter(
                      r => r.id !== logicalSetReference.id
                    );

                    if (
                      !filteredMasterContext.mastersMap[
                        masterKey
                      ].masterItem.name
                        .toLocaleLowerCase()
                        .includes(filterString.toLocaleLowerCase()) &&
                      !masterMap.bridgeSelection[
                        filteredMasterContext.mastersMap[masterKey].masterItem
                          .id
                      ].bridges[
                        masterMap.bridgeSelection[
                          filteredMasterContext.mastersMap[masterKey].masterItem
                            .id
                        ].selectedBridge
                      ]
                        .toString()
                        .toLocaleLowerCase()
                        .includes(filterString.toLocaleLowerCase()) &&
                      filteredMasterContext.mastersMap[masterKey].masterItem
                        .references?.length === 0
                    ) {
                      delete filteredMasterContext.mastersMap[masterKey];
                    }
                  }
                }
              }
            }
          );

          // filter out master contexts that master maps are empty
          if (Object.keys(filteredMasterContext.mastersMap).length === 0) {
            filteredMasterContextsLogicalSets.splice(
              filteredMasterContextsLogicalSets.indexOf(filteredMasterContext),
              1
            );
          }
        }
      }
    }

    return [
      ...filteredMasterContextDocuments,
      ...filteredMasterContextsLogicalSets,
    ];
  };

  const setBridgeSelection = (
    mastersContext: MasterContext,
    masterId: string,
    selectedId: string,
    bridgeSelection: BridgeSelection
  ) => {
    const mastersContextFromMap = map.find(
      m => m.master.id === mastersContext.master.id
    )!;

    mastersContextFromMap.mastersMap[masterId].bridgeSelection[selectedId] =
      bridgeSelection;

    if (mastersContext.mastersMap[selectedId]) {
      setNonDgnItemsToSameBridgeTypeAsParent(
        mastersContext.mastersMap,
        selectedId,
        bridgeSelection
      );
    }

    mastersContext.mastersMap[masterId].bridgeSelection[selectedId] =
      bridgeSelection;

    map.splice(map.indexOf(mastersContextFromMap), 1, mastersContextFromMap);

    setMap([...map]);
  };

  const setBridgeForSelected = (
    mastersContext: MasterContext[],
    bridge: BridgeType
  ) => {
    mastersContext.forEach((mc, i) => {
      Object.entries(mc.mastersMap).forEach(([masterKey, masterMap]) => {
        Object.entries(masterMap.bridgeSelection).forEach(
          ([refKey, selection]) => {
            if (
              selectedItems.find((si: Item) => si.id === refKey) &&
              selection.selectedBridge !== bridge &&
              selection.bridges[bridge]
            ) {
              mastersContext[i].mastersMap[masterKey].bridgeSelection[
                refKey
              ].selectedBridge = bridge;
            }
          }
        );
      });
    });

    setMap([...mastersContext]);
  };

  const getSelectedBridge = (
    fileName: string,
    itemId: string,
    fileType: AffinityFileType,
    affinityReport: AffinityReport | null
  ): BridgeType => {
    if (repository) {
      if (!affinityReport) {
        return getAllowedBridgeTypesForFile(fileName, repository)[0];
      } else {
        const affinityReportEntry = affinityReport.Results.find(
          ar =>
            ar.Affinity.FileId === itemId && ar.Affinity.FileType === fileType
        );

        if (affinityReportEntry) {
          return mapProductStampToBridgeType(
            affinityReportEntry.Affinity.AuthoringProductStamp
          );
        } else {
          return getAllowedBridgeTypesForFile(fileName, repository)[0];
        }
      }
    }

    return BridgeType.Microstation;
  };

  const addMastersContext = async (rootItems: Item[]) => {
    setIsLoading(true);
    let mastersContext: MasterContext[] = [];

    for (let i = 0; i < rootItems.length; i++) {
      const fetchedMasters: MastersMap = await fetchMasterReferences(
        rootItems[i],
        {},
        affinityReport?.Results ? affinityReport : null
      );

      mastersContext = [
        ...mastersContext,
        {
          master: rootItems[i],
          mastersMap: filterMasterMap(fetchedMasters),
        } as MasterContext,
      ];
    }

    setMap([...map, ...mastersContext]);
    setIsLoading(false);
  };

  const removeMasterContext = (masterContext: MasterContext) => {
    setMap(map.filter(mc => mc.master.id !== masterContext.master.id));
  };

  const isAnyFileSelected = () => {
    return selectedItems.length > 0;
  };

  return [
    map,
    isLoading,
    gqerror,
    selectedItems,
    filterMasterContexts,
    setBridgeSelection,
    setSelectedItems,
    setBridgeForSelected,
    addMastersContext,
    removeMasterContext,
    fetchTree,
    isAnyFileSelected,
    setMastersContext,
  ];
};

export const responseToMasterMap = (response: any): any => {
  return response.data != null
    ? ({
        id: response.data?.item.id,
        name: response.data?.item.name,
        fileSize: response.data?.item.fileSize,
        references: response.data?.item.children,
      } as MasterItem)
    : { error: response.errors[0].message };
};

export const useGetMasterItems = (
  repository?: Repository
): [
  MasterItem,
  boolean,
  any,
  (parentId: string) => Promise<ResponseData<MasterItem>>
] => {
  const [data, isLoading, error, fetchData] = useGraphQl(responseToMasterMap, {
    initialData: {} as MasterItem,
    initialIsLoading: true,
  });

  const fetchFolderItems = useCallback(
    async (parentId: string) => {
      if (repository) {
        const query = getItemsGraphQLQuery(parentId, repository);
        return fetchData(query);
      }

      return {} as ResponseData<any>;
    },
    [repository, fetchData]
  );

  return [data, isLoading, error, fetchFolderItems];
};

export const setNonDgnItemsToSameBridgeTypeAsParent = (
  mastersMap: MastersMap,
  masterId: string,
  bridgeSelection: BridgeSelection
) => {
  if (mastersMap[masterId].masterItem.references) {
    const masterFileExtension = getFileExtension(
      mastersMap[masterId].masterItem.name
    );
    for (const reference of mastersMap[masterId].masterItem.references!) {
      const referenceFileExtension = getFileExtension(reference.name);
      if (
        referenceFileExtension !== 'dgn' ||
        (masterFileExtension !== 'dgn' && referenceFileExtension === 'dgn')
      ) {
        mastersMap[masterId].bridgeSelection[reference.id] = {
          ...bridgeSelection,
          selectable: false,
        };
      }

      if (mastersMap[reference.id]) {
        setNonDgnItemsToSameBridgeTypeAsParent(
          mastersMap,
          reference.id,
          bridgeSelection
        );
      }
    }
  }
};

const getItemsGraphQLQuery = (parentId: string, repository: Repository) => {
  const repoType = getDocumentServiceRepositoryType(repository.type);
  return `{item(input: {id: "${parentId}", type: ${repoType}, location: "${repository.location}"}) {id, name, fileSize, children {name, id, isFolder, itemType, downloadUrl, parentId, fileSize }} }`;
};

export const filterMasterMap = (masterMap: MastersMap) => {
  const map = JSON.parse(JSON.stringify(masterMap)) as MastersMap;

  Object.entries(map).forEach(([key, master]) => {
    Object.entries(map).forEach(([keyToRemove, masterToRemove]) => {
      // filter nested references from root level
      if (
        key !== keyToRemove &&
        master?.masterItem.references?.find((r: any) => r.id === keyToRemove)
      ) {
        master.masterItem.references = master.masterItem.references.filter(
          (r: any) => {
            return (
              masterToRemove?.masterItem.references
                ?.map((mtr: any) => mtr.id)
                .indexOf(r.id) === -1
            );
          }
        );
      }
    });
  });

  return map;
};

// goes through mastermap and remove duplicate references
export const treeShakeMasterMap = (masterMap: MastersMap) => {
  const map = JSON.parse(JSON.stringify(masterMap)) as MastersMap;
  let shakedMap: MastersMap = map;

  Object.entries(map).forEach(([key, master]) => {
    if (master.masterItem.masterParentId) {
      shakedMap = shakeMasterItem(
        shakedMap,
        master.masterItem,
        master.masterItem.masterParentId
      );

      shakedMap[key].masterItem.references?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    }
  });

  return shakedMap;
};

// removes duplicate reference from master item
export const shakeMasterItem = (
  map: MastersMap,
  decendantMasterItem: MasterItem,
  parentId: string
) => {
  map[parentId].masterItem.references = map[
    parentId
  ].masterItem.references?.filter((pr: Item) => {
    return (
      decendantMasterItem.references?.map((r: Item) => r.id).indexOf(pr.id) ===
      -1
    );
  });

  if (map[parentId].masterItem.masterParentId) {
    map = shakeMasterItem(
      map,
      decendantMasterItem,
      map[parentId].masterItem.masterParentId!
    );
  }

  return map;
};

import './feedbackButton.scss';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@itwin/itwinui-react';
import { Icon } from '../../externalComponents/components/icons/icon';
import { SvgSmileyHappy } from '@itwin/itwinui-react/cjs/core/utils';

export const FeedbackButton = () => {
  const { t } = useTranslation();
  return (
    <div className="feedback-button">
      <Tooltip content={t('Feedback_Tooltip')}>
        <div className="icon-wrapper">
          <Icon
            icon={SvgSmileyHappy}
            className="header-icon"
            data-testid="feedback-button"
            onClick={() => {
              window.open('https://itwin.ideas.aha.io/', '_blank');
            }}
          />
        </div>
      </Tooltip>
    </div>
  );
};

import { Redirect, RouteComponentProps } from '@reach/router';
import { User } from 'oidc-client';
import { parse } from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { oneDay } from '../../externalComponents/constants/constants';
import { useAppContext } from '../../context/appContext/appContext';
import { useLogger } from '../../externalComponents/context/loggerContext/loggerContext';
import { TokenType } from '../../externalComponents/entities/repository';
import { navigate } from '../../externalComponents/services/uiUtils/navigation';
import { useOrchestratorAuthenticateUser } from '../../externalComponents/hooks/useOrchestratorAuthenticateUser/useOrchestratorAuthenticateUser';

export const AuthenticateUserHandler = (props: RouteComponentProps<any>) => {
  const {
    userManager,
    setUser,
    user,
    setForgeUser,
    forgeUserManager,
    configuration,
  } = useAppContext();

  const [redirectPath, setRedirectPath] = useState('');
  const [, isOrcsvAuthenticating, , authenticateUser] =
    useOrchestratorAuthenticateUser();
  const { logError } = useLogger();

  const query = useMemo(
    () => parse(props.location!.search),
    [props.location!.search]
  );
  const redirectedFromOrcsv = useMemo(
    () => query.authenticated === 'true',
    [query]
  );

  useEffect(() => {
    const path =
      props.location?.pathname === '/authenticate'
        ? props.location.pathname
        : `/${props.projectId ? props.projectId : ''}${
            props['*'] ? '/' + props['*'] : ''
          }`;
    setRedirectPath(`/login${path}`);

    userManager.events.addAccessTokenExpired(() => {
      userManager.signinRedirect({
        state: { from: `${window.location.origin}${path}` },
      });
    });

    userManager.events.addUserLoaded(async () => {
      try {
        const user = await userManager.getUser();

        if (user != null && !user.expired) {
          setUser(user);
        } else {
          throw new Error('User was null');
        }
      } catch (e) {
        logError(`Failed to getUser: ${e}`, [e]);
        console.log('Failed to getUser:', e);

        navigate(`${window.location.origin}/login${window.location.pathname}`);
      }
    });

    userManager.events.addSilentRenewError(e => {
      logError(`Failed to renew access token silently: ${e.message}`, [
        e.message,
      ]);
      console.log('silent renew error', e.message);
    });

    forgeUserManager.events.addAccessTokenExpired(() => {
      forgeUserManager.signinRedirect({
        state: { from: `${window.location.origin}${path}` },
      });
    });

    forgeUserManager.events.addUserLoaded(() => {
      forgeUserManager.getUser().then(usr => {
        setForgeUser(usr ? usr : ({} as User));
      });
    });
  }, [userManager, props.location]);

  useEffect(() => {
    if (redirectedFromOrcsv) {
      navigate(`${window.location.origin}${window.location.pathname}`);
    }
  }, [redirectedFromOrcsv]);

  useEffect(() => {
    if (
      user != null &&
      !user.expired &&
      !isOrcsvAuthenticating &&
      !redirectedFromOrcsv
    ) {
      const localStorageKey =
        'orchsrv-auth-cach' +
        user.profile.sub +
        '-' +
        configuration.buddiRegion;

      const lastAuthenticationTime = localStorage.getItem(localStorageKey);

      const now = Date.now();
      const timeDiff =
        now - (lastAuthenticationTime ? parseInt(lastAuthenticationTime) : 0);

      if (timeDiff > oneDay) {
        authenticateUser(
          TokenType.OIDC,
          `${window.location.href}?authenticated=true`
        );

        localStorage.setItem(localStorageKey, now.toString());
      }
    }
  }, [user]);

  return (user === null || user.expired) && redirectPath !== '' ? (
    <Redirect to={redirectPath} noThrow />
  ) : (
    <></>
  );
};

import { RouteComponentProps } from '@reach/router';
import '../../components/layout/layout.scss';
import { useTrackFeature } from '../../externalComponents/hooks/useTrackFeature/useTrackFeature';
import { Features } from '../../externalComponents/context/usageLoggerContext/featureConstants';
import { iModel } from '../../externalComponents/entities/iModel';
import './jobs.scss';
import { Text } from '@itwin/itwinui-react';
import { ConnectionDefinitionsTable } from '../../externalComponents/components/connectionDefinitionsTable/connectionDefinitionsTable';
import { SvgImodel } from '@itwin/itwinui-icons-color-react';

export interface JobsProps extends RouteComponentProps<{}> {
  iModel: iModel;
  goToJobView: (jobNameId: string) => any;
  goToChangeMappingsView: (jobNameId: string) => any;
  onCreateNewJobClick: () => void;
}

export const Jobs = (props: JobsProps) => {
  const { iModel, goToJobView, onCreateNewJobClick, goToChangeMappingsView } =
    props;

  useTrackFeature(Features.ViewJobsPage);

  return (
    <>
      <div className="iModel-name-wrapper">
        <SvgImodel />
        <Text variant="subheading" as="h3" className="iModel-name">
          {iModel.displayName}
        </Text>
      </div>

      <ConnectionDefinitionsTable
        orchAuthRedirectUrl={window.location.href}
        createDefinitionCallback={onCreateNewJobClick}
        changeMappingCallback={goToChangeMappingsView}
        onConnectionDefinitionClick={(connectionDefinitionId: string) => {
          goToJobView(connectionDefinitionId);
        }}
      />
    </>
  );
};

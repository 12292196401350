import { NewEcInstance } from './EcSchema/ecInstances';

export interface iModel {
  id : string;
  displayName : string;
  dataCenterLocation : string;
}

export interface IModelDto{
  iModels: iModel[];
  _links: any;
}

export const responseToIModelsMap = (
  response: NewEcInstance<IModelDto>
): IModelDto => {

  return {
    iModels: response.iModels.map((imodel : any) => {
      return {
        id: imodel.id,
        displayName: imodel.displayName,
        dataCenterLocation: imodel.dataCenterLocation,
      } as iModel;
    }),
    _links: response._links
  }
}

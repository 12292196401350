import { useState, useCallback } from 'react';
import { User } from 'oidc-client';
import { RequestData, ResponseData, UseDataSettings } from './requestData';
import { v4 as newGuid } from 'uuid';
import { useLogger } from '../../context/loggerContext/loggerContext';
import { useUserContext } from '../../context/userContext/userContext';
import { useErrorHandlingContext } from '../../context/errorHandlingContext/errorHandlingContext';

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

// grabbed partially from: https://www.robinwieruch.de/react-hooks-fetch-data/
export const useDataApi = <T>(
  settings: UseDataSettings<T>
): [T, boolean, any, (data: RequestData<T>) => Promise<ResponseData<T>>] => {
  const { user } = useUserContext();
  const { logError, logInformation } = useLogger();
  const { onUserUnauthenticated } = useErrorHandlingContext();

  const [data, setData] = useState<T | null>(settings.initialData);
  const [isLoading, setIsLoading] = useState(settings.initialIsLoading);
  const [error, setError] = useState<ResponseData<T> | null>(null);

  const fetchData = useCallback(
    async (requestData: RequestData<T>): Promise<ResponseData<T>> => {
      const { url, method, map, body, extraHeaders } = requestData;
      logInformation(`Fetch started: Url:${url}`, {
        url,
        body,
      });
      setError(null);
      setIsLoading(true);
      const { ok, data, error, response } = await tryFetchAndParseResult<T>(
        url,
        user!,
        method,
        body,
        map,
        extraHeaders,
        onUserUnauthenticated
      );

      setData(data);
      setError(error);
      setIsLoading(false);
      if (error) {
        logError(`Fetch failed Url:${url}`, { url, error });
        console.error(`Error fetching Url:'${url}': ${error}`);
      } else {
        logInformation(`Fetch succeeded: Url:${url}`, {
          url,
          body,
        });
      }
      return { ok, data, error, response };
    },
    [user, logError, logInformation]
  );

  const dataToShow = data || settings.initialData;
  return [dataToShow, isLoading, error, fetchData];
};

const tryFetchAndParseResult = async <T>(
  url: string,
  user: User,
  method: HTTPMethod,
  bodyToUse: string | undefined,
  map?: (response: any) => T,
  extraHeaders?: Record<string, string>,
  onUserUnauthenticated?: () => void
): Promise<ResponseData<T>> => {
  let response: Response;
  try {
    response = await doFetch(url, user, method, bodyToUse, extraHeaders);

    if (response.status === 401 && (user == null || user.expired)) {
      if (onUserUnauthenticated) {
        onUserUnauthenticated();
      }
    }

    if (!response.ok) {
      return {
        ok: false,
        data: null,
        error: await response.text(),
        status: response.status,
        response,
      };
    }

    const data: T = map
      ? map(await response.json())
      : (((await response.text()) as any) as T);

    return {
      ok: true,
      data: data,
      error: null,
      status: response.status,
      response: response,
    };
  } catch (error) {
    return { ok: false, data: null, error: error };
  }
};

export const doFetch = (
  url: string,
  user: User,
  method: HTTPMethod,
  body?: string,
  extraHeaders?: Record<string, string>
) => {
  return fetch(url, {
    headers: {
      Authorization: 'Bearer ' + user!.access_token,
      'Content-Type': 'application/json',
      'X-Correlation-Id': newGuid(),
      ...extraHeaders,
    },
    method,
    body,
  });
};

import { useEffect, useCallback, useState } from 'react';
import { Router, RouteComponentProps, useLocation } from '@reach/router';
import { Repository } from '../../externalComponents/entities/repository';
import { JobPage } from '../jobPage/jobPage';
import { iModel } from '../../externalComponents/entities/iModel';
import { RunJobAuthenticationCallbackPage } from '../runJobAuthenticationCallback/runJobAuthenticationCallbackPage';
import { JobPageController } from './jobPageController';
import { useTranslation } from 'react-i18next';
import { useIModelContext } from '../../context/navigationContext/iModelNameContext';
import { ChangeMappingsPage } from '../changeMappingsPage/changeMappingsPage';
import { navigate } from '../../externalComponents/services/uiUtils/navigation';
import { useConnectionDefintionsContext } from '../../externalComponents/context/connectionDefinitionsContext/connectionsDefintionsContext';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { useBentleyContext } from '../../externalComponents/context/bentleyContext/bentleyContext';
import { useSpatialRootsContext } from '../../externalComponents/context/spatialRootsContext/spatialRootsContext';
import { CreateConnectionsPage } from '../createConnectionPage/createConnectionPage';
import { SynchronizationReportPage } from '../synchronizationReportPage/synchronizationReportPage';

export interface JobsRouterProps extends RouteComponentProps<{}> {
  repositories: Repository[];
  projectId: string;
  iModels: iModel[];
}

export interface JobsRouterRoutedProps {
  imodelId: string;
}

export const JobsRouter = (
  props: JobsRouterProps & RouteComponentProps<JobsRouterRoutedProps>
) => {
  const { projectId, imodelId, iModels } = props;

  const { iModelId } = useBentleyContext();
  const { setCurrentIModel } = useIModelContext();
  const { t } = useTranslation();

  const {
    connectionsDefintions: jobDefs,
    areConnectionDefintionsLoading: areJobsLoading,
    fetchConnectionDefintions: fetchJobDefinitions,
    clearConnectionDefinitions,
  } = useConnectionDefintionsContext();
  const { spatialRoots, getSpatialRoots: getSpatialRoot } =
    useSpatialRootsContext();

  const locationState = useLocation();
  const [timesNavigated, setTimesNavigated] = useState(0);
  const isFirstNavigationInRouter = timesNavigated < 2;

  useEffect(() => {
    return () => {
      clearConnectionDefinitions();
    };
  }, [clearConnectionDefinitions]);

  const iModel = iModels.find(iModel => iModel.id === imodelId);

  useEffect(() => {
    if (iModel) {
      setCurrentIModel(iModel as iModel);
    } else {
      navigate('/errorPage', true, undefined, {
        state: {
          errorType: '404',
          errorMessage: t('FullPageError_iModelNotFound_Message'),
        },
      });
    }
  }, [iModel, iModels, setCurrentIModel, t]);
  
  useEffect(() => {
    setTimesNavigated(prev => prev + 1);
  }, [locationState.pathname]);

  const refetchData = useCallback(async () => {
    await getSpatialRoot(projectId, iModelId);
  }, [getSpatialRoot, iModelId, projectId]);

  if (iModelId === undefined) {
    return <FullPageLoader>{t('FullPage_Loading_Message')}</FullPageLoader>;
  }

  return (
    <Router style={{ height: '100%', position: 'relative' }}>
      <JobPageController
        path="/"
        iModel={iModel!}
        goToJobView={jobViewId => navigate(jobViewId, true, props.navigate!)}
        goToChangeMappingsView={(jobId: string) =>
          navigate(jobId + '/changeMapping', true, props.navigate!)
        }
        isFirstNavigationInRouter={isFirstNavigationInRouter}
      />

      <CreateConnectionsPage
        path="createConnection"
        orchAuthRedirectUrl={window.location.href.replace(
          '/createConnection',
          ''
        )}
        onConnectionCreatedCallback={async () => {
          await refetchData();
          navigate(`/${projectId}/${iModelId}`);
        }}
        onCancelCallback={() => {
          navigate(`/${projectId}/${iModelId}`);
        }}
      />

      <RunJobAuthenticationCallbackPage
        path="runJobAuthCallback"
        projectId={projectId}
        iModelId={imodelId!}
        jobs={jobDefs}
        areJobsFetched={areJobsLoading}
      />

      <JobPage
        path=":jobId"
        jobs={jobDefs}
        areJobsLoading={areJobsLoading}
        refetchJobs={fetchJobDefinitions}
        projectId={projectId}
        iModel={iModel!}
        spatialRoot={spatialRoots ? spatialRoots[0] : null}
      />

      <ChangeMappingsPage
        path=":jobId/changeMapping"
        jobs={jobDefs}
        areJobsLoading={areJobsLoading}
        navigateBackToJobsPage={(jobId: string) =>
          navigate(jobId, true, props.navigate!)
        }
      />

      <SynchronizationReportPage
        path="/:connectionId/:synchronizationReportId/*"
        iModelId={imodelId!}
      />
    </Router>
  );
};

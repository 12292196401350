import React, { createContext, ProviderProps, useContext } from 'react';

export interface IErrorHandlingContext {
  onUserUnauthenticated?: () => void;
  onErrorEncountered: (
    errorType: 'UserDoesNotHavePermissionForiModelHub'
  ) => void;
}

export const ErrorHandlingContext = createContext<IErrorHandlingContext>({
  onUserUnauthenticated: () => {},
  onErrorEncountered: (
    errorType: 'UserDoesNotHavePermissionForiModelHub'
  ) => {},
});

export interface ErrorHandlingContextProviderProps
  extends Omit<ProviderProps<IErrorHandlingContext>, 'value'> {
  onUserUnauthenticated?: () => void;
  onErrorEncountered: (
    errorType: 'UserDoesNotHavePermissionForiModelHub'
  ) => void;
}

export const ErrorHandlingContextProvider = ({
  ...props
}: ErrorHandlingContextProviderProps) => {
  const { onUserUnauthenticated, onErrorEncountered } = props;

  return (
    <ErrorHandlingContext.Provider
      value={{
        onUserUnauthenticated,
        onErrorEncountered,
      }}
      {...props}
    />
  );
};

export const useErrorHandlingContext = () => {
  const context = useContext(ErrorHandlingContext);
  if (context == null) {
    throw new Error('useErrorHandlingContext must be used inside provider');
  }
  return context;
};

import humanizeDuration from 'humanize-duration';

export const humanizedTimeFormatFunction = (timeInSeconds: number) => {
  if (timeInSeconds == null) {
    return '';
  }
  timeInSeconds = Math.round(timeInSeconds);
  return humanizeDuration(timeInSeconds * 1000, {
    largest: 1,
    units: ['d', 'h', 'm', 's'],
  });
};

export const humanizedTitleForTimeFunction = (timeInSeconds: number) => {
  return shortHandTimeFormatFunction(timeInSeconds * 1000);
};

export const shortHandTimeFormatFunction = humanizeDuration.humanizer({
  round: true,
  units: ['d', 'h', 'm', 's'],
  language: 'shortEn',
  languages: {
    shortEn: {
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
    },
  },
});

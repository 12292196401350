import React, { useEffect } from 'react';
import { useAppContext } from '../../context/appContext/appContext';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';

export const LogOut: React.FunctionComponent<any> = props => {
  const { userManager } = useAppContext();
  useEffect(() => {
    userManager.signoutRedirect({
      state: {
        from: `${props.location.origin}/${props.projectId}${
          props.iModelId ? '/' + props.iModelId : ''
        }`,
      },
    });
  }, [userManager]);
  return <FullPageLoader>Logging out...</FullPageLoader>;
};

import { ExecutionAction } from '../jobDefinition/jobDefinition';

export enum JobStartStatus {
  Started = 1,
  Skipped,
  BlockedByOtherJob,
  AlreadyRunning,
}

export enum FileRunState {
  NotFound = 'NotFound',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Succeeded = 'Succeeded',
  Fail = 'Failed',
  Canceled = 'Canceled',
  Timeout = 'Timeout',
  WaitingToRetry = 'WaitingToRetry',
  WaitingToExecute = 'WaitingToExecute',
  Skipped = 'Skipped',
  Paused = 'Paused',
  CompletedWithIssues = 'CompletedWithIssues',
}

export enum JobRunState {
  None = 'None',
  Queued = 'Queued',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  PartiallyCompleted = 'PartiallyCompleted',
  CompletedWithIssues = 'CompletedWithIssues',
  Completed = 'Completed',
  Failed = 'Failed',
  Skipped = 'Skipped',
  Canceled = 'Canceled',
  Paused = 'Paused',
  CompletedWithPaused = 'CompletedWithPaused',
}

export enum JobRunTriggerType {
  Scheduled = 'Scheduled',
  Manual = 'Manual',
}

export interface JobRun {
  jobRunId: string;
  jobStatus: JobRunState;
  startTime: string;
  endTime: string;
  processingStartTime: string;
  details: string;
  triggerType: JobRunTriggerType;
  fileRuns: FileRun[];
  errorCode?: string;
  knownIssueArticleLink?: string;
  projectId: string;
  iModelId: string;
  connectionDefinitionId: string;
  dataLocationId: string;
}

export interface FileRun {
  id: string;
  status: FileRunState;
  totalTime: number | null;
  startTime: string;
  endTime: string;
  errorCode: string | undefined;
  details: string | undefined;
  inputFile: string | null;
  inputFileId: string;
  taskStatuses: TaskStatus[];
  bridgeType: string;
  batchJobRunId?: string;
  action: ExecutionAction;
  knownIssueArticleLink?: string;
  sasUrl?: string;
}

export interface TaskStatus {
  step: string;
  state: string;
  stepsPercentage: string;
  task: string;
  tasksPercentage: string;
  phase: string;
  phasesPercentage: string;
  modifiedTime: string;
}

import React from 'react';

export const FlexTableWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="table-flex-positioner">
      <div className="react-table-restrictor">{children}</div>
    </div>
  );
};

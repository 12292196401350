import { useDataApi } from '../../hooks/useDataApi/useDataApi';
import { Repository, RepositoryType } from '../../entities/repository';
import {
  productSettingsJobsNamespace,
  JobFiles,
} from '../../entities/jobDefinition/jobDefinition';
import {
  getBridgeRepositoryType,
  getRepositoryUrl,
  getDatasourceUrl,
  getBridgeAssignmements,
  getHostName,
} from '../../services/bridgeLogic/bridgeLogic';
import { RequestData, ResponseData } from '../../hooks/useDataApi/requestData';
import { useCallback } from 'react';
import { v4 as newGuid } from 'uuid';
import { BridgeType, BridgeRepositoryType } from '../../entities/bridge';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import {
  iModelHubAppId,
  iTwinBridgePortalAppId,
} from '../../constants/constants';
import { Features } from '../../context/usageLoggerContext/featureConstants';
import {
  useFeatureToggleContext,
  FeatureToggles,
} from '../../context/featureToggleContext/featureToggleContext';
import {
  BridgeArg,
  JobDefinitionDto,
} from '../../entities/jobDefinition/jobDefintionDTO';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { useUserContext } from '../../context/userContext/userContext';

export const useCreateJob = (
  projectId: string,
  iModelId: string
): [
  JobDefinitionDto,
  boolean,
  any,
  (
    name: string,
    jobFiles: JobFiles,
    bridge: BridgeType,
    schedule: string,
    repository: Repository,
    bridgeArgs: BridgeArg
  ) => Promise<ResponseData<JobDefinitionDto>>
] => {
  const { logFeature } = useUsageLogger();
  const { buddiUrls } = useBuddiContext();
  const { user } = useUserContext();
  const features = useFeatureToggleContext();

  const useDataSettings = { initialIsLoading: false, initialData: null as any };
  const [data, isLoading, error, fetchData] = useDataApi<JobDefinitionDto>(
    useDataSettings
  );

  const createJob = useCallback(
    (
      name: string,
      jobFiles: JobFiles,
      bridge: BridgeType,
      schedule: string,
      repository: Repository,
      bridgeArgs: BridgeArg
    ) => {
      const uniqueName = newGuid();
      const lastModified = new Date().toISOString();

      const jobDef = getCreateJobBody(
        jobFiles,
        repository,
        uniqueName,
        name,
        iModelId,
        bridge,
        lastModified,
        bridgeArgs,
        user?.profile.sub as string,
        features
      );

      const spatialMaster = jobFiles.spatialMaster;
      const masters = jobFiles.masters;
      const sheets = jobFiles.sheets;
      const files = [
        ...(spatialMaster ? [spatialMaster] : []),
        ...masters,
        ...sheets,
      ];
      let requestData: RequestData<any> = {} as RequestData<any>;
      if (features.adminJobDefCreate) {
        requestData = {
          url: `/api/ProductSettings/CreateJobDefinition`,
          map: (x: any) => x,
          method: 'POST',
          body: JSON.stringify({
            projectId,
            iModelId,
            applicationId: iModelHubAppId,
            jobDefinition: jobDef,
          }),
        };
      } else {
        const url =
          `${buddiUrls.productSettingsServiceUrl}/v1.0/Application/` +
          `${iModelHubAppId}/Context/${projectId}/iModel/${iModelId}/Setting/${productSettingsJobsNamespace}/${uniqueName}`;
        requestData = {
          url: url,
          map: (x: any) => x,
          method: 'PUT',
          body: jobDef,
        };
      }

      logFeature(Features.CreateJob, [
        { name: 'jobName', value: name },
        {
          name: 'bridgeType',
          value: bridge,
        },
        {
          name: 'repositoryType',
          value: repository.type,
        },
        {
          name: 'fileCount',
          value: files.length.toString(),
        },
        {
          name: 'schedule',
          value: schedule,
        },
      ]);
      return fetchData(requestData);
    },
    [iModelId, user, features, logFeature, fetchData, projectId, buddiUrls]
  );

  return [data, isLoading, error, createJob];
};

export const getCreateJobBody = (
  jobFiles: JobFiles,
  repository: Repository,
  nameId: string,
  jobName: string,
  iModelId: string,
  bridge: BridgeType,
  lastModified: string,
  bridgeArgs: BridgeArg,
  userId: string,
  featuresToggles: FeatureToggles
) => {
  let repoType = getBridgeRepositoryType(repository.type);
  if (
    featuresToggles.forceGeoSpatialBridgeAsLegacy &&
    bridge === BridgeType.Geospatial &&
    repository.type === RepositoryType.PWDI
  ) {
    repoType = BridgeRepositoryType.ProjectWiseLegacy;
  }
  const spatialMaster = jobFiles.spatialMaster;
  const masters = jobFiles.masters;
  const sheets = jobFiles.sheets;

  const repoUrl = getRepositoryUrl(repository, jobFiles, repoType);
  const dsUrl = getDatasourceUrl(repository);
  const hostName = getHostName(repository);
  const bridgeAssignments = getBridgeAssignmements(bridge, jobFiles);

  const files = [
    ...(spatialMaster ? [spatialMaster] : []),
    ...masters,
    ...sheets,
  ];

  const spatialRootId = spatialMaster
    ? files.findIndex(x => x.id === spatialMaster.id)
    : -1;

  return JSON.stringify({
    id: '',
    name: nameId,
    namespace: productSettingsJobsNamespace,
    properties: {
      name: jobName,
      files: files.map(file => ({
        name: file.name,
        guid: file.id,
        transform: null,
        bridgeArgs: bridgeArgs != null ? bridgeArgs : undefined,
      })),
      spatialRootId: spatialRootId,
      bridges: bridgeAssignments,
      version: '1.0',
      repositoryType: repoType,
      repositoryUrl: repoUrl,
      datasourceUrl: dsUrl,
      iModelId: iModelId,
      lastModified: lastModified,
      clientId: iTwinBridgePortalAppId,
      userId: userId,
      hostName: hostName,
    },
  } as JobDefinitionDto);
};

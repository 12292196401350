import * as React from 'react';

export const SvgDeletePendingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="ad493e42-5b5f-40c8-9e0f-541e5d0663c1"
    data-name="b610eda4-0c98-44c3-9d03-bd81a96ffacc"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...props}
  >
    <polygon points="9.8 0.8 7.3 0.8 6.5 0 3.3 0 2.4 0.8 0 0.8 0 1.6 9.8 1.6 9.8 0.8" />
    <path d="M11.5,7A4.5,4.5,0,1,0,16,11.5,4.5045,4.5045,0,0,0,11.5,7Zm0,8A3.5,3.5,0,1,1,15,11.5,3.50382,3.50382,0,0,1,11.5,15Z" />
    <path d="M11.5,9.61719a.49971.49971,0,0,0-.5.5v.59863l-1.5332-.71191a.49982.49982,0,1,0-.42188.90625l2.24414,1.043A.49969.49969,0,0,0,12,11.5V10.11719A.49971.49971,0,0,0,11.5,9.61719Z" />
    <path d="M6.00049,11.5c0-.03375.00445-.06635.00506-.1H5.7V4.1h.8V9.223a5.53121,5.53121,0,0,1,2.4-2.5675V2.4H.8v9.8a.86034.86034,0,0,0,.8.8h4.6137A5.47433,5.47433,0,0,1,6.00049,11.5ZM4.1,11.4H3.3V4.1h.8Z" />
  </svg>
);
export default SvgDeletePendingIcon;

import React from 'react';
import './contextMenu.scss';
import { useTranslation } from 'react-i18next';
import { SvgMore } from '@itwin/itwinui-icons-react';
import { DropdownMenu, IconButton, MenuItem } from '@itwin/itwinui-react';
import { MenuAction } from '../jobView/toolbarActions';

export interface ContextMenuProps {
  menuOptions: MenuAction[];
  onToggle?: () => void;
}

export const ContextMenu = (props: ContextMenuProps) => {
  const { menuOptions, onToggle } = props;
  const { t } = useTranslation();

  return (
    <DropdownMenu
      menuItems={(close: () => void) =>
        menuOptions.map((option, index) => (
          <MenuItem
            {...option}
            key={index}
            onClick={() => {
              close();
              onToggle && onToggle();
              option.onClick?.();
            }}
          >
            {option.title}
          </MenuItem>
        ))
      }
      onHide={instance => {
        instance.popper.remove(); // Prevents tippy DOM node leak
      }}
    >
      <IconButton
        styleType="borderless"
        size="small"
        tabIndex={0}
        role="button"
        data-testid="context-menu"
        title={t('ContextMenuBtn_Tooltip')!}
      >
        <SvgMore />
      </IconButton>
    </DropdownMenu>
  );
};

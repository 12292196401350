import React, { useEffect } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import { roundArrow } from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';
//import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import { useBentleyContext } from '../../context/bentleyContext/bentleyContext';
import { useTranslation } from 'react-i18next';
import { Anchor, Flex } from '@itwin/itwinui-react';
import { Icon } from '../icons/icon';
import { SvgCloseSmall } from '@itwin/itwinui-icons-react';
import './onboardingPopover.scss';

type OnboardingPopoverProps = TippyProps & {
  shouldShowPopover: boolean; //LD flag
  isVisible: boolean;
  show: () => void;
  hide: () => void;
  displayMessage: string;
  placement: string;
};

//Wrapper around [tippy.js](https://atomiks.github.io/tippyjs)
export const OnboardingPopover = (props: OnboardingPopoverProps) => {
  const {
    shouldShowPopover,
    isVisible,
    show,
    hide,
    displayMessage,
    placement,
    children,
  } = props;

  const { t } = useTranslation();
  const { iModelId } = useBentleyContext();

  const hidePopoverValue = 'dismiss';
  useEffect(() => {
    const visitedIModelIdValue = localStorage.getItem('visitedIModelId');

    visitedIModelIdValue == iModelId.toString() ||
    visitedIModelIdValue == hidePopoverValue
      ? hide()
      : show();
  }, []);

  const dismissPopover = () => {
    localStorage.setItem('visitedIModelId', hidePopoverValue);
    hide();
    return;
  };

  return shouldShowPopover ? (
    <Tippy
      placement={placement}
      visible={isVisible}
      theme="sync-popover"
      arrow={roundArrow}
      onClickOutside={hide}
      allowHTML={true}
      interactive={true}
      content={
        <Flex flexDirection="column" display="inline-flex" alignItems="flex">
          <div className="popover-css">
            <div className="header-row">
              <Flex>
                <div className="popover-heading">
                  {t('New_Feature_Alert_Message')}
                </div>
                <Flex.Spacer />
                <div className="close-icon">
                  <Icon
                    icon={SvgCloseSmall}
                    color="default"
                    onClick={() => {
                      localStorage.setItem('visitedIModelId', iModelId);
                      hide();
                    }}
                  />
                </div>
              </Flex>
            </div>
            {displayMessage}
          </div>
          <div className="div-dismiss">
            <Anchor
              className="anchor-text"
              as="button"
              onClick={() => dismissPopover()}
            >
              {t('Dismiss_Popover_Message')}
            </Anchor>
          </div>
        </Flex>
      }
    >
      {children}
    </Tippy>
  ) : (
    <div>{children}</div>
  );
};

import * as React from 'react';
import './iModelUserNameBadge.scss';

interface IMSUserNameBadgeProps {
  userInitials: string;
  email: string;
}

export const IMSUserNameBadge: React.FC<IMSUserNameBadgeProps> = (
  props: IMSUserNameBadgeProps
) => {
  const GetDataVizColorFromString = (str: string) => {
    const DataVisualizationColors = [
      '#6AB9EC',
      '#B1C854',
      '#F7706C',
      '#4585A5',
      '#FFC335',
      '#F7963E',
      '#73C7C1',
      '#85A9CF',
      '#A3779F',
      '#C8C2B4',
      '#A47854',
    ];

    const colorCount = DataVisualizationColors.length;
    const lowerCase = str.toLowerCase();
    let hash = 0;

    for (let i = 0; i < lowerCase.length; i++) {
      const char = lowerCase.charCodeAt(i);
      hash = (hash + char) % colorCount;
    }

    return DataVisualizationColors[hash];
  };

  return (
    <div
      className="imodel-user-name-badge"
      style={{ backgroundColor: GetDataVizColorFromString(props.email) }}
    >
      <span>{props.userInitials.toUpperCase()}</span>
    </div>
  );
};

export enum NotificationType {
  SyncSuccess = 'SynchronizationSuccess',
  SyncFailure = 'SynchronizationFailure',
  ScheduleSuspension = 'SynchronizationScheduleSuspension',
  Summary = 'SynchronizationSummary',
}

export enum NotificationContext {
  iModel = 'iModel',
  iTwin = 'iTwin',
}

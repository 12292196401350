import { useState, useEffect } from 'react';
import { RecentPwdiConnection } from '../../components/pwdiConnection/recentPwdiConnections';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { useUserContext } from '../../context/userContext/userContext';

export const useRecentPwdiConnections = (): [
  RecentPwdiConnection[],
  RecentPwdiConnection | undefined,
  (selectedConnection?: RecentPwdiConnection) => void,
  (newConnection: RecentPwdiConnection) => void
] => {
  const { user } = useUserContext();
  const { buddiUrls } = useBuddiContext();

  const [recentConnections, setRecentConnections] = useState<
    RecentPwdiConnection[]
  >([]);
  const [
    selectedRecentConnection,
    setSelectedRecentConnection,
  ] = useState<RecentPwdiConnection>();

  const localStorageKey =
    'pwdi-recent-connections-' + user!.profile.sub + '-' + buddiUrls.region;

  const getRecentConnections = () => {
    const recentConnectionsString = localStorage.getItem(localStorageKey);
    if (recentConnectionsString) {
      setRecentConnections(JSON.parse(recentConnectionsString));
    }
  };

  const selectRecentConnection = (
    selectedConnection?: RecentPwdiConnection
  ) => {
    setSelectedRecentConnection(selectedConnection);

    if (selectedConnection) {
      let recentConnections: RecentPwdiConnection[] = [];
      const recentConnectionsString = localStorage.getItem(localStorageKey);

      if (recentConnectionsString) {
        recentConnections = JSON.parse(recentConnectionsString);
      }

      const recentConnectionIndex = recentConnections.findIndex(
        rc => JSON.stringify(rc) === JSON.stringify(selectedConnection)
      );

      recentConnections.splice(recentConnectionIndex, 1);
      recentConnections.unshift(selectedConnection);

      localStorage.setItem(localStorageKey, JSON.stringify(recentConnections));

      setRecentConnections(recentConnections);
    }
  };

  const addConnectionToLocalStorage = (newConnection: RecentPwdiConnection) => {
    let recentConnections: RecentPwdiConnection[] = [];
    const recentConnectionsString = localStorage.getItem(localStorageKey);

    if (recentConnectionsString) {
      recentConnections = JSON.parse(recentConnectionsString);
    }

    const recentConnectionIndex = recentConnections.findIndex(
      rc => JSON.stringify(rc) === JSON.stringify(newConnection)
    );

    if (recentConnectionIndex === -1) {
      recentConnections.unshift(newConnection);

      if (recentConnections.length > 3) {
        recentConnections.pop();
      }
    }

    localStorage.setItem(localStorageKey, JSON.stringify(recentConnections));
    setRecentConnections(recentConnections);
  };

  useEffect(() => getRecentConnections(), []);

  return [
    recentConnections,
    selectedRecentConnection,
    selectRecentConnection,
    addConnectionToLocalStorage,
  ];
};

import {
  InputFileWithNextDataLink,
  JobDefinition,
  JobDefinitionFile,
} from '../../entities/jobDefinition/jobDefinition';
import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useCallback, useState } from 'react';
import { mapInputFileDtoToJobDefinitionFileWithNextDataLink } from '../../entities/jobDefinition/jobDefinitionMappings';
import { useGetItemsByIds } from '../useGetItemsByIds/useGetItemsByIds';
import { getRepository } from '../../services/jobDefinitionUtils/getRepositoryFromJobDefinition';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { RepositoryType } from '../../entities/repository';
import { Item } from '../../entities/item';
import { useTranslation } from 'react-i18next';

export const useGetInputFiles = (
  projectId: string
): [
  JobDefinitionFile[],
  boolean,
  any,
  (
    jobDefinition: JobDefinition,
    fetchMetaData?: boolean,
    fetchNames?: boolean
  ) => Promise<ResponseData<JobDefinitionFile[]>>
] => {
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = {
    initialData: {} as InputFileWithNextDataLink,
    initialIsLoading: false,
  };
  const [, , , fetchData] =
    useDataApi<InputFileWithNextDataLink>(useDataSettings);
  const [, , , fetchItemsFromIds] = useGetItemsByIds();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<JobDefinitionFile[]>([]);
  const { t } = useTranslation();

  const fetchInputFiles = useCallback(
    async (
      jobDefinition: JobDefinition,
      fetchMetaData: boolean = false,
      fetchNames: boolean = true
    ) => {
      const urlV2 = `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions('${jobDefinition.id}')/InputFiles?contextId=${projectId}&iModelId=${jobDefinition.iModelId}`;

      let requestData: RequestData<InputFileWithNextDataLink> = {
        method: 'GET',
        url: urlV2,
        map: mapInputFileDtoToJobDefinitionFileWithNextDataLink,
      };

      setIsLoading(true);
      const result = await fetchData(requestData);
      if (!result.ok) {
        setIsLoading(false);
        return result;
      }

      const inputFiles: JobDefinitionFile[] = [];
      [...result.data!.files].forEach(i => inputFiles.push(i));

      let nextDataLink = result.data!.nextDataLink;
      while (nextDataLink) {
        requestData = {
          method: 'GET',
          url: nextDataLink,
          map: mapInputFileDtoToJobDefinitionFileWithNextDataLink,
        };
        const nextInputFiles = await fetchData(requestData);
        [...nextInputFiles.data!.files].forEach(i => inputFiles.push(i));
        nextDataLink = nextInputFiles.data!.nextDataLink;
      }

      const namesCount = inputFiles.filter(f =>
        !fetchMetaData ? f.file.name == null : true
      ).length;
      if (fetchNames && namesCount !== 0) {
        if (jobDefinition.repositoryType === RepositoryType.MANIFEST) {
          inputFiles.forEach(
            x =>
              (x.file = {
                id: x.file.id,
                name: t('DataSource_DeletedFile'),
              } as Item)
          );
        } else {
          const repository = getRepository(jobDefinition, projectId);
          const itemsResult = await fetchItemsFromIds(
            inputFiles.map(f => f.file.id),
            repository
          );
          const items = [...itemsResult.data!];
          for (const inputFile of inputFiles) {
            inputFile.file = items.find(i => i.id === inputFile.file.id)!;
          }
        }
      }
      setData(inputFiles);
      setIsLoading(false);
      const returnValue: any = { ...result, data: inputFiles };
      return returnValue;
    },
    [buddiUrls, projectId, fetchData, fetchItemsFromIds]
  );

  return [data, isLoading, null, fetchInputFiles];
};

import { useState } from 'react';
import last from 'lodash/last';

interface IBreadcrumb<T> {
  label: string;
  value: T;
}
export const useBreadcrumbs = <T>(
  initialBreadcrumbs: IBreadcrumb<T>[] = []
) => {
  const [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const addBreadcrumb = (label: string, value: T) =>
    setBreadcrumbs(breadcrumbs => [...breadcrumbs, { label, value }]);
  const jumpTo = (_jumpToId: string, jumpToIndex: number) => () => {
    const breadcrumbsToLeaveCount = jumpToIndex + 1;
    setBreadcrumbs(breadcrumbs.slice(0, breadcrumbsToLeaveCount));
  };
  const depth = breadcrumbs.length - 1;
  const labels = breadcrumbs.map(x => x.label);
  const values = breadcrumbs.map(x => x.value);
  const lastBreadcrumbValue = breadcrumbs.length
    ? last(breadcrumbs)!.value
    : null;
  return {
    breadcrumbs,
    lastBreadcrumbValue,
    depth,
    labels,
    values,
    addBreadcrumb,
    jumpTo,
  };
};

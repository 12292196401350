import React, { useState, useEffect } from 'react';
import {
  getWeeklyCronExpressionForHour,
  CronWeeklySchedule,
  CronWeeklyScheduleDisplayNames,
  getHourFromScheduleCronString,
  getWeekFromScheduleCronString,
  CRON_IGNORE,
} from '../../../../entities/cronSchedule';
import { TimePicker } from '../../../../components/timePicker/timePicker';
import { useTranslation } from 'react-i18next';
import { Radio, Select } from '@itwin/itwinui-react';

interface weeklyJobScheduleSelectionProps {
  schedule: string;
  isSelected: boolean;
  setSchedule: (schedule: string) => void;
}

export const WeeklyJobScheduleSelection = (
  props: weeklyJobScheduleSelectionProps
) => {
  const { isSelected, schedule, setSchedule } = props;

  const { t } = useTranslation();
  const [hourIn24HrFormat, setHourIn24HrFormat] = useState('0');
  const [weekDay, setWeekDay] = useState<string>(
    CronWeeklySchedule.WeeklySunday
  );

  useEffect(() => {
    if (!isSelected) {
      return;
    }
    const hours = getHourFromScheduleCronString(schedule);
    const weekDay = getWeekFromScheduleCronString(schedule);

    if (hours !== CRON_IGNORE && weekDay !== CRON_IGNORE) {
      setHourIn24HrFormat(hours);
      setWeekDay(weekDay);
    }
  }, [isSelected, schedule]);

  const onTimeChanged = (timeIn24HrFormat: string) => {
    setHourIn24HrFormat(timeIn24HrFormat);
    setSchedule(getWeeklyCronExpressionForHour(weekDay, timeIn24HrFormat));
  };

  return (
    <div className="schedule-option">
      <Radio
        label={t('JobScheduleWeekly_Label')}
        name="schedule-sync"
        onChange={() => {
          setSchedule(
            getWeeklyCronExpressionForHour(weekDay, hourIn24HrFormat)
          );
        }}
        checked={isSelected}
      />
      <div className="day-time-picker">
        <Select
          data-testid="weekday-select"
          className="time-select-long"
          options={CronWeeklyScheduleDisplayNames}
          value={weekDay}
          onChange={value => {
            setWeekDay(value);
            setSchedule(
              getWeeklyCronExpressionForHour(value, hourIn24HrFormat)
            );
          }}
          disabled={!isSelected}
        />
        <TimePicker
          hourIn24HrFormat={hourIn24HrFormat}
          disable={!isSelected}
          onTimeChanged={onTimeChanged}
        />
      </div>
    </div>
  );
};

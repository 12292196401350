import {
  ApiVersion,
  JobDefinition,
  productSettingsJobsNamespace,
} from '../../entities/jobDefinition/jobDefinition';
import { useDataApi } from '../useDataApi/useDataApi';
import { iModelHubAppId } from '../../constants/constants';
import { useCallback } from 'react';
import { ResponseData, RequestData } from '../useDataApi/requestData';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { Features } from '../../context/usageLoggerContext/featureConstants';
import { jobDefinitionToJobDefinitionPropertiesDtoMap } from '../../entities/jobDefinition/jobDefinitionMappings';
import { JobDefinitionPropertiesDto } from '../../entities/jobDefinition/jobDefintionDTO';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
export const useRenameJob = (
  projectId: string,
  iModelId: string
): [
  any,
  boolean,
  any,
  (job: JobDefinition, newName: string) => Promise<ResponseData<null>>
] => {
  const { buddiUrls } = useBuddiContext();
  const { logFeature } = useUsageLogger();

  const useDataSettings = { initialIsLoading: false, initialData: null };
  const [data, isLoading, error, fetchData] = useDataApi(useDataSettings);

  const baseUrl = `${buddiUrls.productSettingsServiceUrl}/v1.0/Application/${iModelHubAppId}/Context/${projectId}`;

  const renameJobV1 = useCallback(
    (job: JobDefinition, newName: string) => {
      const url =
        baseUrl +
        `/iModel/${iModelId}/Setting/${productSettingsJobsNamespace}/${job.uniqueSettingName}`;

      const body = getRenameJobBody(job, newName);
      const requestData: RequestData<null> = {
        map: x => x,
        url: url,
        method: 'PUT',
        body: body,
      };

      logFeature(Features.RenameJob);
      return fetchData(requestData);
    },
    [baseUrl, buddiUrls, iModelId, logFeature, fetchData]
  );

  const renameJobV2 = useCallback(
    (job: JobDefinition, newName: string) => {
      const requestData: RequestData<null> = {
        map: x => x,
        url: `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions('${job.id}')?contextId=${projectId}&iModelId=${iModelId}`,
        method: 'PATCH',
        body: JSON.stringify([
          {
            value: newName,
            path: '/name',
            op: 'replace',
          },
        ]),
      };

      logFeature(Features.RenameJob);
      return fetchData(requestData);
    },
    [baseUrl, buddiUrls, iModelId, logFeature, fetchData]
  );

  const renameJob = (job: JobDefinition, name: string) =>
    job.apiVersion === ApiVersion.v2
      ? renameJobV2(job, name)
      : renameJobV1(job, name);

  return [data, isLoading, error, renameJob];
};

export const getRenameJobBody = (job: JobDefinition, newName: string) => {
  return JSON.stringify({
    properties: {
      ...jobDefinitionToJobDefinitionPropertiesDtoMap(job),
      name: newName,
    } as JobDefinitionPropertiesDto,
  });
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REGEXEXPRESSIONINFO_LINK } from '../../entities/repository';
import './regexModal.scss';
import { Explanation } from '../explanation/explanation';
import { LoadingOverlay } from '../loadingOverlay/loadingOverlay';
import {
  Button,
  LabeledTextarea,
  Modal,
  ModalButtonBar,
} from '@itwin/itwinui-react';
import { ModalProps } from '../../hooks/useModal/useModal';

type regexModalProps = ModalProps & {
  closeModal: () => void;
  submitRegex: (regex: string | null) => void;
  regexInitialValue: string;
  hasLoadingOverlay?: boolean;
};
export const RegexModal = (props: regexModalProps) => {
  const {
    closeModal,
    submitRegex,
    regexInitialValue,
    hasLoadingOverlay,
    ...modalProps
  } = props;
  const { isOpen } = modalProps;
  const { t } = useTranslation();
  const [regexValue, setRegexValue] = useState(regexInitialValue);
  useEffect(() => {
    if (!isOpen) {
      setRegexValue(regexInitialValue);
    }
  }, [isOpen]);

  const cancel = () => {
    closeModal();
  };

  const handleRegexValue = (value: string) => {
    setRegexValue(value);
  };

  const handlePrimaryButton = () => {
    submitRegex(regexValue === '' ? null : regexValue);
  };

  return (
    <Modal
      {...modalProps}
      title={t('RegexModal_title')}
      onClose={cancel}
      className={'regexModal'}
    >
      <LoadingOverlay loading={hasLoadingOverlay ?? false}>
        <Explanation
          text={t('RegexExpression_Label')}
          linkText={t('ReadMore_Link')}
          linkUrl={REGEXEXPRESSIONINFO_LINK}
        />
        <LabeledTextarea
          label=""
          placeholder={t('RegexExpression_Placeholder')!}
          value={regexValue}
          onChange={e => {
            handleRegexValue(e.target.value);
          }}
          data-testid={'labled-textarea-regex'}
        />
        <ModalButtonBar>
          <Button
            styleType="high-visibility"
            onClick={handlePrimaryButton}
            disabled={hasLoadingOverlay || regexValue === regexInitialValue}
            data-testid="regex-modal-submit-button"
          >
            {t('OKBtn_Label')}
          </Button>
          <Button onClick={cancel} disabled={hasLoadingOverlay}>
            {t('CancelBtn_Label')}
          </Button>
        </ModalButtonBar>
      </LoadingOverlay>
    </Modal>
  );
};

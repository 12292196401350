import React, { useEffect, useState } from 'react';
import { useBadgersReport } from '../../../hooks/useBadgers/useBadgerReport';
import { FileRun } from '../../../entities/jobRunStatus/jobRunStatus';
import { FullPageLoader } from '../../fullPageLoader/fullPageLoader';
import { ModalProps } from '../../../hooks/useModal/useModal';
import { JobDefinition } from '../../../entities/jobDefinition/jobDefinition';
import { useFeatureToggleContext } from '../../../context/featureToggleContext/featureToggleContext';
import { FeedbackIntroductionModal } from '../feedbackIntroductionModal/feedbackIntroductionModal';
import { FeedbackModal } from '../feedbackModal/feedbackModal';
import { SynchronizationReport } from '../../synchronizationReport/synchronizationReport';
import { Modal } from '@itwin/itwinui-react';
import './badgersModal.scss';

type SynchronizationReportModalProps = ModalProps & {
  projectId: string;
  iModelId: string;
  projectName?: string;
  iModelName?: string;
  fileRun: FileRun;
  jobDefinition?: JobDefinition;
  jobRunId: string;
  closeModal: () => void;
};

export const SynchronizationReportModal: React.FC<
  SynchronizationReportModalProps
> = (props: SynchronizationReportModalProps) => {
  const {
    projectId,
    iModelId,
    projectName,
    iModelName,
    fileRun,
    closeModal,
    jobDefinition,
    jobRunId,
    ...modalProps
  } = props;

  const { syncReportUIv3Feedback } = useFeatureToggleContext();

  const [report, isLoading, , getDiagnosticsReport] = useBadgersReport(
    projectId,
    iModelId
  );

  const [showFeedbackIntroduction, setShowFeedbackIntroduction] =
    useState(true);

  useEffect(() => {
    const showFeedbackIntroductionValue = localStorage.getItem(
      'showFeedbackIntroduction'
    );
    if (showFeedbackIntroductionValue) {
      setShowFeedbackIntroduction(JSON.parse(showFeedbackIntroductionValue));
    }
  }, []);

  useEffect(() => {
    getDiagnosticsReport(fileRun.id);
  }, [fileRun.id, getDiagnosticsReport]);

  return syncReportUIv3Feedback && showFeedbackIntroduction ? (
    <FeedbackIntroductionModal
      onGotItClick={() => {
        localStorage.setItem('showFeedbackIntroduction', 'false');
        setShowFeedbackIntroduction(false);
      }}
      {...modalProps}
    />
  ) : (
    <Modal {...modalProps} className="synchronization-report-modal" title={''}>
      <div className="synchronization-report-modal-container">
        {!isLoading ? (
          <SynchronizationReport report={report} />
        ) : (
          <FullPageLoader />
        )}
      </div>
      <>
        <FeedbackModal {...modalProps} title={''} children={undefined} />
      </>
    </Modal>
  );
};

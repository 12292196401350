import {
  Button,
  Checkbox,
  Flex,
  InputGroup,
  Modal,
  ModalButtonBar,
  ModalContent,
  Tooltip,
} from '@itwin/itwinui-react';
import React, { useEffect, useState } from 'react';
import { ModalProps } from '../../../hooks/useModal/useModal';
import { useToast } from '../../../context/toastContext/toastContext';
import { useUsageLogger } from '../../../context/usageLoggerContext/usageLoggerContext';
import { Events } from '../../../context/usageLoggerContext/eventConstants';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../context/userContext/userContext';
import { Icon } from '../../icons/icon';
import {
  SvgTechnicalPreviewMini,
} from '@itwin/itwinui-icons-color-react';
import './emailNotificationsModal.scss';

export const EmailNotificationsModal: React.FC<ModalProps> = (
  props: ModalProps
) => {
  const { onClose, isDismissible, ...modalProps } = props;

  const { t } = useTranslation();
  const { toastSuccess } = useToast();
  const { logEvent } = useUsageLogger();
  const { user } = useUserContext();

  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const subscribedNotificationsValue = localStorage.getItem(
      'hasSubscribedNotifications'
    );
    if (subscribedNotificationsValue) {
      setIsSubscribed(JSON.parse(subscribedNotificationsValue));
    }
  }, []);

  const saveNotificationPreference = () => {
    logEvent(Events.EmailNotificationSubscriptionStatus, [
      {
        name: 'isUserSubscribed',
        value: isSubscribed.toString(),
      },
      {
        name: 'userId',
        value: user.profile.sub,
      },
    ]);

    localStorage.setItem('hasSubscribedNotifications', isSubscribed.toString());
    const displayMessage = isSubscribed
      ? t('Successfully_Subscribed')
      : t('Successfully_Unsubscribed');
    toastSuccess(displayMessage);
    onClose();
  };

  const getUserSubscriptionStatus = () => {
    const subscribedNotificationsValue = localStorage.getItem(
      'hasSubscribedNotifications'
    );
    return subscribedNotificationsValue
      ? JSON.parse(subscribedNotificationsValue)
      : false;
  };

  return (
    <Modal
      isDismissible={false}
      title={
        <Flex>
          <div className="notification-title">
            {t('Notification_Modal_Title')}
          </div>

          <Tooltip content="Technical Preview">
            <div className="tech-preview-img">
              <Icon icon={SvgTechnicalPreviewMini} />
            </div>
          </Tooltip>
        </Flex>
      }
      {...modalProps}
    >
      <ModalContent>
        <div className="div-note">
          {getUserSubscriptionStatus() ? (
            <div>{t('Already_Subscribed_Message')}</div>
          ) : (
            <Flex>
              <div className="div-imp-note">{t('Important_Tag')}</div>
              <div>{t('Important_Message')}</div>
            </Flex>
          )}
        </div>
        <Flex
          className="input-groups-common"
          alignItems="flex-start"
          display="inline-flex"
        >
          <div>
            <div className="checkbox-flex-labels">
              {getUserSubscriptionStatus()
                ? t('Unsubscribe_Notifications')
                : t('Subscribe_Notification_Message')}
            </div>

            <Checkbox
              label={t('Subscribe_Notifications')}
              checked={isSubscribed}
              onChange={e => {
                if (e.target.checked != isSubscribed) {
                  setIsSubscribed(e.target.checked);
                  setIsDisabled(false);
                }
                if (e.target.checked == getUserSubscriptionStatus()) {
                  setIsDisabled(true);
                }
              }}
            />
          </div>
          <div className="div-notification-types">
            <div className="checkbox-flex-labels">
              {t('Notifications_Preferences_Label')}
            </div>
            <InputGroup>
              <Checkbox
                checked={isSubscribed}
                disabled={true}
                label={t('Failure_Notification_Label')}
              />
              <Checkbox
                checked={isSubscribed}
                disabled={true}
                label={t('Partial_Success_Notification_Label')}
              />
              <Checkbox
                checked={isSubscribed}
                disabled={true}
                label={t('Schedule_Suspension_Notification_Label')}
              />
              <Checkbox
                checked={isSubscribed}
                disabled={true}
                label={t('Connection_Owner_Change_Notification_Label')}
              />
            </InputGroup>
          </div>
        </Flex>
      </ModalContent>
      <ModalButtonBar>
        <Button
          styleType="high-visibility"
          disabled={isDisabled}
          onClick={() => saveNotificationPreference()}
        >
          {t('SaveChangesBtn_Label')}
        </Button>
        <Button onClick={() => onClose()}>{t('CancelBtn_Label')}</Button>
      </ModalButtonBar>
    </Modal>
  );
};

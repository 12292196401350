import { useDataApi } from '../../hooks/useDataApi/useDataApi';
import { RequestData } from '../../hooks/useDataApi/requestData';
import { responseToIModelsMap, iModel, IModelDto } from '../../entities/iModel';
import { useCallback, useEffect } from 'react';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { useErrorHandlingContext } from '../../context/errorHandlingContext/errorHandlingContext';

export const useGetProjectIModels = (
  projectId: string
): [iModel[], boolean, any, () => Promise<iModel[]>] => {
  const { buddiUrls } = useBuddiContext();
  const { onErrorEncountered } = useErrorHandlingContext();

  const init: IModelDto = {
    iModels: [],
    _links: {}
  }

  const useDataSettings = { initialIsLoading: true, initialData: init };
  const [iModelBody, isLoading, error, fetchData] =
      useDataApi<IModelDto>(useDataSettings);
  
  const getData = useCallback(async (requestData : any) => {
    const result = await fetchData(requestData);

    while(result.data?._links?.next){
      const nextRequestData = {
        url: result.data._links.next.href,
        method: 'GET',
        map: responseToIModelsMap,
      } as RequestData<IModelDto>;
      const nextResult = await fetchData(nextRequestData);
      result.data.iModels = result.data.iModels.concat(nextResult.data!.iModels);
      result.data._links = nextResult.data!._links;
    }
    return result.data!.iModels;
  }, [fetchData])

  const getIModels = useCallback(() => {
    const requestData = getProjectIModelsGraphQLQuery(
      buddiUrls.iModelsAPIsUrl,
      projectId
    );

    return getData(requestData);
  }, [projectId, buddiUrls, getData]);

  useEffect(() => {
    const err = error ? JSON.parse(error) : null;
    if (err != null && err.errorId === 'iModelHub.UserDoesNotHavePermission') {
      onErrorEncountered('UserDoesNotHavePermissionForiModelHub');
    }
  }, [error]);

  return [iModelBody.iModels, isLoading, error, getIModels];
};

const getProjectIModelsGraphQLQuery = (
  iModelsAPIsUrl: string,
  projectId: string
) => {
  const iModelsMap = responseToIModelsMap;
  const iModelUrl = `${iModelsAPIsUrl}/?iTwinId=${projectId}`;
  const requestData = {
    url: iModelUrl,
    method: 'GET',
    map: iModelsMap,
  } as RequestData<IModelDto>;
  return requestData;
};

import {
  JobDefinition,
  JobDefinitionFile,
} from '../../entities/jobDefinition/jobDefinition';
import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useCallback, useState } from 'react';
import { getRepository } from '../../services/jobDefinitionUtils/getRepositoryFromJobDefinition';
import {
  getBridgeRepositoryType,
  getDatasourceUrl,
} from '../../services/bridgeLogic/bridgeLogic';
import { v4 as newGuid } from 'uuid';
import { RepositoryType } from '../../entities/repository';
import { Item } from '../../entities/item';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useDeleteFileFromImodel = (
  projectId: string,
  iModelId: string
): [
  boolean,
  (
    file: JobDefinitionFile,
    jobDefinition: JobDefinition
  ) => Promise<ResponseData<null>>
] => {
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = { initialData: null, initialIsLoading: true };
  const [, , , fetchData] = useDataApi<null>(useDataSettings);
  const [isLoading, setIsLoading] = useState(false);

  const deleteFile = useCallback(
    async (fileToDelete: JobDefinitionFile, jobDefinition: JobDefinition) => {
      const repo = getRepository(jobDefinition, projectId);
      const repoType = getBridgeRepositoryType(repo.type);
      const datasourceUrl = getDatasourceUrl(repo);
      const jobId = newGuid();
      const body = {
        ActivityId: jobId,
        BridgeType: fileToDelete.bridgeType,
        Environment: environmentString(buddiUrls.region),
        InputSASUrl: getFileRepositoryUrl(fileToDelete.file, jobDefinition),
        ContextId: projectId,
        iModelName: iModelId,
        DMSType: repoType,
        GPRId: 2996,
        DocumentGuid: fileToDelete.file.id,
        InputFile: fileToDelete.file.name,
        DeleteFile: fileToDelete.file.name,
        DatasourceUrl: datasourceUrl,
      };

      const requestData: RequestData<null> = {
        url: `${buddiUrls.orchestrationServiceUrl}/api/bridgestart`,
        method: 'POST',
        body: JSON.stringify(body),
      };

      setIsLoading(true);
      const result = await fetchData(requestData);
      setIsLoading(false);

      return { ...result };
    },
    [fetchData, buddiUrls]
  );

  return [isLoading, deleteFile];
};
export const environmentString = (region: string): string => {
  switch (region) {
    case '103':
      return 'DEV';
    case '102':
    case '294':
      return 'QA';
    default:
      return 'RELEASE';
  }
};

export const getFileRepositoryUrl = (file: Item, jobDef: JobDefinition) => {
  const repositoryType = jobDef.repositoryType as RepositoryType;
  switch (repositoryType) {
    case RepositoryType.PWDI_LEGACY:
    case RepositoryType.PWDI: {
      return `${jobDef.repositoryUrl.split('{')[0]}{${file.id}}`;
    }
    case RepositoryType.PROJECTSHARE: {
      const separator = '/';
      const repoUrlSplit = jobDef.repositoryUrl.split(separator);
      const projectId = repoUrlSplit[0];
      return [projectId, file.parentId, file.id].join(separator);
    }
    default: {
      return jobDef.repositoryUrl;
    }
  }
};

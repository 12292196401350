import {
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  Modal,
  ModalButtonBar,
  ModalContent,
  Radio,
  Tooltip,
} from '@itwin/itwinui-react';
import { ModalProps } from '../../../hooks/useModal/useModal';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  NotificationContext,
  NotificationType,
} from '../../../entities/notificationTypes';
import { useToast } from '../../../context/toastContext/toastContext';
import { useUsageLogger } from '../../../context/usageLoggerContext/usageLoggerContext';
import { useUserContext } from '../../../context/userContext/userContext';
import { useFeatureToggleContext } from '../../../context/featureToggleContext/featureToggleContext';
import { Events } from '../../../context/usageLoggerContext/eventConstants';
import { SyncFeedbackRating } from '../../../entities/feedbackRating';
import './emailNotificationFeedbackModal.scss';

export const EmailNotificationFeedbackModal: React.FC<ModalProps> = (
  props: ModalProps
) => {
  const { isDismissible, onClose, ...otherProps } = props;
  const { t } = useTranslation();
  const { toastSuccess } = useToast();
  const { logEvent } = useUsageLogger();
  const { user } = useUserContext();
  const { enableEmailNotificationFeedbackModal } = useFeatureToggleContext();

  const [
    showEmailNotificationFeedbackModal,
    setShowEmailNotificationFeedbackModal,
  ] = useState<boolean>(true);

  const [rating, setRating] = useState<string>(SyncFeedbackRating.NoRating);

  let yes_label = t('Yes_Label');
  let no_label = t('No_Label');
  const [nonConnOwner, setNonConnOwner] = useState<string>('');

  let selectedNotificationContexts: string[] = [];
  const [preferredNotificationContext, setPreferredNotificationContext] =
    useState<string[]>(selectedNotificationContexts);

  let selectedNotificationTypes: string[] = [];
  const [preferredNotificationTypes, setPreferredNotificationTypes] = useState<
    string[]
  >(selectedNotificationTypes);

  let inputPlaceholderString = t('TextArea_Placeholder');
  const [feedbackMessage, setFeedbackMessage] = useState<string>('');

  useEffect(() => {
    const showEmailNotificationFeedbackModalValue = localStorage.getItem(
      'showEmailNotificationFeedbackModal'
    );
    if (showEmailNotificationFeedbackModalValue) {
      setShowEmailNotificationFeedbackModal(
        JSON.parse(showEmailNotificationFeedbackModalValue)
      );
    }
  }, []);

  //https://react.dev/learn/updating-arrays-in-state#
  const updatePreferredNotificationContext = (
    isSelected: boolean,
    currentValue: string
  ) => {
    if (isSelected) {
      const updatedArr = [...preferredNotificationContext, currentValue];
      setPreferredNotificationContext(updatedArr);
    } else {
      const filteredArr = preferredNotificationContext.filter(
        (notificationContext: string) => notificationContext != currentValue
      );
      setPreferredNotificationContext(filteredArr);
    }
  };

  const updatePreferredNotificationTypes = (
    isSelected: boolean,
    currentValue: string
  ) => {
    if (isSelected) {
      const updatedArr = [...preferredNotificationTypes, currentValue];
      setPreferredNotificationTypes(updatedArr);
    } else {
      const filteredArr = preferredNotificationTypes.filter(
        (notificationType: string) => notificationType != currentValue
      );
      setPreferredNotificationTypes(filteredArr);
    }
  };

  const constructUserNotificationTypesObject = (typePreferences: string[]) => {
    const notificationTypesObject = {
      SyncSuccess: typePreferences.includes(NotificationType.SyncSuccess),
      SyncFailure: typePreferences.includes(NotificationType.SyncFailure),
      SyncScheduleSuspension: typePreferences.includes(
        NotificationType.ScheduleSuspension
      ),
      SynSummary: typePreferences.includes(NotificationType.Summary),
    };
    return notificationTypesObject;
  };

  const constructUserNotificationContextObject = (
    contextPreferences: string[]
  ) => {
    const notificationContextObject = {
      iModel: contextPreferences.includes(NotificationContext.iModel),
      iTwin: contextPreferences.includes(NotificationContext.iTwin),
    };
    return notificationContextObject;
  };

  const sendEmailNotificationFeedbackEventToAppInsights = (
    hasClickedSubmit: boolean
  ) => {
    const notificationTypePrefObject = constructUserNotificationTypesObject(
      preferredNotificationTypes
    );

    const notificationContextPrefObject =
      constructUserNotificationContextObject(preferredNotificationContext);

    //send appInsighsts event
    logEvent(Events.EmailNotificationFeedback, [
      { name: 'hasClickedSubmit', value: hasClickedSubmit.toString() },
      { name: 'userId', value: user.profile.sub },
      { name: 'NotificationSentimentScore', value: rating },
      { name: 'IsNonConnectionOwnerNotificationUseful', value: nonConnOwner },
      {
        name: 'PreferredNotificationContext',
        value: JSON.stringify(notificationContextPrefObject),
      },
      {
        name: 'PreferredNotificationTypes',
        value: JSON.stringify(notificationTypePrefObject),
      },
      {
        name: 'feedbackMessage',
        value: feedbackMessage,
      },
    ]);
  };

  const acceptFeedback = (hasClickedSubmit: boolean) => {
    sendEmailNotificationFeedbackEventToAppInsights(hasClickedSubmit);

    localStorage.setItem('showEmailNotificationFeedbackModal', 'false');
    setShowEmailNotificationFeedbackModal(false);
    if (hasClickedSubmit) {
      toastSuccess(t('Feedback_Submit_Toast'));
    }
    onClose();
  };

  return enableEmailNotificationFeedbackModal &&
    showEmailNotificationFeedbackModal ? (
    <Modal
      {...otherProps}
      isDismissible={false}
      title={
        <div className="notification-feedback-title">
          {t('Feedback_Modal_Title')}
        </div>
      }
      className="notification-feedbck-modal-container"
    >
      <ModalContent className="notification-feedbck-modal">
        <Flex>
          <div>{t('Notification_Description_Statement1')}</div>
          <div className="feature-name">
            {t('Notification_Description_Statement2')}
          </div>
          <div>{t('Notification_Description_Statement3')}</div>
        </Flex>

        <div className="feedback-question required">
          <div className="required-start required-label">
            {t('Required_Label')}
          </div>
          {t('Notification_Sentiment_Rating')}
        </div>

        <InputGroup className="rating-input-group" displayStyle="inline">
          <div className="extreme-sentiment-label content-font">
            {t('Extreme_Low_Sentiment')}
          </div>
          <InputGroup className="input-group-option">
            <div className="div-rating-label">1</div>
            <Radio
              value={SyncFeedbackRating.Rating1}
              checked={rating === SyncFeedbackRating.Rating1}
              onChange={e => {
                setRating(e.target.value);
              }}
            />
          </InputGroup>

          <InputGroup className="input-group-option">
            <div className="div-rating-label">2</div>
            <Radio
              value={SyncFeedbackRating.Rating2}
              checked={rating === SyncFeedbackRating.Rating2}
              onChange={e => {
                setRating(e.target.value);
              }}
            />
          </InputGroup>

          <InputGroup className="input-group-option">
            <div className="div-rating-label">3</div>
            <Radio
              value={SyncFeedbackRating.Rating3}
              checked={rating === SyncFeedbackRating.Rating3}
              onChange={e => {
                setRating(e.target.value);
              }}
            />
          </InputGroup>

          <InputGroup className="input-group-option">
            <div className="div-rating-label">4</div>
            <Radio
              value={SyncFeedbackRating.Rating4}
              checked={rating === SyncFeedbackRating.Rating4}
              onChange={e => {
                setRating(e.target.value);
              }}
            />
          </InputGroup>

          <InputGroup className="input-group-option">
            <div className="div-rating-label">5</div>
            <Radio
              value={SyncFeedbackRating.Rating5}
              checked={rating === SyncFeedbackRating.Rating5}
              onChange={e => {
                setRating(e.target.value);
              }}
            />
          </InputGroup>
          <div className="extreme-sentiment-label content-font">
            {t('Extreme_High_Sentiment')}
          </div>
        </InputGroup>

        <div className="feedback-question-bold">
          {t('User_Preferences_Label')}
        </div>
        <Flex
          display="inline-flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <div className="div-preferences">
            <div>{t('User_address')}</div>
            <Flex
              display="inline-flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <div className="configure-notifications">
                {t('Question_Notification_Context')}
                <InputGroup
                  className="notification-context-options"
                  displayStyle="inline"
                >
                  <Checkbox
                    className="div-radio-option-1 content-font"
                    label={t('iModel_Label')}
                    value={NotificationContext.iModel}
                    onChange={e => {
                      updatePreferredNotificationContext(
                        e.target.checked,
                        e.target.value
                      );
                    }}
                  />
                  <Checkbox
                    className="div-radio-option-1 content-font"
                    label={t('iTwin_Label')}
                    value={NotificationContext.iTwin}
                    onChange={e => {
                      updatePreferredNotificationContext(
                        e.target.checked,
                        e.target.value
                      );
                    }}
                  />
                </InputGroup>
              </div>
            </Flex>
          </div>

          <div className="div-preferences">
            <div className="preferences-label">
              {t('Question_Notification_Preferences')}
            </div>
            <InputGroup>
              <Flex display="inline-flex">
                <div className="div-radio-option-1">
                  <Checkbox
                    className="content-font"
                    label={t('Success_Notification_Type')}
                    value={NotificationType.SyncSuccess}
                    onChange={e => {
                      updatePreferredNotificationTypes(
                        e.target.checked,
                        e.target.value
                      );
                    }}
                  />

                  <Tooltip content={t('Schedule_Suspension_Tooltip')}>
                    <Checkbox
                      className="content-font"
                      label={t('ScheduleSuspension_Notification_Type')}
                      value={NotificationType.ScheduleSuspension}
                      onChange={e => {
                        updatePreferredNotificationTypes(
                          e.target.checked,
                          e.target.value
                        );
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="div-radio-option-1">
                  <Checkbox
                    className="content-font"
                    label={t('Failure_Notification_Type')}
                    value={NotificationType.SyncFailure}
                    onChange={e => {
                      updatePreferredNotificationTypes(
                        e.target.checked,
                        e.target.value
                      );
                    }}
                  />

                  <Tooltip content={t('Sync_Summary_Tooltip')}>
                    <Checkbox
                      className="content-font"
                      label={t('Summary_Notification_Type')}
                      value={NotificationType.Summary}
                      onChange={e => {
                        updatePreferredNotificationTypes(
                          e.target.checked,
                          e.target.value
                        );
                      }}
                    />
                  </Tooltip>
                </div>
              </Flex>
            </InputGroup>
          </div>

          <div>
            <div>{t('Question_Notification_ConnectionOwner')}</div>
            <InputGroup className="radio-group" displayStyle="inline">
              <Radio
                className="div-radio-option-1 content-font"
                value="Yes"
                checked={nonConnOwner == 'Yes'}
                label={yes_label}
                onChange={e => {
                  setNonConnOwner(e.target.value);
                }}
              />
              <Radio
                className="div-radio-option-2 content-font"
                value="No"
                checked={nonConnOwner == 'No'}
                label={no_label}
                onChange={e => {
                  setNonConnOwner(e.target.value);
                }}
              />
            </InputGroup>
          </div>
        </Flex>

        <div className="feedback-question">
          <Input
            placeholder={inputPlaceholderString}
            value={feedbackMessage}
            maxLength={512}
            onChange={e => {
              setFeedbackMessage(e.target.value);
            }}
          />
        </div>
      </ModalContent>

      <ModalButtonBar>
        <Button
          styleType="high-visibility"
          disabled={rating == SyncFeedbackRating.NoRating}
          onClick={() => acceptFeedback(true)}
        >
          {t('Submit_Label')}
        </Button>
        <Button onClick={() => acceptFeedback(false)}>
          {t('Dismiss_Label')}
        </Button>
      </ModalButtonBar>
    </Modal>
  ) : (
    <></>
  );
};

import React, { createContext, ProviderProps, useContext } from 'react';
import { BuddiUrls } from '../../typedef/index';

export interface IBuddiContext {
  buddiUrls: BuddiUrls;
}

export const BuddiContext = createContext<IBuddiContext>({
  buddiUrls: {} as BuddiUrls,
});

export interface BuddiContextProviderProps
  extends Omit<ProviderProps<IBuddiContext>, 'value'> {
  buddiUrls: BuddiUrls;
}

export const BuddiContextProvider = ({
  ...props
}: BuddiContextProviderProps) => {
  const { buddiUrls } = props;

  return (
    <BuddiContext.Provider
      value={{
        buddiUrls,
      }}
      {...props}
    />
  );
};

export const useBuddiContext = () => {
  const context = useContext(BuddiContext);
  if (context == null) {
    throw new Error('useBuddiContext must be used inside provider');
  }
  return context;
};

import React from 'react';

export interface TextLinkProps {
  url: string;
  children: string;
}
export const TextLink = (props: TextLinkProps) => {
  const { url, children } = props;
  return (
    <a
      className="iui-anchor"
      href={url}
      target="_blank"
      data-testid="text-link"
    >
      {children}
    </a>
  );
};

import React from 'react';
import { Item, ItemType } from '../../entities/item';
import { getFileExtension } from '../../services/bridgeLogic/bridgeLogic';
import { RepositoryType } from '../../entities/repository';
import { assertUnreachable } from '../../services/uiUtils/assertUnreachable';
import SvgLogicalSet from '../icons/LogicalSet';
import './itemIcon.scss';
import {
  SvgFiletypeAmim,
  SvgFiletypeAutocad,
  SvgFiletypeDgndb,
  SvgFiletypeImage,
  SvgFiletypeImodel,
  SvgFiletypeMicrostation,
  SvgFiletypeMism,
  SvgFiletypePdf,
  SvgFiletypePdfMarkup,
  SvgFiletypePpt,
  SvgFiletypeRevit,
  SvgFiletypeTxt,
  SvgFiletypeVideo,
  SvgFiletypeWord,
  SvgFiletypeXls,
  SvgFolder,
} from '@itwin/itwinui-icons-color-react';
import { Icon } from '../icons/icon';
import { SvgDocument } from '@itwin/itwinui-icons-react';
import SvgDocumentSet from '../icons/DocumentSet';
import SvgPWDILegacy from '../icons/SvgPWDILegacy';
import SvgShareIconNoFill from '../icons/ShareIconNoFill';
import SvgShareIcon from '../icons/ShareIcon';
import SvgProjectwise from '../icons/SvgPWDI';
import SvgFiletypeIfc from '../icons/SvgFiletypeIfc';

export const getItemIcon = (item: Item, iconClassName?: string) => {
  return item.isFolder ? (
    <Icon
      icon={SvgFolder}
      size="large"
      data-testid="svg-folder"
      className="table-cell-icon"
    />
  ) : (
    getFileIcon(item, iconClassName)
  );
};

export const getFileIcon = (
  item: Item,
  iconClassName?: string
): JSX.Element => (
  <div className="file-icon-wrapper">
    {getIcon(item, iconClassName)}
    {item.itemType === 'LogicalSet' && (
      <Icon
        icon={SvgLogicalSet}
        size="large"
        data-testid="logical-set-icon"
        className="icon-overlay-logical-set"
      />
    )}
  </div>
);

export const getIcon = (
  item: Item,
  iconClassName: string = 'table-cell-icon'
) => {
  if (item.itemType === ItemType.FlatSet) {
    return (
      <Icon
        icon={SvgDocumentSet}
        size="large"
        className={iconClassName}
        data-testid="svg-document-set"
      />
    );
  }
  const fileType = getFileType(item.name ?? '');

  switch (fileType) {
    case 'Amim':
      return (
        <Icon icon={SvgFiletypeAmim} size="large" className={iconClassName} />
      );
    case 'i-model':
      return (
        <Icon icon={SvgFiletypeImodel} size="large" className={iconClassName} />
      );
    case 'DgnDb':
      return (
        <Icon icon={SvgFiletypeDgndb} size="large" className={iconClassName} />
      );
    case 'Dgn-Db':
      return (
        <Icon icon={SvgFiletypeImodel} size="large" className={iconClassName} />
      );
    case 'Text':
      return (
        <Icon icon={SvgFiletypeTxt} size="large" className={iconClassName} />
      );
    case 'Image':
      return (
        <Icon icon={SvgFiletypeImage} size="large" className={iconClassName} />
      );
    case 'AutoCad':
      return (
        <Icon
          icon={SvgFiletypeAutocad}
          size="large"
          className={iconClassName}
        />
      );
    case 'Mism':
      return (
        <Icon icon={SvgFiletypeMism} size="large" className={iconClassName} />
      );

    case 'Pdf':
      return (
        <Icon icon={SvgFiletypePdf} size="large" className={iconClassName} />
      );
    case 'PowerPoint':
      return (
        <Icon icon={SvgFiletypePpt} size="large" className={iconClassName} />
      );

    case 'Video':
      return (
        <Icon icon={SvgFiletypeVideo} size="large" className={iconClassName} />
      );
    case 'Word':
      return (
        <Icon icon={SvgFiletypeWord} size="large" className={iconClassName} />
      );

    case 'Excel':
      return (
        <Icon icon={SvgFiletypeXls} size="large" className={iconClassName} />
      );

    case 'Document':
      return (
        <Icon
          icon={SvgDocument}
          size="large"
          color="default"
          className={iconClassName}
        />
      );

    case 'Revit':
      return (
        <Icon icon={SvgFiletypeRevit} size="large" className={iconClassName} />
      );

    case 'Ifc':
      return (
        <Icon icon={SvgFiletypeIfc} size="large" className={iconClassName} />
      );

    case 'MicroStation':
      return (
        <Icon
          icon={SvgFiletypeMicrostation}
          size="large"
          className={iconClassName}
          data-testid="svg-mstn"
        />
      );

    case 'Markup':
      return (
        <Icon
          icon={SvgFiletypePdfMarkup}
          size="large"
          className={iconClassName}
        />
      );

    default:
      return (
        <Icon
          icon={SvgDocument}
          size="large"
          color="default"
          className={iconClassName}
        />
      );
  }
};

export const getFileType = (name: string): string =>
  getFileTypeByExtension(getFileExtension(name).toLowerCase());

export const getFileTypeByExtension = (extension: string) => {
  let type: string = '';

  switch (extension.toLowerCase()) {
    case 'anim':
      type = 'Anim';
      break;
    case 'amim':
      type = 'Amim';
      break;
    case 'dgn.i.dgn':
    case 'i.dgn':
    case 'i-model':
    case 'imodel':
      type = 'i-model';
      break;
    case 'bim':
      type = 'Bim';
      break;
    case 'dgndb':
      type = 'DgnDb';
      break;
    case 'dgn-db':
      type = 'Dgn-Db';
      break;
    case 'txt':
      type = 'Text';
      break;
    case 'png':
    case 'jpg':
    case 'bmp':
    case 'gif':
    case 'tif':
      type = 'Image';
      break;
    case 'dwg':
    case 'dxf':
    case 'skp':
    case 'obj':
    case 'cad':
      type = 'AutoCad';
      break;
    case 'mism':
      type = 'Mism';
      break;
    case 'pdf':
      type = 'Pdf';
      break;
    case 'pptx':
    case 'pptm':
    case 'potx':
    case 'potm':
    case 'pot':
    case 'pps':
    case 'ppsx':
    case 'ppsm':
    case 'ppam':
    case 'ppa':
    case 'ppt':
      type = 'PowerPoint';
      break;
    case 'avi':
    case 'amv':
    case 'mp4':
    case 'wmv':
      type = 'Video';
      break;
    case 'doc':
    case 'dot':
    case 'wbk':
    case 'docx':
    case 'docm':
    case 'dotx':
    case 'dotm':
    case 'docb':
      type = 'Word';
      break;
    case 'xls':
    case 'xlt':
    case 'xlm':
    case 'xlsx':
    case 'xlsm':
    case 'xltx':
    case 'xltm':
      type = 'Excel';
      break;
    case 'xml':
    case 'html':
    case 'htm':
      type = 'Document';
      break;
    case 'rvt':
    case 'rfa':
      type = 'Revit';
      break;
    case 'rcnx':
    case 'retx':
      type = 'RAM';
      break;
    case 'std':
      type = 'Staad';
      break;
    case 'zip':
    case 'rar':
    case 'arj':
    case 'tar':
    case 'tgz':
    case '7z':
      type = 'Compressed';
      break;
    case 'dgn':
      type = 'MicroStation';
      break;
    case 'xfdf':
      type = 'Markup';
      break;
    case 'log':
      type = 'Log';
      break;
    case 'msg':
      type = 'Message';
      break;
    case 'ifc':
      type = 'Ifc';
      break;
    default:
      type = 'Unknown';
      break;
  }

  return type;
};

export const getIconForRepository = (repositoryType: RepositoryType) => {
  switch (repositoryType) {
    case RepositoryType.PROJECTSHARE:
      return <Icon icon={SvgShareIcon} data-testid={'share-icon'} />;
    case RepositoryType.PWDI:
      return (
        <Icon
          icon={SvgProjectwise}
          className="repository-icon-blue"
          data-testid={'pwdi-icon'}
        />
      );
    case RepositoryType.PW365:
      return <Icon icon={SvgProjectwise} data-testid={'pwdi-365'} />;
    case RepositoryType.PWDI_LEGACY:
      return <Icon icon={SvgPWDILegacy} data-testid={'pwdi-legacy-icon'} />;
    case RepositoryType.SHAREPOINT:
      throw new Error('Icon for repository type SHAREPOINT not implemented');
    case RepositoryType.MANIFEST:
      return <Icon icon={SvgShareIconNoFill} data-testid={'share-icon'} />;
    default:
      throw assertUnreachable(repositoryType);
  }
};
export const getIconForRepositoryTile = (repositoryType: RepositoryType) => {
  switch (repositoryType) {
    case RepositoryType.PROJECTSHARE:
      return <SvgShareIconNoFill data-testid={'share-icon'} />;
    case RepositoryType.PWDI:
    case RepositoryType.PW365:
    case RepositoryType.PWDI_LEGACY:
      return <SvgProjectwise data-testid={'pwdi-icon'} />;
    case RepositoryType.SHAREPOINT:
      throw new Error('Icon for repository type SHAREPOINT not implemented');
    case RepositoryType.MANIFEST:
      throw new Error('Icon for repository type MANIFEST not implemented');
    default:
      throw assertUnreachable(repositoryType);
  }
};

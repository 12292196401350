import { Component } from 'react';
import { isRedirect } from '@reach/router';
import { LoggerContext } from '../../externalComponents/context/loggerContext/loggerContext';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NonIdealState } from '@itwin/itwinui-react';
import { SvgError } from '@itwin/itwinui-illustrations-react/cjs/illustrations';

interface Props extends WithTranslation {
  children?: any;
}

interface ErrorInfo {
  componentStack: string;
}

interface State {
  error?: Error;
  info?: ErrorInfo;
}

// Note: error boundary is not available in React hooks as of v16, so we use a class component instead
class GenericErrorBoundary extends Component<Props, State> {
  static contextType = LoggerContext;
  context!: React.ContextType<typeof LoggerContext>;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const { logError } = this.context;
    if (isRedirect(error)) {
      throw error;
    }

    try {
      logError(
        'Unknown error occurred: {error}. Component stack: {componentStack}.',
        [error, info?.componentStack ?? '']
      );
    } catch (ignoredError) {
      // pass
    }

    this.setState({ error, info });
  }

  render() {
    const { children } = this.props;
    const { error, info } = this.state;
    const { t } = this.props;

    if (error) {
      return (
        <NonIdealState
          svg={<SvgError />}
          heading={t('GenericErrorPage_Title')}
          description={<>{t('GenericErrorPage_Caption')}</>}
          data-testid="error-page"
        />
      );
    }

    return children || null;
  }
}

export default withTranslation()(GenericErrorBoundary);

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from '../../../entities/item';
import {
  getColumnHeaderIUI,
  getTextColumnIUI,
} from '../../../services/uiUtils/tableColumnWrappers';
import { FlexTableWrapper } from '../../flexTableWrapper/flexTableWrapper';
import { getItemIcon } from '../../itemIcon/itemIcon';
import { NavigatableCellIUI } from '../../navigatableCell/navigatableCell';
import { Entity } from '../../../entities/entity';
import {
  sortItemComparator,
  sortItems,
} from '../../../services/uiUtils/sortItems';
import { Repository, RepositoryType } from '../../../entities/repository';
import { Radio, Table } from '@itwin/itwinui-react';
import { Column } from 'react-table';

export interface SpatialRootFileSelectionTableProps {
  repository: Repository;
  masterFiles: Item[];
  onSelection: (item: Item) => void;
  isSelected: (obj: Entity) => boolean;
  isItemSelectableAsSpatialRoot: (item: Item) => boolean;
}

export const SpatialRootFileSelectionTable = (
  props: SpatialRootFileSelectionTableProps
) => {
  const {
    repository,
    masterFiles,
    onSelection,
    isSelected,
    isItemSelectableAsSpatialRoot: isItemSelectable,
  } = props;
  const { t } = useTranslation();

  const [sortedFiles, setSortedFiles] = useState<Item[]>([]);

  useEffect(() => {
    setSortedFiles(
      sortItems(masterFiles).map(x => ({
        ...x,
      }))
    );
  }, [masterFiles]);

  const columns = useMemo(
    (): Column<Item>[] => [
      {
        Cell: ({ row: { original } }) => (
          <Radio
            name="master-model-radio"
            checked={isSelected(original)}
            onClick={() => {
              onSelection(original);
            }}
            disabled={isItemSelectable(original)}
            data-testid="RadioButton"
          />
        ),
        minWidth: 40,
        maxWidth: 40,
        accessor: 'id',
        disableSortBy: true,
      },
      {
        ...getColumnHeaderIUI<any, 'name'>(t('NameColumnHeader_Label'), 'name'),
        Cell: ({ row: { original } }) => (
          <NavigatableCellIUI
            name={original.name}
            leftIcon={getItemIcon(original, 'table-cell-icon')}
          />
        ),
        minWidth: 150,
        accessor: 'name',
        sortDescFirst: true,
        sortType: ({ original: a }, { original: b }, _, desc?) =>
          sortItemComparator(a, b, desc),
      },
      ...(repository.type === RepositoryType.PWDI ||
      repository.type === RepositoryType.PWDI_LEGACY
        ? [
            {
              ...getTextColumnIUI<Item, 'description'>(
                t('DescriptionColumnHeader_Label'),
                'description'
              ),
              disableSortBy: true,
            },
            {
              ...getTextColumnIUI<Item, 'version'>(
                t('VersionColumnHeader_Label'),
                'version'
              ),
              disableSortBy: true,
            },
          ]
        : []),
    ],
    [onSelection]
  );

  return (
    <>
      <div className={'job-files-table'}>
        <FlexTableWrapper>
          <Table<{ [P in keyof Item]: Item[P] }>
            data={sortedFiles}
            columns={columns}
            emptyTableContent={t('CompositesEmpty_Label')}
            className="iui-condensed"
            isSortable
            disableSortRemove
          />
        </FlexTableWrapper>
      </div>
    </>
  );
};

import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useCallback } from 'react';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export interface iModelUser {
  Id: string;
  Name: string;
  Surname: string;
  Email: string;
}

export const useFetchiModelUsers = (): [
  iModelUser[],
  boolean,
  any,
  (imodelId: string) => Promise<ResponseData<iModelUser[]>>
] => {
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = { initialData: [], initialIsLoading: false };
  const [data, isLoading, error, fetchData] = useDataApi<iModelUser[]>(
    useDataSettings
  );

  const fetchiModelUsers = useCallback(
    (imodelId: string) => {
      const requestData: RequestData<iModelUser[]> = {
        method: 'GET',
        url: `${buddiUrls.iModelHubUrl}/sv1.1/Repositories/iModel--${imodelId}/iModelScope/UserInfo?$select=*&$filter=IsServiceAccount eq false&$orderby=Name asc,Surname asc`,
        map: responseToIMSUsers,
      };
      return fetchData(requestData);
    },
    [buddiUrls, fetchData]
  );

  return [data, isLoading, error, fetchiModelUsers];
};

const responseToIMSUsers = (response: {
  instances: [{ properties: iModelUser }];
}): any => {
  return response != null
    ? response.instances.map(i => i.properties)
    : ({} as iModelUser);
};

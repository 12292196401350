import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './connectionDefinitionChangeMappings.scss';
import {
  ApiVersion,
  ExecutionAction,
  JobDefinition,
  JobDefinitionFile,
  JobFiles,
} from '../../entities/jobDefinition/jobDefinition';
import { useModal } from '../../hooks/useModal/useModal';
import { useTranslation } from 'react-i18next';
import { AddCompositeModelModal } from '../addCompositeModelModal/addCompositeModelModal';
import { Item } from '../../entities/item';
import { Repository, RepositoryType } from '../../entities/repository';
import { useToast } from '../../context/toastContext/toastContext';
import { useUpdateJobDefinition } from '../../hooks/useUpdateJobDefinition/useUpdateJobDefinition';
import { JobDefinitionFilesTable } from '../jobDefinitionFilesTable/jobDefinitionFilesTable';
import { getRepository } from '../../services/jobDefinitionUtils/getRepositoryFromJobDefinition';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import { useDeleteInputFile } from '../../hooks/useDeleteInputFile/useDeleteInputFile';
import { useCreateInputFile } from '../../hooks/useCreateInputFile/useCreateInputFile';
import { BridgeAssignmentTable } from '../jobDefinitionFilesTable/bridgeAssignmentTable';
import {
  MasterContext,
  useMastersContext,
} from '../../hooks/useGetMasterReferencesTree/useGetMasterReferencesTree';
import { BridgeSelectionModal } from '../modals/bridgeSelectionModal/bridgeSelectionModal';
import { Searchbar } from '../searchBar/searchBar';
import { useCreateAffinityReport } from '../../hooks/useCreateAffinityReport/useCreateAffinityReport';
import { BridgeSelection } from '../../hooks/useBridgeSelection/useBridgeSelection';
import { FullPageLoader } from '../fullPageLoader/fullPageLoader';
import { useDeleteFileFromImodel } from '../../hooks/useDeleteFileFromImodel/useDeleteFileFromImodel';
import {
  ConfirmationType,
  ConfirmDeleteFilesModal,
} from '../confirmDeleteFilesModal/confirmDeleteFilesModal';
import { useFlagInputFileForUnmap } from '../../hooks/useFlagInputFileForUnmap/useFlagInputFileForUnmap';
import { useRunJob } from '../../hooks/useRunJob/useRunJob';
import { BridgeType } from '../../entities/bridge';
import {
  Tooltip,
  Button,
  IconButton,
  NonIdealState,
  ButtonGroup,
} from '@itwin/itwinui-react';
import { useGetJobStatus } from '../../hooks/useGetJobRunStatus/useGetJobRunStatus';
import { useGetItemsByIds } from '../../hooks/useGetItemsByIds/useGetItemsByIds';
import { ExecutionStrategy } from '../../entities/jobDefinition/jobDefinitionDTOv2';
import { useGetAffinityReport } from '../../hooks/useGetAffinityReport/useGetAffinityReport';
import { withConditionalWrapper } from '../../services/uiUtils/withConditionalWrapper';
import { SvgAdd, SvgEdit } from '@itwin/itwinui-icons-react';
import { useBentleyContext } from '../../context/bentleyContext/bentleyContext';
import { useConnectionDefintionsContext } from '../../context/connectionDefinitionsContext/connectionsDefintionsContext';
import { useSpatialRootsContext } from '../../context/spatialRootsContext/spatialRootsContext';
import { ConnectionDefinitionChangeMappingsProps } from '../../typedef/index';
import { useUserContext } from '../../context/userContext/userContext';
import { useInputFilesContext } from '../../context/inputFilesContext/inputFilesContext';
import { Svg404 } from '@itwin/itwinui-illustrations-react';

interface ErrorData {
  errorMessage: string;
  errorValues?: ErrorValuesObject;
}

interface ErrorValuesObject {
  error: object;
}

export const ConnectionDefinitionChangeMappings = (
  props: ConnectionDefinitionChangeMappingsProps
) => {
  const { connectionDefinitionId, onChangeMappingsCallback, onCancelCallback } =
    props;

  const { t } = useTranslation();
  const { fileBridgeAssignment, deleteModel } = useFeatureToggleContext();
  const { toastError, toastSuccess } = useToast();
  const { projectId, iModelId } = useBentleyContext();
  const { user } = useUserContext();

  const {
    connectionsDefintions: jobDefs,
    areConnectionDefintionsLoading: areJobsLoading,
    fetchConnectionDefintions: fetchJobDefinitions,
  } = useConnectionDefintionsContext();

  const { spatialRoots, areSpatialRootsLoading } = useSpatialRootsContext();

  const job = useMemo(() => {
    return jobDefs.find(x => x.id === connectionDefinitionId);
  }, [jobDefs]);

  const isAffinityAvailable = useMemo(() => {
    return fileBridgeAssignment && job && job.apiVersion === ApiVersion.v2;
  }, [job]);

  const [changedJob, setChangedJob] = useState<JobDefinition>();

  useEffect(() => {
    if (job && !areJobsLoading) {
      setChangedJob(job);
    }
  }, [job, areJobsLoading]);

  const [creatingReports, createReport] = useCreateAffinityReport();

  const [filesToAdd, setFilesToAdd] = useState<Item[]>([]);

  const [isInputFileDeleting, deleteInputFile] = useDeleteInputFile(
    projectId,
    iModelId
  );

  const [, flagInputFileForUnmap] = useFlagInputFileForUnmap(
    projectId,
    iModelId
  );

  const [, , , runJob] = useRunJob(projectId, iModelId);

  const [, deleteFileFromImodel] = useDeleteFileFromImodel(projectId, iModelId);

  const [, isInputFileCreating, , createInputFile] = useCreateInputFile(
    projectId,
    iModelId
  );

  const [bridgeSelectionChanged, setBridgeSelectionChanged] = useState(false);

  const [, isJobDefUpdating, , updateJobDefinition] = useUpdateJobDefinition(
    projectId,
    iModelId
  );

  const [isAffinityEnabledForConnection, setIsAffinityEnabledForConnection] =
    useState<boolean>();

  useEffect(() => {
    setIsAffinityEnabledForConnection(isAffinityAvailable);
  }, [isAffinityAvailable]);

  const repository: Repository | undefined = useMemo(() => {
    if (changedJob) {
      return getRepository(changedJob, projectId);
    }
  }, [changedJob]);

  const {
    modalProps: addCompositeModelModalProps,
    openModal: openAddCompositeModelModal,
    closeModal: closeAddCompositeModelModal,
  } = useModal();

  const {
    openModal: openBridgeSelectionModal,
    modalProps: bridgeSelectionModalProps,
  } = useModal();
  const {
    openModal: openDeleteConfirmModal,
    modalProps: deleteConfirmModalProps,
    closeModal: closeDeleteConfirmModal,
  } = useModal();

  const [jobFiles, setJobFiles] = useState<JobFiles>();

  useEffect(() => {
    if (
      !areSpatialRootsLoading &&
      changedJob &&
      changedJob.apiVersion !== ApiVersion.v2
    ) {
      setJobFiles({
        spatialMaster:
          spatialRoots &&
          spatialRoots.length > 0 &&
          spatialRoots[0] &&
          spatialRoots[0].jobDefinitionId === changedJob.id
            ? ({
                name: spatialRoots[0].name,
                id: spatialRoots[0].id,
              } as Item)
            : undefined,
        masters: changedJob.files
          ? changedJob.files
              .filter(c => spatialRoots[0]?.id !== c.file.id)
              .map(c => {
                return {
                  name: c.file.name,
                  id: c.file.id,
                } as Item;
              })
          : [],
      } as JobFiles);
    }
  }, [spatialRoots, changedJob]);

  const [areInputFilesLoading, setAreInputFilesLoading] = useState(true);

  const { connectionToFilesMap, fetchInputFiles, clearInputFilesMap } =
    useInputFilesContext();

  useEffect(() => {
    const fetchInputFilesAsync = async (job: JobDefinition) => {
      setAreInputFilesLoading(true);
      await fetchInputFiles(job);
      setAreInputFilesLoading(false);
    };

    if (job?.apiVersion === ApiVersion.v2) {
      fetchInputFilesAsync(job);
    } else {
      setAreInputFilesLoading(false);
    }
  }, [fetchInputFiles, job]);

  const [inputFiles, setInputFiles] = useState<JobDefinitionFile[]>([]);
  useEffect(() => {
    if (
      job &&
      connectionToFilesMap &&
      connectionToFilesMap[connectionDefinitionId]
    ) {
      setInputFiles(connectionToFilesMap[connectionDefinitionId]);
    }
  }, [job, connectionDefinitionId, connectionToFilesMap]);

  const [jobStatuses, , , fetchJobStatuses] = useGetJobStatus();

  const [, , , fetchItemsFromIds] = useGetItemsByIds();
  const [isDeletedFileLoading, setIsDeletedFileLoading] =
    useState<boolean>(false);
  const getDeletedFileIds: string[] = [];
  const rootItems: Item[] = [];
  const [, , getReport] = useGetAffinityReport();
  const shouldShowIndividualConnectorsWithoutAffinity =
    (repository?.type === RepositoryType.PROJECTSHARE ||
      repository?.type === RepositoryType.PWDI ||
      repository?.type === RepositoryType.PWDI_LEGACY ||
      repository?.type === RepositoryType.PW365) &&
    isAffinityAvailable!;

  const getItems = useCallback(async () => {
    setIsDeletedFileLoading(true);

    if (repository && job && !areInputFilesLoading) {
      const ids: string[] = [];
      inputFiles.forEach(x => {
        ids.push(x.file.id);
      });

      const itemData = await fetchItemsFromIds(ids, repository);
      if (itemData) {
        inputFiles?.map(inputf => {
          itemData?.data?.forEach(async items => {
            if (inputf.file.id === items.id) {
              inputf.file.version = items.version;
            }
            if (inputf.file.name === null) {
              if (inputf.file.id === items.id) {
                inputf.file.name = items.name;
              }
            }
            if (items.name === t('DataSource_DeletedFile')) {
              if (inputf.file.id === items.id) {
                getDeletedFileIds.push(items.id);
                inputf.file.isDeleted = true;
                if (
                  job.executionStrategy === ExecutionStrategy.ExistingAffinity
                ) {
                  const report = await getReport(job.id);
                  report?.data?.Results.map((x: any) => {
                    if (x.Affinity.FileId === items.id) {
                      rootItems.push({
                        id: x.Affinity.FileId,
                        name: x.Affinity.File,
                        isDeleted: true,
                      } as Item);
                    }
                  });
                } else {
                  rootItems.push({
                    id: inputf.file.id,
                    name: inputf.file.name,
                    isDeleted: true,
                  } as Item);
                }
              }
            } else {
              if (inputf.file.id === items.id) {
                rootItems.push(items);
              }
            }
          });
        });
      }
      if (
        isAffinityAvailable &&
        (job.executionStrategy === ExecutionStrategy.ExistingAffinity ||
          shouldShowIndividualConnectorsWithoutAffinity)
      ) {
        const mc = await fetchMastersContext(
          rootItems,
          [],
          job.id,
          job.executionStrategy === ExecutionStrategy.ExistingAffinity
        );

        setIsAffinityEnabledForConnection(
          (mc.length !== 0 || mastersContextError === null) &&
            job.executionStrategy === ExecutionStrategy.ExistingAffinity
        );
      } else {
        setIsAffinityEnabledForConnection(false);
      }

      setIsDeletedFileLoading(false);
    }
  }, [inputFiles, repository, job, areInputFilesLoading]);

  useEffect(() => {
    getItems();
    if (job?.apiVersion === ApiVersion.v2) {
      setJobFiles({
        spatialMaster: inputFiles.find(f => f.isSpatialRoot)?.file,
        masters: inputFiles.filter(f => !f.isSpatialRoot).map(f => f.file),
      } as JobFiles);

      const itemsMarkedForDeletion = inputFiles.filter(
        f => f.executionAction === ExecutionAction.UnMap
      );

      if (itemsMarkedForDeletion.length > 0) {
        const itemsMarkedForDeletionMap: { [id: string]: string } = {};

        for (const itemMarkedForDeletion of itemsMarkedForDeletion) {
          itemsMarkedForDeletionMap[itemMarkedForDeletion.file.id] =
            itemMarkedForDeletion.id!;
        }

        setItemsToBeRemove({ ...itemsMarkedForDeletionMap });
      } else {
        setItemsToBeRemove({});
      }
    }
  }, [inputFiles, job, areInputFilesLoading]);

  const initialMastersContextLoading =
    isAffinityAvailable &&
    job?.executionStrategy === ExecutionStrategy.ExistingAffinity;
  const [
    mastersContext,
    isMastersContextLoading,
    mastersContextError,
    selectedItems,
    filterMasterContexts,
    setBridgeSelection,
    setSelectedItems,
    setBridgeForSelected,
    addMastersContext,
    ,
    fetchMastersContext,
    isAnyFileSelected,
  ] = useMastersContext(repository, initialMastersContextLoading);

  const [itemsToBeRemoved, setItemsToBeRemove] = useState<{
    [id: string]: string;
  }>({});

  useEffect(() => {
    if (job) {
      fetchJobStatuses(job);
    }
  }, [job]);

  // all files bridge with same bridge
  const jobDefinitionBridge = useMemo(() => {
    if (job) {
      if (
        job.apiVersion === ApiVersion.v2 &&
        inputFiles.length > 0 &&
        inputFiles[0]
      ) {
        return inputFiles[0]?.bridgeType;
      } else {
        return job.files[0]?.bridgeType;
      }
    }
  }, [job, inputFiles]);

  const [filterString, setFilterString] = useState('');
  const [filteredMastersContext, setFilteredMastersContext] = useState<
    MasterContext[]
  >([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  useEffect(() => {
    setFilteredMastersContext(filterMasterContexts(filterString));
    setIsFilterApplied(filterString !== '');
  }, [filterString]);

  const addFilesToJob = useCallback(
    (
      job: JobDefinition,
      newItems: Item[],
      isSpatial: boolean = false
    ): JobDefinition | undefined => {
      if (changedJob) {
        const jobWithAddedFiles: JobDefinition = JSON.parse(
          JSON.stringify(job)
        );
        const itemsToAdd = newItems.map(
          item =>
            ({
              file: item,
              isSpatialRoot: isSpatial,
              bridgeType: jobDefinitionBridge,
              transform: null,
              bridgeArgs: {},
              location: job.datasourceUrl,
              repositoryType: job.repositoryType,
            } as JobDefinitionFile)
        );

        jobWithAddedFiles.files = isSpatial
          ? [...itemsToAdd, ...changedJob.files]
          : [...changedJob.files, ...itemsToAdd];

        return jobWithAddedFiles;
      }
    },
    [changedJob, jobDefinitionBridge]
  );

  const onAddCompositeModels = useCallback(
    async (selectedItems: Item[]) => {
      if (changedJob && jobFiles) {
        if (selectedItems.length > 0) {
          if (changedJob.apiVersion !== ApiVersion.v2) {
            const newChangedJob = addFilesToJob(changedJob, selectedItems);

            setChangedJob(newChangedJob);
          } else {
            setJobFiles({
              spatialMaster: jobFiles.spatialMaster,
              masters: [...jobFiles.masters, ...selectedItems],
            } as JobFiles);

            setFilesToAdd(previousFilesToAdd => [
              ...previousFilesToAdd,
              ...selectedItems,
            ]);
          }
        }

        closeAddCompositeModelModal();
      }
    },
    [changedJob, jobFiles]
  );

  const onAddCompositeModelsAffinity = useCallback(
    async (selectedItems: Item[]) => {
      if (selectedItems.length > 0) {
        setFilesToAdd(previousFilesToAdd => [
          ...previousFilesToAdd,
          ...selectedItems,
        ]);
        addMastersContext(selectedItems);
      }
      closeAddCompositeModelModal();
    },
    [addMastersContext]
  );

  const onRemoveCompositeModel = useCallback(
    async (item: Item) => {
      if (changedJob?.apiVersion !== ApiVersion.v2) {
        setItemsToBeRemove(previousItemsToBeRemoved => {
          if (previousItemsToBeRemoved[item.id] == null) {
            return {
              ...previousItemsToBeRemoved,
              [item.id]: '',
            };
          } else {
            return previousItemsToBeRemoved;
          }
        });
      } else {
        const jobDefinitionFile = inputFiles.find(f => f.file.id === item.id);

        setItemsToBeRemove(previousItemsToBeRemoved => {
          if (previousItemsToBeRemoved[item.id] == null) {
            return {
              ...previousItemsToBeRemoved,
              [item.id]: jobDefinitionFile?.id!,
            };
          } else {
            return previousItemsToBeRemoved;
          }
        });
      }
    },
    [changedJob, inputFiles]
  );

  const onRemoveCompositeModelAffinity = useCallback(
    async (masterContext: MasterContext) => {
      const jobDefinitionFile = inputFiles.find(
        f => f.file.id === masterContext.master.id
      );

      setItemsToBeRemove(previousItemsToBeRemoved => {
        if (previousItemsToBeRemoved[masterContext.master.id] == null) {
          return {
            ...previousItemsToBeRemoved,
            [masterContext.master.id]: jobDefinitionFile?.id!,
          };
        } else {
          return previousItemsToBeRemoved;
        }
      });
    },
    [inputFiles]
  );

  const updateInputFilesV2 = useCallback(
    async (deleteFiles: boolean) => {
      if (job && jobDefinitionBridge) {
        let errored: boolean = false;
        let updatedMastersContext = [...mastersContext];
        let duplicateFilesNames: string[] = [];
        let fileIdsMessages: string[] = [];
        for (const fileToAdd of filesToAdd) {
          let bridgeType: BridgeType;
          if (
            isAffinityEnabledForConnection ||
            shouldShowIndividualConnectorsWithoutAffinity
          ) {
            const masterContext = mastersContext.find(
              mc => mc.master.id === fileToAdd.id
            );

            bridgeType =
              masterContext!.mastersMap[fileToAdd.id].bridgeSelection[
                fileToAdd.id
              ].selectedBridge;
          } else {
            bridgeType = jobDefinitionBridge;
          }

          const createInputFileResult = await createInputFile(
            job,
            fileToAdd,
            bridgeType,
            fileToAdd.id === jobFiles?.spatialMaster?.id
          );

          const result = JSON.parse(createInputFileResult.error);
          if (
            result &&
            result.error &&
            result.error.Message.includes('already mapped to the iModel')
          ) {
            let errorDetailsArray: any;
            errorDetailsArray = Array.from(result?.error?.Details);
            fileIdsMessages = fileIdsMessages.concat(
              errorDetailsArray[0].Message
            );
          }
        }

        const response = await fetchItemsFromIds(fileIdsMessages, repository!);
        if (response.data) {
          response.data.forEach(file => {
            duplicateFilesNames = duplicateFilesNames.concat(file.name);
          });
        }

        if (duplicateFilesNames.length > 0) {
          return {
            errorMessage:
              duplicateFilesNames.length === 1
                ? 'ConnectionCreationFailure_SingleFileAlreadyMapped_Toast'
                : 'ConnectionCreationFailure_MultipleFilesAlreadyMapped_Toast',
            errorValues: { error: duplicateFilesNames.join(', ') },
          };
        }

        if (Object.keys(itemsToBeRemoved).length > 0) {
          if (!deleteFiles) {
            for (const fileToRemoveId of Object.keys(itemsToBeRemoved)) {
              if (
                isAffinityEnabledForConnection ||
                shouldShowIndividualConnectorsWithoutAffinity
              ) {
                updatedMastersContext = updatedMastersContext.filter(
                  mc => mc.master.id !== fileToRemoveId
                );
              }
              const jobDefinitionFile = inputFiles.find(
                f => f.file.id === fileToRemoveId
              );

              const deleteInputFileResult = await deleteInputFile(
                job.id,
                jobDefinitionFile!
              );
              if (!deleteInputFileResult.ok) {
                errored = true;
              }
            }
          } else {
            for (const fileToRemoveId of Object.keys(itemsToBeRemoved)) {
              const flagInputFileForUnmapResult = await flagInputFileForUnmap(
                job.id,
                itemsToBeRemoved[fileToRemoveId]
              );
              if (!flagInputFileForUnmapResult.ok) {
                errored = true;
              }
            }

            if (!errored) {
              await runJob(job);
            }
          }
        }

        if (isAffinityEnabledForConnection) {
          const reportResult = await createReport(
            job.id,
            updatedMastersContext
          );

          if (!reportResult.ok) {
            return { errorMessage: 'FailedToUploadAffinity_Message' };
          }
        }

        return errored
          ? { errorMessage: 'FailedToChangeMapping_Message' }
          : { errorMessage: '' };
      }
    },
    [job, jobDefinitionBridge, mastersContext, filesToAdd, itemsToBeRemoved]
  );

  const updateFiles = useCallback(
    async (deleteModels: boolean) => {
      if (changedJob && job && jobDefinitionBridge) {
        clearInputFilesMap();

        if (changedJob.apiVersion !== ApiVersion.v2) {
          if (Object.keys(itemsToBeRemoved).length > 0) {
            if (deleteModels) {
              const removedFiles = changedJob.files.filter(
                f => itemsToBeRemoved[f.file.id] != null
              );
              for (let i = 0; i < removedFiles.length; i++) {
                await deleteFileFromImodel(
                  {
                    ...removedFiles[i],
                    bridgeType: jobDefinitionBridge,
                  },
                  job
                );
              }
            }

            changedJob.files = changedJob.files.filter(
              f => itemsToBeRemoved[f.file.id] == null
            );
          }

          const result = await updateJobDefinition(changedJob);

          if (!result.ok) {
            toastError(t('ChangeMappingPage_ErrorMessage'));
            return;
          }
          onChangeMappingsCallback();
          await fetchJobDefinitions();

          toastSuccess(t('ChangeMappingPage_SuccessMessage'));
        } else {
          const result = (await updateInputFilesV2(deleteModels)) as ErrorData;
          if (result.errorMessage.length === 0) {
            onChangeMappingsCallback();
            await fetchJobDefinitions();
            toastSuccess(t('ChangeMappingPage_SuccessMessage'));
          } else {
            onChangeMappingsCallback();
            toastError(
              t(result.errorMessage, { error: result.errorValues?.error })
            );
          }
        }
      }
    },
    [changedJob, job, jobDefinitionBridge, updateInputFilesV2, itemsToBeRemoved]
  );

  const isConnectionEmptyV1 = useMemo(() => {
    if (changedJob) {
      return changedJob.files.length === Object.keys(itemsToBeRemoved).length;
    }
  }, [changedJob, job]);

  const isConnectionEmptyV2 =
    Object.keys(itemsToBeRemoved).length === inputFiles.length &&
    filesToAdd.length === 0;

  const isSaveButtonDisabled: boolean = useMemo(
    () =>
      job && job.apiVersion !== ApiVersion.v2
        ? (job === changedJob && Object.keys(itemsToBeRemoved).length === 0) ||
          isConnectionEmptyV1 ||
          isJobDefUpdating
        : (Object.keys(itemsToBeRemoved).length === 0 &&
            filesToAdd.length === 0 &&
            !bridgeSelectionChanged) ||
          isConnectionEmptyV2 ||
          isMastersContextLoading ||
          mastersContextError ||
          isInputFileCreating ||
          creatingReports ||
          areInputFilesLoading,
    [
      job,
      changedJob,
      isConnectionEmptyV1,
      isJobDefUpdating,
      itemsToBeRemoved,
      isConnectionEmptyV2,
      isMastersContextLoading,
      mastersContextError,
      isInputFileCreating,
      creatingReports,
      bridgeSelectionChanged,
      filesToAdd,
      areInputFilesLoading,
    ]
  );

  const findUsedFiles = useCallback(() => {
    const usedFileIds: string[] = [];
    jobDefs.forEach(x => {
      if (x.userId !== user?.profile.sub)
        x.files.forEach(y => usedFileIds.push(y.file.id));
    });
    return usedFileIds;
  }, [jobDefs, user]);

  const ConditionalTooltip = withConditionalWrapper(Tooltip);

  if (!job && !changedJob && !areJobsLoading) {
    return (
      <NonIdealState
        svg={<Svg404 />}
        heading={t('FullPageError_ConnectionNotFound_Message')}
      />
    );
  }

  const isTableLoading =
    areInputFilesLoading ||
    isInputFileDeleting ||
    isInputFileCreating ||
    creatingReports ||
    isMastersContextLoading ||
    isDeletedFileLoading;

  return (
    <>
      {isMastersContextLoading ||
      areInputFilesLoading ||
      areJobsLoading ||
      areSpatialRootsLoading ||
      !jobFiles ? (
        <FullPageLoader />
      ) : isAffinityEnabledForConnection ||
        shouldShowIndividualConnectorsWithoutAffinity ? (
        <>
          <div className="change-mappings-toolbar">
            <ButtonGroup>
              <Tooltip content={t('DropDownOption_BatchConnectorSelection')}>
                <div>
                  <IconButton
                    onClick={openBridgeSelectionModal}
                    disabled={!isAnyFileSelected()}
                    data-testid="affinity-bridge-edit-button"
                  >
                    <SvgEdit />
                  </IconButton>
                </div>
              </Tooltip>
              <Tooltip content={t('AddBtn_Label')}>
                <IconButton
                  onClick={openAddCompositeModelModal}
                  disabled={isTableLoading}
                  data-testId="add-files-button"
                >
                  <SvgAdd />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
            <Searchbar debounce onChange={setFilterString} />
          </div>
          <BridgeAssignmentTable
            selectedRepository={repository!}
            spatialRootId={
              spatialRoots.length > 0 &&
              spatialRoots[0]?.jobDefinitionId === job!.id
                ? spatialRoots[0].id
                : ''
            }
            editable={true}
            itemsToBeRemoved={{
              ...itemsToBeRemoved,
            }}
            mastersContext={
              filterString ? filteredMastersContext : mastersContext
            }
            isFilterApplied={isFilterApplied}
            isTreeLoading={isTableLoading}
            setBridgeSelection={(
              mastersConext: MasterContext,
              masterId: string,
              selectedId: string,
              bridgeSelection: BridgeSelection
            ) => {
              setBridgeSelectionChanged(true);
              setBridgeSelection(
                mastersConext,
                masterId,
                selectedId,
                bridgeSelection
              );
            }}
            setSelectedItems={setSelectedItems}
            onRemoveCompositeModelClick={onRemoveCompositeModelAffinity}
            isAffinityUIEnabled={
              isAffinityEnabledForConnection ||
              shouldShowIndividualConnectorsWithoutAffinity
            }
            mastersContextError={mastersContextError}
            inputFiles={inputFiles}
            isV2Job={changedJob?.apiVersion === ApiVersion.v2}
            lastRunDetails={changedJob!.lastRunDetails}
            disableConnectorChanging={
              shouldShowIndividualConnectorsWithoutAffinity &&
              changedJob?.executionStrategy !==
                ExecutionStrategy.ExistingAffinity
            }
          />
        </>
      ) : (
        <>
          <Tooltip content={t('AddBtn_Label')}>
            <IconButton
              onClick={openAddCompositeModelModal}
              data-testId="add-files-button"
            >
              <SvgAdd />
            </IconButton>
          </Tooltip>
          <JobDefinitionFilesTable
            existingSpatial={
              spatialRoots.length > 0 && spatialRoots[0]
                ? spatialRoots[0]
                : undefined
            }
            jobFiles={jobFiles!}
            repository={repository!}
            editable
            itemsToBeRemoved={{
              ...itemsToBeRemoved,
            }}
            isTreeLoading={
              (changedJob?.apiVersion === ApiVersion.v2 &&
                areInputFilesLoading) ||
              isJobDefUpdating
            }
            onRemoveCompositeModelClick={onRemoveCompositeModel}
            inputFiles={inputFiles}
            isV2Job={changedJob?.apiVersion === ApiVersion.v2}
            lastRunDetails={changedJob && changedJob.lastRunDetails}
          />
        </>
      )}

      <div className="save-button-row center-content">
        <ConditionalTooltip
          content={t('ConnectionCannotBeEmpty_Tooltip')}
          isWrapperDisplayed={
            changedJob?.apiVersion !== ApiVersion.v2
              ? !!isConnectionEmptyV1
              : !!isConnectionEmptyV2
          }
        >
          <div>
            <Button
              className="save-button"
              id="save-mappings-button"
              styleType="high-visibility"
              disabled={isSaveButtonDisabled}
              onClick={async () => {
                if (deleteModel && Object.keys(itemsToBeRemoved).length > 0) {
                  openDeleteConfirmModal();
                } else {
                  await updateFiles(false);
                }
              }}
            >
              {t('SaveChangesBtn_Label')}
            </Button>
          </div>
        </ConditionalTooltip>
        <Button id="cancel-mappings-button" onClick={onCancelCallback}>
          {t('CancelBtn_Label')}
        </Button>
      </div>

      <AddCompositeModelModal
        {...addCompositeModelModalProps}
        repository={repository!}
        items={
          jobFiles
            ? jobFiles.spatialMaster
              ? [jobFiles.spatialMaster, ...jobFiles.masters]
              : [...jobFiles.masters]
            : []
        }
        onSave={(items: Item[]) => {
          !isAffinityEnabledForConnection &&
          !shouldShowIndividualConnectorsWithoutAffinity
            ? onAddCompositeModels(items)
            : onAddCompositeModelsAffinity(items);
        }}
        filesUsedByAnotherUser={findUsedFiles()}
        job={job!}
        jobDefinitionBridge={jobDefinitionBridge!}
        allowDifferentConnectorFilesForRepository={
          shouldShowIndividualConnectorsWithoutAffinity
        }
      />

      {isAffinityAvailable && bridgeSelectionModalProps.isOpen && (
        <BridgeSelectionModal
          {...bridgeSelectionModalProps}
          masterContexts={mastersContext}
          selectedItems={selectedItems}
          setBridgeForSelected={setBridgeForSelected}
        />
      )}

      <ConfirmDeleteFilesModal
        {...deleteConfirmModalProps}
        title={t('ConfirmDeleteModal_QuestionOptionsTitle')}
        onConfirm={async (deleteModels: boolean) => {
          await updateFiles(deleteModels);
          closeDeleteConfirmModal();
        }}
        onCancel={() => {
          closeDeleteConfirmModal();
        }}
        type={ConfirmationType.DeleteFiles}
        doesJobHasSyncs={jobStatuses.length > 0}
        noOfItemstoBeRemoved={Object.keys(itemsToBeRemoved).length}
      />
    </>
  );
};

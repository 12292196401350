import { RequestData } from '../useDataApi/requestData';
import { useCallback, useState } from 'react';
import {
  Pw365Connection,
  Pw365ConnectionDTO,
} from '../../components/pw365Connections/pw365Connections';
import { useDataApi } from '../useDataApi/useDataApi';
import { useTranslation } from 'react-i18next';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { useIsConnectionEnabled } from './useIsConnectionEnabled';

export const useFetchPW365Connection = (
  projectId: string
): [Pw365Connection[], boolean, any, () => Promise<Pw365Connection[]>] => {
  const { buddiUrls } = useBuddiContext();
  const [isConnectionEnabled] = useIsConnectionEnabled();
  const useDataSettings = { initialData: [], initialIsLoading: false };
  const [isLoading, setIsLoading] = useState(false);
  const [, , error, fetchData] = useDataApi<Pw365Connection[]>(useDataSettings);
  const { t } = useTranslation();
  const [connections, setConnections] = useState<Pw365Connection[]>([]);

  const getPW365ConnectionData = useCallback(async () => {
    const requestData: RequestData<Pw365Connection[]> = {
      method: 'GET',
      url: `${buddiUrls.projectWise365Url}/api/v1/context/${projectId}/connections`,
      map: responseToPWConnectionDto,
    };
    setIsLoading(true);
    const result = await fetchData(requestData);
    let connections: Pw365Connection[] = result.data == null ? [] : result.data;
    if (connections.length > 0) {
      connections = connections.filter(
        c => !c.canned || (c.canned && !(c.disabled === true))
      );
      for (const connection of connections)
        if (connection.canned !== true) {
          const isEnabled = await isConnectionEnabled(projectId, connection.id);
          connection.disabled = isEnabled.data === 'false';
        }
    }

    setIsLoading(false);
    setConnections(connections);
    return connections;
  }, [fetchData]);

  const responseToPWConnectionDto = (
    pwFiles: Pw365ConnectionDTO[]
  ): Pw365Connection[] => {
    const response = pwFiles.map(x => {
      return {
        name: x.name,
        canned: x.canned,
        id: x.id,
        url: x.url,
        disabled: x.disabled != null ? x.disabled : false,
      };
    });
    response.forEach(x =>
      x.canned ? (x.name = t('PW365Connection_Documents')) : x.name
    );
    return response;
  };

  return [connections, isLoading, error, getPW365ConnectionData];
};

import React from 'react';
import { Text, ToggleSwitch } from '@itwin/itwinui-react';

interface LabeledToggleProps {
  title: string;
  explanation: string;
  onChange: () => void;
  isOn: boolean;
}

export const LabeledToggle = (props: LabeledToggleProps) => {
  const { title, explanation, onChange, isOn } = props;
  return (
    <>
      <div className="toggle-affinity-ui">
        <Text variant="subheading" as="h3">
          {title}
        </Text>
        <ToggleSwitch checked={isOn} onChange={onChange} />
      </div>
      <Text variant="small" as="small" isMuted className="toggle-description">
        {explanation}
      </Text>
    </>
  );
};

import React, { useState } from 'react';
import './createConnectionWizard.scss';
import { Item } from '../../entities/item';
import { Repository, RepositoryType } from '../../entities/repository';
import { useCreateJob } from '../../hooks/useCreateJob/useCreateJob';
import { useToast } from '../../context/toastContext/toastContext';
import { JobFiles } from '../../entities/jobDefinition/jobDefinition';
import { useCreateJobSchedule } from '../../hooks/useCreateJobSchedule/useCreateJobSchedule';
import { useTranslation } from 'react-i18next';
import { useTrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import { Features } from '../../context/usageLoggerContext/featureConstants';
import {
  useBridgeForFile,
  getJobTokenType,
} from '../../services/bridgeLogic/bridgeLogic';
import { FileSelectionStep } from '../createConnectionWizardComponents/fileSelectionStep/fileSelectionStep';
import { FullPageLoader } from '../fullPageLoader/fullPageLoader';
import { useSetSpatialRoot } from '../../hooks/useSetSpatialRoot/useSetSpatialRoot';
import { SpatialRoot } from '../../entities/spatialRootSetting';
import { ConnectionConfiguration } from '../createConnectionWizardComponents/createConnectionContent/createConnectionContent';
import { DataSourceSelectionStep } from '../createConnectionWizardComponents/datasourceSelectionStep/dataSourceSelectionStep';
import { ExistingSpatialStep } from '../createConnectionWizardComponents/ExistingSpatialStep/existingSpatialStep';
import { CreateConnectionStep } from '../createConnectionWizardComponents/connectionConfigurationStep/createConnectionStep';
import { DefineBridgeStep } from '../createConnectionWizardComponents/defineBridgeStep/defineBridgeStep';
import { BridgeType } from '../../entities/bridge';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import { useCreateJobV2 } from '../../hooks/useCreateJob/useCreateJobV2';
import { BridgeArg } from '../../entities/jobDefinition/jobDefintionDTO';
import {
  MasterContext,
  useMastersContext,
} from '../../hooks/useGetMasterReferencesTree/useGetMasterReferencesTree';
import { useCreateAffinityReport } from '../../hooks/useCreateAffinityReport/useCreateAffinityReport';
import { SpatialRootSelectionStep } from '../createConnectionWizardComponents/spatialRootSelectionStep/spatialRootSelectionStep';
import { ExecutionStrategy } from '../../entities/jobDefinition/jobDefinitionDTOv2';
import { Text, Stepper } from '@itwin/itwinui-react';
import { WizardStep } from '../wizard/wizard';
import { useOrchestratorAuthenticateUser } from '../../hooks/useOrchestratorAuthenticateUser/useOrchestratorAuthenticateUser';
import { useBentleyContext } from '../../context/bentleyContext/bentleyContext';
import { CreateConnectionWizardProps } from '../../typedef/index';
import { useConnectionDefintionsContext } from '../../context/connectionDefinitionsContext/connectionsDefintionsContext';
import { useSpatialRootsContext } from '../../context/spatialRootsContext/spatialRootsContext';
import { useUserContext } from '../../context/userContext/userContext';

export enum WizardStepId {
  ChooseDatasourceStep = 'WizardChooseDataSourceStep',
  SelectSpatialStep = 'WizardSelectSpatialStep',
  SelectCompositesStep = 'WizardSelectCompositesStep',
  DefineBridgeStep = 'WizardDefineConnectorStep',
  CreateConnectionStep = 'WizardCreateConnectionStep',
}

export const identifyAlreadyMappedFiles = (
  jobFiles: JobFiles,
  fileIds: string
): string[] => {
  const duplicateFileIds = Array.from(fileIds.split(','));

  return duplicateFileIds
    .map(duplicateFileId => {
      if (
        jobFiles.spatialMaster &&
        jobFiles.spatialMaster.id === duplicateFileId
      ) {
        return jobFiles.spatialMaster.name;
      }

      const duplicateFileName = jobFiles.masters.find(
        file => file.id === duplicateFileId
      )?.name;
      return duplicateFileName ? duplicateFileName : '';
    })
    .filter(fileName => fileName.length !== 0);
};

export const CreateConnectionWizard = (props: CreateConnectionWizardProps) => {
  useTrackFeature(Features.ViewCreateJobPage);

  const {
    orchAuthRedirectUrl,
    predefinedRepositoryType,
    onConnectionCreatedCallback,
    onCancelCallback,
  } = props;

  const { projectId, iModelId } = useBentleyContext();
  const { user } = useUserContext();
  const { defineBridgeStepEnabled, createV2Connection } =
    useFeatureToggleContext();

  const { toastError, toastSuccess } = useToast();
  const { t } = useTranslation();

  const {
    connectionsDefintions: jobs,
    areConnectionDefintionsLoading: areJobsLoading,
  } = useConnectionDefintionsContext();

  const { spatialRoots } = useSpatialRootsContext();

  const [repository, setRepository] = useState<Repository>({} as Repository);
  const [isJobBeingCreated, setIsJobBeingCreated] = useState(false);
  const [spatialModel, setSpatial] = useState<Item | null>(null);
  const [masters, setMasters] = useState<Item[]>([]);
  const [bridgeArgs, setBridgeArgs] = useState<BridgeArg>({});

  const [
    mastersContext,
    isMastersContextLoading,
    mastersContextError,
    selectedItems,
    filterMasterContexts,
    setBridgeSelection,
    setSelectedItems,
    setBridgeForSelected,
    ,
    ,
    fetchMastersContext,
    isAnyFileSelected,
    setMastersContext
  ] = useMastersContext(repository);

  const { isItemValidMasterModel, isItemValidSpatialModel } =
    useBridgeForFile();
  const [, , , setSpatialRoot] = useSetSpatialRoot(projectId);
  const [, , , createOrUpdateJob] = useCreateJob(projectId, iModelId);
  const [, , , createJobSchedule] = useCreateJobSchedule(projectId, iModelId);
  const [, , , createConnectionDefintionV2] = useCreateJobV2(
    projectId,
    iModelId
  );
  const [, createReport] = useCreateAffinityReport();
  const [, , , authenticateUser] = useOrchestratorAuthenticateUser();

  const isPageLoading = areJobsLoading || isJobBeingCreated;

  const shouldShowIndividualConnectorsWithoutAffinity =
    (repository.type === RepositoryType.PROJECTSHARE ||
      repository.type === RepositoryType.PWDI ||
      repository.type === RepositoryType.PWDI_LEGACY ||
      repository.type === RepositoryType.PW365) &&
    createV2Connection;

  const findUsedFiles = () => {
    const usedFileIds: string[] = [];
    jobs.forEach(x => {
      if (x.userId !== user?.profile.sub)
        x.files.forEach(y => usedFileIds.push(y.file.id));
    });
    return usedFileIds;
  };

  const getInitialSteps = () => {
    const wizardSteps = [
      {
        active: true,
        id: WizardStepId.ChooseDatasourceStep,
        label: t(WizardStepId.ChooseDatasourceStep),
        toolTip: t(`${WizardStepId.ChooseDatasourceStep}_ToolTip`),
      },
      {
        active: false,
        id: WizardStepId.SelectCompositesStep,
        label: t(WizardStepId.SelectCompositesStep),
        toolTip: t(`${WizardStepId.SelectCompositesStep}_ToolTip`),
      },
      {
        active: false,
        id: WizardStepId.SelectSpatialStep,
        label: t(WizardStepId.SelectSpatialStep),
        toolTip: t(`${WizardStepId.SelectSpatialStep}_ToolTip`),
      },
    ];
    if (defineBridgeStepEnabled) {
      wizardSteps.push({
        active: false,
        id: WizardStepId.DefineBridgeStep,
        label: t(WizardStepId.DefineBridgeStep),
        toolTip: t(`${WizardStepId.DefineBridgeStep}_ToolTip`),
      });
    }
    wizardSteps.push({
      active: false,
      id: WizardStepId.CreateConnectionStep,
      label: t(WizardStepId.CreateConnectionStep),
      toolTip: t(`${WizardStepId.CreateConnectionStep}_ToolTip`),
    });
    return wizardSteps;
  };

  const [steps, setSteps] = useState<WizardStep[]>(getInitialSteps());

  const onSpatialSelect = (selectedItems: Item[]) => {
    if (selectedItems.length > 0) {
      setStepActive(
        defineBridgeStepEnabled
          ? WizardStepId.DefineBridgeStep
          : WizardStepId.CreateConnectionStep
      );
      setSpatial({ ...selectedItems[0] });
    } else {
      setStepActive(
        defineBridgeStepEnabled
          ? WizardStepId.DefineBridgeStep
          : WizardStepId.CreateConnectionStep
      );
    }
  };

  const isItemSelectableAsSpatialModel = (item: Item) =>
    isItemValidSpatialModel(item) && !isItemSelectedAsCompositeModel(item);

  const isItemSelectedAsCompositeModel = (item: Item): boolean => {
    const isItemCompositeModel = masters.some(x => x.id === item.id);
    return isItemCompositeModel;
  };

  const isItemSelectableAsMasterModel = (item: Item) =>
    isItemValidMasterModel(item);

  const setStepActive = (id: WizardStepId) => {
    setSteps(steps.map(step => ({ ...step, active: step.id === id })));
  };
  const [bridge, setBridge] = useState<BridgeType>();

  const jobFiles = {
    spatialMaster: spatialModel,
    masters: masters.filter(m => m.id !== spatialModel?.id),
    sheets: [],
  } as JobFiles;

  const addBridgeArgs = (newArgs: any) => {
    setBridgeArgs({ ...bridgeArgs, ...newArgs });
  };

  const createAndOptionallyScheduleJob = async (
    configuration: ConnectionConfiguration
  ) => {
    setIsJobBeingCreated(true);
    if (createV2Connection) {
      const syncDefinition = await createConnectionDefintionV2(
        configuration.jobName,
        jobFiles,
        defineBridgeStepEnabled ? (bridge as BridgeType) : configuration.bridge,
        configuration.schedule,
        configuration.repository,
        bridgeArgs,
        mastersContext ? mastersContext : null,
        executionStrategy,
        configuration.nextSchedule
      );
      const connectionCreateError = JSON.parse(syncDefinition.error);

      if (
        connectionCreateError?.error?.Message?.includes(
          'already mapped to the iModel'
        ) &&
        connectionCreateError?.error?.Details?.length === 1
      ) {
        let errorDetailsArray: any;

        //get duplicate fileIds from error details
        errorDetailsArray = Array.from(connectionCreateError?.error?.Details);
        const fileIdsMessage = errorDetailsArray[0]?.Message;
        const duplicateFiles = identifyAlreadyMappedFiles(
          jobFiles,
          fileIdsMessage
        );
        toastError(
          t(
            duplicateFiles.length === 1
              ? 'ConnectionCreationFailure_SingleFileAlreadyMapped_Toast'
              : 'ConnectionCreationFailure_MultipleFilesAlreadyMapped_Toast',
            {
              error: duplicateFiles.join(', '),
            }
          )
        );

        setIsJobBeingCreated(false);
        return;
      }
      if (
        mastersContext != null &&
        executionStrategy === ExecutionStrategy.ExistingAffinity
      ) {
        const result = await createReport(
          syncDefinition.data?.id as string,
          mastersContext
        );
        if (!result.ok) {
          toastError(t('FailedToUploadAffinity_Message'));
          return;
        }
      }

      if (spatialModel) {
        await setSpatialRoot(
          iModelId,
          spatialModel,
          syncDefinition.data?.id as string
        );
      }

      if (syncDefinition.data && syncDefinition.data.isScheduled) {
        const jobTokenType = getJobTokenType(configuration.repository.type);
        await authenticateUser(jobTokenType, orchAuthRedirectUrl);
      }
    } else {
      const jobResult = await createOrUpdateJob(
        configuration.jobName,
        jobFiles,
        defineBridgeStepEnabled ? (bridge as BridgeType) : configuration.bridge,
        configuration.schedule,
        configuration.repository,
        bridgeArgs
      );
      if (!jobResult.ok) {
        toastError(t('ConnectionCreationFailure_Toast'));
        setIsJobBeingCreated(false);
        return;
      }
      if (spatialModel) {
        await setSpatialRoot(iModelId, spatialModel, jobResult.data!.id);
      }

      if (configuration.schedule) {
        const jobDefinitionId = jobResult.data!.id;
        const jobScheduleResult = await createJobSchedule(
          jobDefinitionId,
          configuration.schedule
        );

        if (!jobScheduleResult.ok) {
          toastError(t('JobScheduleFailure_Toast'));
          setIsJobBeingCreated(false);
        } else {
          const jobTokenType = getJobTokenType(configuration.repository.type);
          await authenticateUser(jobTokenType, orchAuthRedirectUrl);
        }
      }
    }

    toastSuccess(t('ConnectionCreationSuccess_Toast'));
    await onConnectionCreatedCallback();
    setIsJobBeingCreated(false);
  };

  const [executionStrategy, setExecutionStrategy] = useState<ExecutionStrategy>(
    ExecutionStrategy.NonAffinity
  );

  const stringToRepositoryType = (
    str: string | undefined
  ): RepositoryType | undefined => {
    return RepositoryType[str as keyof typeof RepositoryType];
  };

  const getActivePage = () => {
    if (steps[0].active) {
      return (
        <DataSourceSelectionStep
          key={'datasource-selection-step'}
          primaryButtonHandle={(repository: Repository) => {
            setRepository(repository);
            setStepActive(WizardStepId.SelectCompositesStep);
          }}
          primaryButtonLabel={t('NextButton_Label')}
          primaryDisabled={false}
          cancelHandle={() => {
            setMastersContext([]);
            onCancelCallback();
          }}
          projectId={projectId}
          executionStrategy={executionStrategy}
          setExecutionStrategy={setExecutionStrategy}
          predefinedRepositoryType={stringToRepositoryType(
            predefinedRepositoryType
          )}
        />
      );
    } else if (steps[1].active) {
      return (
        <FileSelectionStep
          key={'file-selection1-step'}
          id={'select-composite-models'}
          repository={repository}
          isItemSelectable={isItemSelectableAsMasterModel}
          primaryDisabled={false}
          primaryButtonHandle={(selectedItems: Item[]) => {
            setMasters(selectedItems);
            setStepActive(WizardStepId.SelectSpatialStep);
          }}
          primaryButtonLabel={t('NextButton_Label')}
          backHandle={() => {
            setMastersContext([]);
            setMasters([]);
            setStepActive(WizardStepId.ChooseDatasourceStep);
          }}
          cancelHandle={() => {
            setMastersContext([]);
            onCancelCallback();
          }}
          preSelectedItems={masters.length > 0 ? masters : []}
          filesUsedByAnotherUser={findUsedFiles()}
          isAutoAffinityEnabled={
            executionStrategy === ExecutionStrategy.Affinity
          }
          selectedSpatialRoot={spatialModel}
          removeSpatialRoot={() => setSpatial(null)}
        />
      );
    } else if (steps[2].active) {
      return spatialRoots && spatialRoots.length > 0 ? (
        <ExistingSpatialStep
          key={'existing-spatial-step'}
          existingSpatialRoot={spatialRoots[0] as SpatialRoot}
          primaryButtonHandle={() => {
            setStepActive(
              defineBridgeStepEnabled
                ? WizardStepId.DefineBridgeStep
                : WizardStepId.CreateConnectionStep
            );
          }}
          primaryButtonLabel={t('NextButton_Label')}
          primaryDisabled={false}
          backHandle={() => {
            setStepActive(WizardStepId.SelectCompositesStep);
          }}
          cancelHandle={() => {
            setMastersContext([]);
            onCancelCallback();
          }}
        />
      ) : (
        <SpatialRootSelectionStep
          repository={repository}
          key={'file-selection2-step'}
          id={'select-spatial-root'}
          selectedSpatialRoot={spatialModel}
          selectedMasterFiles={masters}
          primaryDisabled={false}
          primaryButtonHandle={onSpatialSelect}
          isItemSelectable={isItemSelectableAsSpatialModel}
          primaryButtonLabel={t('NextButton_Label')}
          backHandle={(selectedItem: Item[]) => {
            setSpatial(selectedItem.length > 0 ? { ...selectedItem[0] } : null);
            setStepActive(WizardStepId.SelectCompositesStep);
          }}
          cancelHandle={() => {
            setMastersContext([]);
            onCancelCallback();
          }}
          skipHandle={() => {
            if (spatialModel) {
              setSpatial(null);
            }
            setStepActive(
              defineBridgeStepEnabled
                ? WizardStepId.DefineBridgeStep
                : WizardStepId.CreateConnectionStep
            );
          }}
          skipTooltip={t('SkipSpatialSelection_Tooltip')!}
        />
      );
    } else if (defineBridgeStepEnabled && steps[3].active) {
      return (
        <DefineBridgeStep
          key={'define-bridge-step'}
          repository={repository}
          jobFiles={jobFiles}
          existingSpatial={
            spatialRoots && spatialRoots.length > 0
              ? spatialRoots[0]
              : undefined
          }
          mastersContext={mastersContext}
          isMastersContextLoading={isMastersContextLoading}
          mastersContextError={mastersContextError}
          selectedItems={selectedItems}
          filterMasterContexts={filterMasterContexts}
          setBridgeSelection={setBridgeSelection}
          setSelectedItems={setSelectedItems}
          setBridgeForSelected={setBridgeForSelected}
          fetchMastersContext={fetchMastersContext}
          isAnyFileSelected={isAnyFileSelected}
          primaryButtonHandle={(
            bridge: BridgeType,
            mastersContext: MasterContext[]
          ) => {
            setMastersContext(
              executionStrategy === ExecutionStrategy.ExistingAffinity ||
                shouldShowIndividualConnectorsWithoutAffinity
                ? mastersContext
                : []
            );
            setBridge(bridge);
            setStepActive(WizardStepId.CreateConnectionStep);
          }}
          primaryButtonLabel={t('NextButton_Label')}
          primaryDisabled={true}
          setBridgeArgs={addBridgeArgs}
          backHandle={() => {
            setStepActive(WizardStepId.SelectSpatialStep);
          }}
          cancelHandle={() => {
            setMastersContext([]);
            onCancelCallback();
          }}
          executionStrategy={executionStrategy}
          shouldShowIndividualConnectorsWithoutAffinity={
            shouldShowIndividualConnectorsWithoutAffinity
          }
        />
      );
    } else if (defineBridgeStepEnabled ? steps[4].active : steps[3].active) {
      return (
        <CreateConnectionStep
          key={'create-connection-step'}
          bridge={bridge}
          jobs={jobs}
          repository={repository}
          masterContext={mastersContext ? mastersContext : null}
          jobFiles={jobFiles}
          isJobBeingCreated={isJobBeingCreated}
          primaryButtonHandle={createAndOptionallyScheduleJob}
          primaryButtonLabel={t('CreateBtn_Label')}
          primaryDisabled={true}
          backHandle={() => {
            setStepActive(
              defineBridgeStepEnabled
                ? WizardStepId.DefineBridgeStep
                : WizardStepId.SelectCompositesStep
            );
          }}
          cancelHandle={() => {
            setMastersContext([]);
            onCancelCallback();
          }}
          isAffinityUIEnabled={
            executionStrategy === ExecutionStrategy.ExistingAffinity
          }
        />
      );
    }
  };

  return (
    <>
      {isPageLoading ? (
        <FullPageLoader />
      ) : (
        <div className="create-connection-page">
          <Text variant="title" as="h2">
            {t('CreateConnection_Title')}
          </Text>
          <div className="create-connection-page-wizard-container">
            <Stepper
              currentStep={steps.findIndex(step => step.active)}
              steps={steps.map(step => ({
                name: step.label,
                description: step.toolTip,
              }))}
            />
          </div>
          {getActivePage()}
        </div>
      )}
    </>
  );
};

import { EcInstances } from '../EcSchema/ecInstances';

export interface RBACPermissionDto {
  relationshipInstances: PermissionDto[];
}

export interface PermissionDto {
  relatedInstance: {
    instanceId: String;
    properties: {
      Name: string;
    };
  };
}

export interface IModelRolesRelatedInstanceDTO {
  instanceId: string;
}

export interface IModelRolesRelationshipInstancesDTO {
  relatedInstance: IModelRolesRelatedInstanceDTO;
}

export interface IModelRolesDTO {
  instanceId: string;
  relationshipInstances: IModelRolesRelationshipInstancesDTO[];
}

export interface IModelPermissionRelatedInstancePropertiesDto {
  Name: string;
}

export interface IModelPermissionRelatedInstanceDto {
  instanceId: string;
  properties: IModelPermissionRelatedInstancePropertiesDto;
}

export interface IModelPermissionDto {
  properties: IModelPermissionsPropertiesDto;
  relatedInstance: IModelPermissionRelatedInstanceDto;
}

export interface IModelPermissionsPropertiesDto {
  ObjectRoleId: string;
}

export interface IModelPermissionsDto {
  objectRoleId: string;
  relatedInstanceId: string;
  relatedInstanceName: string;
}

export interface IModelRolesRelatedInstancePropertiesDto {
  Description: string;
  Name: string;
  Type: string;
}

export interface IModelRolesRelatedInstanceDto {
  instanceId: string;
  properties: IModelRolesRelatedInstancePropertiesDto;
}

export interface IModelRolesRelationshipDto {
  instanceId: string;
  relatedInstance: IModelRolesRelatedInstanceDto;
}

export interface IModelRolesDto {
  instanceId: string;
  relationshipInstances: [IModelRolesRelationshipDto];
}

export interface IModelRoles {
  instanceId: string;
  relationshipInstances: [string];
}

export interface UserRoles {
  instanceId: string;
  name: string;
}

export interface IModelProjectLevelPermissions {
  canReadProjectLevelPermission: boolean;
  canModifyProjectLevelPermission: boolean;
  canDeleteProjectLevelPermission: boolean;
  canManageProjectLevelPermission: boolean;
}

export interface IModelLevelPermissions {
  canReadIModelLevelPermission: boolean;
  canModifyIModelLevelPermission: boolean;
  canDeleteIModelLevelPermission: boolean;
  canManageIModelLevelPermission: boolean;
}

export interface IModelPermissions {
  canModifyiModel: boolean;
  canManageiModel: boolean;
}

export const repsonseToRBACPermissionsMap = (
  response: EcInstances<RBACPermissionDto>
): IModelProjectLevelPermissions => {
  const permissions = response.instances[0].relationshipInstances.map(
    instance => {
      return instance.relatedInstance.instanceId;
    }
  );
  const p = {
    canReadProjectLevelPermission: permissions.includes('IMHS_Read_iModel'),
    canModifyProjectLevelPermission: permissions.includes('IMHS_Modify_iModel'),
    canDeleteProjectLevelPermission: permissions.includes('IMHS_Delete_iModel'),
    canManageProjectLevelPermission:
      permissions.includes('IMHS_ManageResources') ||
      permissions.includes('IMHS_Manage_iModel'),
  };
  return {
    canReadProjectLevelPermission:
      p.canReadProjectLevelPermission ||
      p.canModifyProjectLevelPermission ||
      p.canDeleteProjectLevelPermission ||
      p.canManageProjectLevelPermission,
    canModifyProjectLevelPermission:
      p.canModifyProjectLevelPermission || p.canManageProjectLevelPermission,
    canDeleteProjectLevelPermission: p.canDeleteProjectLevelPermission,
    canManageProjectLevelPermission: p.canManageProjectLevelPermission,
  };
};

import { Item } from '../../entities/item';
import {
  RepositoryType,
  Repository,
  TokenType,
} from '../../entities/repository';
import {
  BridgeType,
  BridgeRepositoryType,
  getBridgeTypeDisplayName,
} from '../../entities/bridge';
import first from 'lodash/first';
import last from 'lodash/last';
import { JobFiles } from '../../entities/jobDefinition/jobDefinition';
import { assertUnreachable } from '../uiUtils/assertUnreachable';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import {
  ProjectShareLocationV2Dto,
  RepositoryTypeV2,
} from '../../entities/jobDefinition/jobDefinitionDTOv2';

export enum ModelFileExtension {
  Csv = 'csv',
  Dgn = 'dgn',
  Dwg = 'dwg',
  Dxf = 'dxf',
  Fbx = 'fbx',
  GeoDb = 'geodb',
  GeoJson = 'geojson',
  Idgn = 'i.dgn',
  Ifc = 'ifc',
  Json = 'json',
  Kml = 'kml',
  Nwc = 'nwc',
  Nwd = 'nwd',
  Obj = 'obj',
  OtXml = 'otxml',
  Pid = 'pid',
  Revit = 'rvt',
  Shp = 'shp',
  shpXml = 'shp.xml',
  Skp = 'skp',
  ThreeDm = '3dm',
  ThreeDs = '3ds',
  Vsd = 'vsd',
  Vue = 'vue',
  Xls = 'xls',
  Xlsx = 'xlsx',
  Xml = 'xml',
  Zip = 'zip',
  Kmz = 'kmz',
}

export enum SelectDataAdapter {
  Bwc,
  iTwinUI,
}

const modelExtensionToBridgeTypesMap = new Map<
  ModelFileExtension,
  BridgeType[]
>([
  [ModelFileExtension.Csv, [BridgeType.PSEXCEL]],
  [
    ModelFileExtension.Dgn,
    [
      BridgeType.Microstation,
      BridgeType.Civil,
      BridgeType.OBD,
      BridgeType.Prostructures,
    ],
  ],
  [
    ModelFileExtension.Dwg,
    [BridgeType.Dwg, BridgeType.Civil3D, BridgeType.AutoPlant],
  ],
  [ModelFileExtension.Dxf, [BridgeType.Dwg]],
  [ModelFileExtension.Fbx, [BridgeType.Microstation]],
  [ModelFileExtension.GeoDb, [BridgeType.Geospatial]],
  [ModelFileExtension.GeoJson, [BridgeType.Geospatial]],
  [
    ModelFileExtension.Idgn,
    [BridgeType.Microstation, BridgeType.Prostructures],
  ],
  [ModelFileExtension.Ifc, [BridgeType.Ifc]],
  [ModelFileExtension.Json, [BridgeType.IntelliPid]],
  [ModelFileExtension.Kml, [BridgeType.Geospatial]],
  [ModelFileExtension.Kmz, [BridgeType.Geospatial]],
  [ModelFileExtension.Nwc, [BridgeType.Nwd]],
  [ModelFileExtension.Nwd, [BridgeType.Nwd]],
  [ModelFileExtension.Obj, [BridgeType.Microstation]],
  [ModelFileExtension.OtXml, [BridgeType.OpenTower, BridgeType.OpenTowerLite]],
  [ModelFileExtension.Pid, [BridgeType.SPPID]],
  [ModelFileExtension.Revit, [BridgeType.Revit]],
  [ModelFileExtension.Shp, [BridgeType.Geospatial]],
  [ModelFileExtension.Skp, [BridgeType.Microstation]],
  [ModelFileExtension.ThreeDm, [BridgeType.Microstation]],
  [ModelFileExtension.ThreeDs, [BridgeType.Microstation]],
  [ModelFileExtension.Vue, [BridgeType.Vue]],
  [ModelFileExtension.Xls, [BridgeType.PSEXCEL]],
  [ModelFileExtension.Xlsx, [BridgeType.PSEXCEL]],
  [ModelFileExtension.Xml, [BridgeType.OpenTower]],
  [ModelFileExtension.Zip, [BridgeType.SPPID]],
]);

export const useBridgeForFile = (): {
  getAllowedBridgeTypesForFile: (
    fileName: string,
    repository: Repository
  ) => BridgeType[];
  isItemValidMasterModel: (item: Item) => boolean;
  isItemValidSpatialModel: (item: Item) => boolean;
  getBridgeSelectOptions: (
    name: string,
    repository: Repository,
    adapter?: SelectDataAdapter
  ) => any;
} => {
  const {
    allowObdShare,
    allowProstructuresShare,
    prostructuresConnector,
    intelliPidConnector,
    enableOTLiteConnector,
    enablekmzFileSupport,
  } = useFeatureToggleContext();

  const getAllowedBridgeTypesForFile = (
    fileName: string,
    repository: Repository
  ): BridgeType[] => {
    const masterFileExtension = getFileExtension(
      fileName
    ) as ModelFileExtension;
    let bridgeTypes = modelExtensionToBridgeTypesMap.get(masterFileExtension);
    if (
      repository.type === RepositoryType.PROJECTSHARE &&
      !allowObdShare &&
      bridgeTypes
    ) {
      bridgeTypes = bridgeTypes.filter(bridge => bridge !== BridgeType.OBD);
    }
    if (
      repository.type === RepositoryType.PROJECTSHARE &&
      !allowProstructuresShare &&
      bridgeTypes
    ) {
      bridgeTypes = bridgeTypes.filter(
        bridge => bridge !== BridgeType.Prostructures
      );
    }
    if (bridgeTypes && !prostructuresConnector) {
      bridgeTypes = bridgeTypes.filter(
        bridge => bridge !== BridgeType.Prostructures
      );
    }
    if (bridgeTypes && !intelliPidConnector) {
      bridgeTypes = bridgeTypes.filter(
        bridge => bridge !== BridgeType.IntelliPid
      );
    }
    if (bridgeTypes && !enableOTLiteConnector) {
      bridgeTypes = bridgeTypes.filter(
        bridge => bridge !== BridgeType.OpenTowerLite
      );
    }
    return bridgeTypes ? bridgeTypes : [];
  };

  const isValidBridgeMasterFileExtension = (extension: string): boolean => {
    const validExtensions: string[] = [
      ModelFileExtension.Csv,
      ModelFileExtension.Dgn,
      ModelFileExtension.Dwg,
      ModelFileExtension.Dxf,
      ModelFileExtension.Fbx,
      ModelFileExtension.GeoDb,
      ModelFileExtension.GeoJson,
      ModelFileExtension.Idgn,
      ModelFileExtension.Ifc,
      ModelFileExtension.Kml,
      ModelFileExtension.Nwc,
      ModelFileExtension.Nwd,
      ModelFileExtension.Obj,
      ModelFileExtension.OtXml,
      ModelFileExtension.Pid,
      ModelFileExtension.Revit,
      ModelFileExtension.Shp,
      ModelFileExtension.Skp,
      ModelFileExtension.ThreeDm,
      ModelFileExtension.ThreeDs,
      ModelFileExtension.Vue,
      ModelFileExtension.Xml,
      ModelFileExtension.Zip,
      ModelFileExtension.Kmz,
      ...[ModelFileExtension.Xls, ModelFileExtension.Xlsx],
      ...(intelliPidConnector ? [ModelFileExtension.Json] : []),
    ];

    if (extension === 'kmz' && !enablekmzFileSupport) {
      return false;
    }
    return validExtensions.includes(extension);
  };

  const getBridgeSelectOptions = (
    name: string,
    repository: Repository,
    adapter = SelectDataAdapter.Bwc
  ) => {
    switch (adapter) {
      case SelectDataAdapter.Bwc:
        return getAllowedBridgeTypesForFile(name, repository).reduce(
          (obj, x) => ({ ...obj, [x]: getBridgeTypeDisplayName(x) }),
          {}
        );
      case SelectDataAdapter.iTwinUI:
        return getAllowedBridgeTypesForFile(name, repository).map(
          bridgeType => ({
            value: bridgeType,
            label: getBridgeTypeDisplayName(bridgeType),
          })
        );
      default:
        throw assertUnreachable(adapter);
    }
  };

  const isItemValidMasterModel = (item: Item): boolean =>
    !item.isFolder &&
    isValidBridgeMasterFileExtension(getFileExtension(item.name));
  const isItemValidSpatialModel = (item: Item): boolean =>
    !item.isFolder &&
    isValidBridgeMasterFileExtension(getFileExtension(item.name));

  return {
    getAllowedBridgeTypesForFile,
    isItemValidMasterModel,
    isItemValidSpatialModel,
    getBridgeSelectOptions,
  };
};

export const getFileExtension = (name: string): string => {
  const normalizedName = name.toLowerCase();
  const nameParts: string[] = normalizedName.split('.');

  if (nameParts.length < 2) {
    return '';
  }

  if (normalizedName.endsWith('.i.dgn')) {
    return ModelFileExtension.Idgn;
  }

  if (normalizedName.endsWith('.shp.xml')) {
    return ModelFileExtension.shpXml;
  }
  return last(nameParts)!;
};

export const getBridgeRepositoryType = (repositoryType: RepositoryType) => {
  if (repositoryType === RepositoryType.PROJECTSHARE) {
    return BridgeRepositoryType.ShareFolder;
  } else if (repositoryType === RepositoryType.PWDI) {
    return BridgeRepositoryType.ProjectWise;
  } else if (repositoryType === RepositoryType.PWDI_LEGACY) {
    return BridgeRepositoryType.ProjectWiseLegacy;
  } else if (repositoryType === RepositoryType.PW365) {
    return BridgeRepositoryType.PW365;
  } else {
    throw new Error('Not supported Bridge Repository Type');
  }
};
export const getRepositoryTypeV2 = (repositoryType: RepositoryType) => {
  if (repositoryType === RepositoryType.PROJECTSHARE) {
    return RepositoryTypeV2.ProjectShare;
  } else if (
    repositoryType === RepositoryType.PWDI ||
    repositoryType === RepositoryType.PW365
  ) {
    return RepositoryTypeV2.ProjectWiseWsg;
  } else if (repositoryType === RepositoryType.PWDI_LEGACY) {
    return RepositoryTypeV2.ProjectWiseLegacy;
  } else {
    throw new Error('Not supported Bridge Repository Type');
  }
};

export const getRepositoryUrl = (
  repository: Repository,
  jobFiles: JobFiles,
  bridgeRepositoryType: BridgeRepositoryType
) => {
  const masterFile = jobFiles.spatialMaster || first(jobFiles.masters)!;
  if (bridgeRepositoryType === BridgeRepositoryType.ShareFolder) {
    const projectId = repository.id;
    return `${projectId}/${masterFile.parentId}/${masterFile.id}`;
  } else if (
    bridgeRepositoryType === BridgeRepositoryType.ProjectWise ||
    bridgeRepositoryType === BridgeRepositoryType.ProjectWiseLegacy
  ) {
    const parts = repository.location.split('/');
    const dataSource = parts[6]
      .split('--')[1]
      .replace(/~3A/g, ':')
      .replace(/~20/g, ' ');
    const moniker = `pw:\\\\${dataSource}\\Documents\\D{${masterFile.id}}`;
    return moniker;
  }
  throw new Error(
    `Repository URL not supported for bridge repository type ${bridgeRepositoryType}.`
  );
};

export const getInputFileLocation = (
  repository: Repository,
  repositoryType: RepositoryTypeV2
) => {
  switch (repositoryType) {
    case RepositoryTypeV2.ProjectShare: {
      const projectId = repository.id;
      return {
        projectShareLocation: { projectId } as ProjectShareLocationV2Dto,
      };
    }
    case RepositoryTypeV2.ProjectWiseWsg:
    case RepositoryTypeV2.PW365:
    case RepositoryTypeV2.ProjectWiseLegacy: {
      const parts = repository.location.split('/');
      const dataSource = parts[6]
        .split('--')[1]
        .replace(/~3A/g, ':')
        .replace(/~20/g, ' ');
      return {
        projectWiseLocation: {
          wsgProjectUrl: repository.location,
          fullDatasourceName: dataSource,
          ...(repository.hostName
            ? { serverHostname: repository.hostName }
            : {}),
        },
      };
    }
    case RepositoryTypeV2.Manifest:
      throw new Error('Input file location unsupported for manifest');
    default:
      assertUnreachable(repositoryType);
      return {};
  }
};

export const getDatasourceUrl = (repository: Repository) => {
  if (
    repository.type === RepositoryType.PWDI ||
    repository.type === RepositoryType.PWDI_LEGACY
  ) {
    return repository.location;
  } else {
    return null;
  }
};
export const getHostName = (repository: Repository) => {
  if (repository.type === RepositoryType.PWDI_LEGACY) {
    return repository.hostName;
  } else {
    return null;
  }
};

export const getDocumentServiceRepositoryType = (repoType: RepositoryType) =>
  repoType === RepositoryType.PWDI_LEGACY || repoType === RepositoryType.PW365
    ? RepositoryType.PWDI
    : repoType;

export const getJobTokenType = (repositoryType: RepositoryType) => {
  switch (repositoryType) {
    case RepositoryType.PWDI:
    case RepositoryType.PWDI_LEGACY:
    case RepositoryType.PW365:
      return TokenType.SAML;
    case RepositoryType.PROJECTSHARE:
    case RepositoryType.MANIFEST:
      return TokenType.OIDC;
    case RepositoryType.SHAREPOINT:
      throw new Error('Token type for SHAREPOINT not implemented');
    default:
      throw assertUnreachable(repositoryType);
  }
};

export const getBridgeAssignmements = (bridge: BridgeType, files: JobFiles) => {
  const names: string[] =
    files.spatialMaster != null ? [files.spatialMaster.name] : [];

  return [
    {
      bridge: bridge,
      fileBaseNames: [...names, ...files.masters.map(m => m.name)],
    },
  ];
};

import { RouteComponentProps } from '@reach/router';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { JobDefinition } from '../../externalComponents/entities/jobDefinition/jobDefinition';
import { isValidGuid } from '../../externalComponents/services/uiUtils/uuidUtils';
import { useTranslation } from 'react-i18next';
import { ConnectionDefinitionChangeMappings } from '../../externalComponents/components/connectionDefinitionChangeMappings/connectionDefinitionChangeMappings';
import { NonIdealState, Text } from '@itwin/itwinui-react';
import { Explanation } from '../../externalComponents/components/explanation/explanation';
import { COMMUNITIES_EDIT_MAPPING } from '../../externalComponents/entities/repository';
import { useFeatureToggleContext } from '../../externalComponents/context/featureToggleContext/featureToggleContext';
import { Svg404, SvgError } from '@itwin/itwinui-illustrations-react';

export interface ChangeMappingPageRoutedProps {
  jobId: string;
}

export interface ChangeMappingPageProps {
  areJobsLoading: boolean;
  jobs: JobDefinition[];
  navigateBackToJobsPage: (jobId: string) => any;
}

export const ChangeMappingsPage = (
  props: RouteComponentProps<ChangeMappingPageRoutedProps> &
    ChangeMappingPageProps
) => {
  const { jobId, jobs, areJobsLoading, navigateBackToJobsPage } = props;
  const { t } = useTranslation();
  const { deleteModel } = useFeatureToggleContext();

  const jobIdValid = jobId && isValidGuid(jobId);

  if (!jobIdValid) {
    return (
      <NonIdealState
        svg={<SvgError />}
        heading={t('FullPageError_InvalidConnectionId_Message')}
      />
    );
  }

  if (areJobsLoading) {
    return (
      <FullPageLoader>
        {t('FullPage_LoadingConnections_Message')}
      </FullPageLoader>
    );
  }

  const job = jobs.find(x => x.id === jobId);
  if (!job) {
    return (
      <NonIdealState
        svg={<Svg404 />}
        heading={t('FullPageError_ConnectionNotFound_Message')}
      />
    );
  }

  return (
    <div className="change-mapping-view">
      <Text variant="title" as="h2">
        {t('ChangeMappingPage_Title')}
      </Text>
      <Explanation
        text={`${t('ReadMoreAboutEditMappingTextPart1')}${
          !deleteModel ? t('ReadMoreAboutEditMappingTextPart2') : ''
        }${t('ReadMoreAboutEditMappingTextPart3')}`}
        linkText={t('ReadMoreAboutLimitations_Link')}
        linkUrl={COMMUNITIES_EDIT_MAPPING}
      />
      <ConnectionDefinitionChangeMappings
        connectionDefinitionId={job.id}
        onChangeMappingsCallback={() => navigateBackToJobsPage(job.id)}
        onCancelCallback={() => navigateBackToJobsPage(job.id)}
      />
    </div>
  );
};

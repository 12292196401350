import React from 'react';
import { Button, Modal } from '@itwin/itwinui-react';
import { Icon } from '../../icons/icon';
import { ModalProps } from '../../../hooks/useModal/useModal';
import { SvgFeedback } from '@itwin/itwinui-icons-react';
import './feedbackIntroductionModal.scss';
import giveUsFeedback from '../../../../images/give-us-feedback.jpg';
import { useTranslation } from 'react-i18next';

type FeedbackIntroductionModalProps = ModalProps & {
  onGotItClick: any;
};

export const FeedbackIntroductionModal: React.FC<
  FeedbackIntroductionModalProps
> = (props: FeedbackIntroductionModalProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal title={''} {...props} className="feedback-intro-modal">
        <div className="feedback-intro-modal-container">
          <Icon icon={SvgFeedback} size="xl" display={'flex'} />
          <div className="feedback-intro-title">
            {t('We_Want_Your_Feedback')}
          </div>
          <div className="feedback-intro-subtext">
            {t('New_Version_Of_Synchronization_Report_Available')}
          </div>
          <div className="feedback-intro-subtext">
            {t('Try_Feedback_So_We_Can_Improve')}
          </div>
          <div className="feedback-intro-subtext">
            {t('Click_On_The_Blue_Button_In_The_Bottom_Right')}
          </div>
          <img
            src={giveUsFeedback}
            loading="lazy"
            className="feedback-intro-img"
            alt=""
          ></img>
          <Button
            className="feedback-intro-button"
            onClick={() => props.onGotItClick()}
          >
            {t('Got_It')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JobScheduleSelectionV2 } from '../../schedule/scheduleV2/jobScheduleSelectionv2/jobScheduleSelectionV2';
import { JobScheduleSelection } from '../../schedule/schedulev1/jobScheduleSelectionV1/jobScheduleSelection';
import {
  ApiVersion,
  JobDefinition,
} from '../../../entities/jobDefinition/jobDefinition';
import { useLogger } from '../../../context/loggerContext/loggerContext';
import { useToast } from '../../../context/toastContext/toastContext';
import { useUpdateScheduleV1 } from '../../../hooks/useUpdateSchedule/useUpdateScheduleV1';
import { useUpdateScheduleV2 } from '../../../hooks/useUpdateSchedule/useUpdateScheduleV2';
import { ModalProps } from '../../../hooks/useModal/useModal';
import { Button, Modal, ModalButtonBar } from '@itwin/itwinui-react';
import '../../../components/schedule/schedulev1/jobScheduleSelectionV1/jobScheduleSelection.scss';
import { useBentleyContext } from '../../../context/bentleyContext/bentleyContext';

type EditScheduleModalProps = ModalProps & {
  jobDefinition: JobDefinition;
  projectId: string;
  schedule: string;
  onScheduleChange: () => void;
};

export const EditScheduleModal = (props: EditScheduleModalProps) => {
  const {
    jobDefinition,
    projectId,
    onScheduleChange,
    schedule,
    ...modalProps
  } = props;
  const { onClose } = modalProps;

  const { iModelId } = useBentleyContext();
  const { t } = useTranslation();
  const { logError } = useLogger();
  const { toastError, toastSuccess } = useToast();

  const isV2Job = jobDefinition.apiVersion === ApiVersion.v2;

  const [, , , updateScheduleV1] = useUpdateScheduleV1(projectId, iModelId);
  const [, , , updateScheduleV2] = useUpdateScheduleV2(projectId, iModelId);

  const [selectedSchedule, setSchedule] = useState(schedule);
  const [nextSchedule, setNextSchedule] = useState<Date | null>(
    jobDefinition.scheduledTimeUtc
  );

  const handleScheduleEditV1 = async () => {
    try {
      const result = await updateScheduleV1(
        jobDefinition,
        selectedSchedule,
        schedule
      );
      if (!result?.ok) {
        throw new Error(result?.error);
      }
      toastSuccess(t('EditScheduleSuccess_Message'));
    } catch (e) {
      logError(
        'Error when editing job schedule. New schedule: {newSchedule}. Error: {error}',
        [selectedSchedule, e]
      );
      toastError(t('EditScheduleFailure_ErrorMessage'));
    }
  };

  const handleScheduleEditV2 = async () => {
    const result = await updateScheduleV2(
      jobDefinition,
      selectedSchedule,
      nextSchedule
    );
    if (!result?.ok) {
      if (result.response?.status === 400)
        toastError(t('UpdateSchedule_ErrorMessage'));
      else toastError(t('EditScheduleFailure_ErrorMessage'));
      return;
    }

    toastSuccess(t('EditScheduleSuccess_Message'));
  };

  const handlePrimaryButton = async () => {
    if (!isV2Job) {
      await handleScheduleEditV1();
    } else {
      await handleScheduleEditV2();
    }
    onScheduleChange();
    onClose();
  };
  return (
    <Modal
      {...modalProps}
      title={t('ConnectorTypeDropDownInput_Label')}
      className="edit-schedule-modal"
    >
      <>
        {isV2Job ? (
          <JobScheduleSelectionV2
            onScheduleChange={(schedule: string, nextSchedule?: string) => {
              setSchedule(schedule);
              setNextSchedule(
                nextSchedule != null ? new Date(nextSchedule) : null
              );
            }}
            initialScheduleInterval={schedule}
            initialStart={jobDefinition.scheduledTimeUtc}
            currentSchedule={jobDefinition.scheduleStartTimeUtc}
          />
        ) : (
          <JobScheduleSelection
            onScheduleChange={(schedule: string) => setSchedule(schedule)}
            initialSchedule={schedule}
          />
        )}
        <ModalButtonBar>
          <Button
            data-testid="primary-button"
            onClick={handlePrimaryButton}
            styleType="high-visibility"
            disabled={
              selectedSchedule === schedule &&
              (nextSchedule != null ? new Date(nextSchedule) : null) ===
                jobDefinition.scheduledTimeUtc
            }
          >
            {t('OKBtn_Label')}
          </Button>
          <Button onClick={onClose}>{t('CancelBtn_Label')}</Button>
        </ModalButtonBar>
      </>
    </Modal>
  );
};

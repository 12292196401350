import { BridgeType } from '../bridge';
import { ExecutionAction } from '../jobDefinition/jobDefinition';

export interface GetRunsDtoV2 {
  value: RunDtoV2[];
}
export interface RunDtoV2 {
  endTimeStamp: string;
  iModelId: string;
  id: string;
  jobs: JobDtoV2[];
  phase: string;
  result: ResultDtoV2;
  startTimestamp: string;
  processingStartTimeStamp: string,
  state: JobRunStateDtoV2;
  connectionDefinitionId: string;
  triggerType: JobRunTriggerTypeDtoV2;
  triggeredBy: string;
  error?: ErrorDtoV2;
  contextId: string;
  dataLocationId: string;
}

export enum JobRunStateDtoV2 {
  Queued = 'Queued',
  NotFound = 'NotFound',
  NotStarted = 'NotStarted',
  Idle = 'Idle',
  WaitingToExecute = 'WaitingToExecute',
  WaitingToRetry = 'WaitingToRetry',
  Executing = 'Executing',
  Finalizing = 'Finalizing',
  Completed = 'Completed',
}

export enum JobRunTriggerTypeDtoV2 {
  Schedule = 'Schedule',
  OnDemand = 'OnDemand',
}

export interface JobDtoV2 {
  endTimeStamp: string;
  hasSpatialRootTask: boolean;
  hasTaskDependencies: boolean;
  id: string;
  jobType: string;
  poolId: string;
  poolImageId: string;
  priority: number;
  result: ResultDtoV2;
  startTimestamp: string;
  state: JobStateDtoV2;
  tasks: TaskDtoV2[];
  bridgeType: BridgeType;
  error?: ErrorDtoV2;
}

export interface ErrorDtoV2 {
  details: string;
  errorCode: string;
  message: string;
  kbArticleLink?: string;
  description?: string;
}
export interface DetailsDtoV2 {
  message: string;
  suggestedAction: string;
  systemMessage: string;
  type: string;
  count: number;
  totalErrorsCount?: number;
}

export enum JobStateDtoV2 {
  NotFound = 'NotFound',
  Completed = 'Completed',
  Executing = 'Executing',
  NotStarted = 'NotStarted',
}

export enum TaskStateDtoV2 {
  NotFound = 'NotFound',
  Completed = 'Completed',
  Executing = 'Executing',
  NotStarted = 'NotStarted',
  WaitingToRetry = 'WaitingToRetry',
  WaitingToExecute = 'WaitingToExecute',
}

export enum ResultDtoV2 {
  Undetermined = 'Undetermined',
  Success = 'Success',
  Error = 'Error',
  Skipped = 'Skipped',
  PartialSuccess = 'PartialSuccess',
  Canceled = 'Canceled',
  TimedOut = 'TimedOut',
  Paused = 'Paused',
  SuccessWithPaused = 'SuccessWithPaused',
}

export interface TaskDtoV2 {
  dependentTaskId: string;
  endTimeStamp: string;
  id: string;
  fileId: string;
  fileName?: string;
  result: ResultDtoV2;
  retryAttempts: number;
  startTimestamp: string;
  state: TaskStateDtoV2;
  action: ExecutionAction;
  error?: ErrorDtoV2;
  details?: DetailsDtoV2[];
  saasUrl?: string;
}

import React, { useEffect, useState } from 'react';
import { Redirect } from '@reach/router';
import { useAppContext } from '../../context/appContext/appContext';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { useTranslation } from 'react-i18next';

export const Login: React.FunctionComponent<any> = props => {
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const { user, userManager } = useAppContext();
  const [redirectPath, setPath] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const path =
      `${
        props['*'] != null && props['*'] !== 'undefined' ? '/' + props['*'] : ''
      }` + props.location?.search;
    setPath(path);

    if (user == null || user.expired) {
      userManager.signinRedirect({
        state: { from: `${window.location.origin}${path}` },
      });
    } else {
      setIsLoggingIn(false);
    }
  }, [props.from, user, userManager]);

  return isLoggingIn ? (
    <FullPageLoader>{t('FullPage_LoggingIn_Message')}</FullPageLoader>
  ) : redirectPath !== null ? (
    <Redirect to={`${redirectPath}`} noThrow />
  ) : (
    <></>
  );
};

export interface JobScheduleDto {
  contextId: string;
  iModelId: string;
  jobDefId: string;
  schedule: string;
  lastActivityId?: string;
  userId: string;
  lastRun: string;
  scheduleNextRunDate: string;
  failureCount: string;
}

export interface JobSchedule {
  projectId: string;
  iModelId: string;
  jobId: string;
  schedule: string;
  lastActivityId?: string;
  userId: string;
  lastRun?: Date;
  scheduleNextRunDate: Date | null;
  failureCount: string;
}

export const jobScheduleDtoToJobScheduleMap = (
  jobScheduleDto: JobScheduleDto
) => {
  return {
    ...jobScheduleDto,
    projectId: jobScheduleDto.contextId,
    jobId: jobScheduleDto.jobDefId,
    lastRun: jobScheduleDto.lastRun ? new Date(jobScheduleDto.lastRun) : null,
    scheduleNextRunDate: jobScheduleDto.scheduleNextRunDate
      ? new Date(jobScheduleDto.scheduleNextRunDate)
      : null,
  } as JobSchedule;
};

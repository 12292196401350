import React, { useEffect } from 'react';
import { useAppContext } from '../../context/appContext/appContext';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { User } from 'oidc-client';
import { useTranslation } from 'react-i18next';

export const CompleteForgeSignIn: React.FunctionComponent<any> = props => {
  const { forgeUserManager, setForgeUser } = useAppContext();
  const { t } = useTranslation();
  useEffect(() => {
    forgeUserManager.signinRedirectCallback().then((user: User) => {
      setForgeUser(user);
      window.location.href = user.state.from;
    });
  }, [forgeUserManager]);
  return (
    <FullPageLoader>{t('FullPage_CompletingSignIn_Message')}</FullPageLoader>
  );
};

import i18n from '../i18n/i18n';
import { assertUnreachable } from '../services/uiUtils/assertUnreachable';

export const CRON_IGNORE = '*';
export const CRON_EVERY_X_PREFIX = '*/';

export enum ScheduleInterval {
  InitiatedManually = 'initiatedManually',
  EveryMinute = 'everyMinute',
  Hourly = 'hourly',
  Every4Hours = 'each4hours',
  Daily = 'daily',
  Weekly = 'weekly',
}

export enum CronMinuteSchedule {
  None = '*',
  NotApplicable = '0',
  Minute5 = '5',
  Minute10 = '10',
  Minute15 = '15',
  Minute20 = '20',
  Minute30 = '30',
}

export const getMinuteScheduleFromCronString = (cronString: string) => {
  const [minute, _hour, _day, _month, _dayOfWeek] = cronString.split(' ');

  const minuteWithoutPrefix = minute.replace(
    CRON_EVERY_X_PREFIX,
    ''
  ) as CronMinuteSchedule;

  switch (minuteWithoutPrefix) {
    case CronMinuteSchedule.None:
    case CronMinuteSchedule.Minute5:
    case CronMinuteSchedule.Minute10:
    case CronMinuteSchedule.Minute15:
    case CronMinuteSchedule.Minute20:
    case CronMinuteSchedule.Minute30:
    case CronMinuteSchedule.NotApplicable:
      return minuteWithoutPrefix;
    default:
      assertUnreachable(minuteWithoutPrefix);
      return CronMinuteSchedule.None;
  }
};

export const getHourFromScheduleCronString = (cronString: string) => {
  const [_minute, hour, _day, _month, _dayOfWeek] = cronString.split(' ');

  return hour;
};

export const getWeekFromScheduleCronString = (cronString: string) => {
  const [_minute, _hour, _day, _month, dayOfWeek] = cronString.split(' ');

  return dayOfWeek;
};

export const CronMinuteScheduleDisplayNames = [
  {
    value: CronMinuteSchedule.Minute5,
    label: '5 min',
  },
  {
    value: CronMinuteSchedule.Minute10,
    label: '10 min',
  },
  {
    value: CronMinuteSchedule.Minute15,
    label: '15 min',
  },
  {
    value: CronMinuteSchedule.Minute20,
    label: '20 min',
  },
  {
    value: CronMinuteSchedule.Minute30,
    label: '30 min',
  },
];

export enum CronWeeklySchedule {
  None = '',
  WeeklySunday = '0',
  WeeklyMonday = '1',
  WeeklyTuesday = '2',
  WeeklyWednesday = '3',
  WeeklyThursday = '4',
  WeeklyFriday = '5',
  WeeklySaturday = '6',
}

export const CronWeeklyScheduleDisplayNames = [
  {
    value: CronWeeklySchedule.WeeklySunday,
    label: i18n.t('WeeklySelectDropDown_Sunday'),
  },
  {
    value: CronWeeklySchedule.WeeklyMonday,
    label: i18n.t('WeeklySelectDropDown_Monday'),
  },
  {
    value: CronWeeklySchedule.WeeklyTuesday,
    label: i18n.t('WeeklySelectDropDown_Tuesday'),
  },
  {
    value: CronWeeklySchedule.WeeklyWednesday,
    label: i18n.t('WeeklySelectDropDown_Wednesday'),
  },
  {
    value: CronWeeklySchedule.WeeklyThursday,
    label: i18n.t('WeeklySelectDropDown_Thursday'),
  },
  {
    value: CronWeeklySchedule.WeeklyFriday,
    label: i18n.t('WeeklySelectDropDown_Friday'),
  },
  {
    value: CronWeeklySchedule.WeeklySaturday,
    label: i18n.t('WeeklySelectDropDown_Saturday'),
  },
];


export const getHourlyCronExpression = () => '0 * * * *';

export const getMinuteCronExpression = (m: string) => `*/${m} * * * *`;

export const getEach4HoursCronExpression = () => `0 */4 * * *`;

export const getDailyCronExpressionForHour = (h: string) => `0 ${h} * * *`;

export const getWeeklyCronExpressionForHour = (w: string, h: string) =>
  `0 ${h} * * ${w}`;

export const getScheduleIntervalFromCronString = (cronString: string) => {
  if (!cronString) {
    return ScheduleInterval.InitiatedManually;
  }

  const [minute, hour, day, month, dayOfWeek] = cronString.split(' ');

  if (dayOfWeek !== CRON_IGNORE) {
    return ScheduleInterval.Weekly;
  }

  if (
    day === CRON_IGNORE &&
    !hour.startsWith(CRON_EVERY_X_PREFIX) &&
    hour !== CRON_IGNORE
  ) {
    return ScheduleInterval.Daily;
  }

  if (hour.startsWith(CRON_EVERY_X_PREFIX + '4')) {
    return ScheduleInterval.Every4Hours;
  }

  if (minute.startsWith(CRON_EVERY_X_PREFIX)) {
    return ScheduleInterval.EveryMinute;
  }

  if (minute === '0') {
    return ScheduleInterval.Hourly;
  }

  return ScheduleInterval.InitiatedManually;
};

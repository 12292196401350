import React from 'react';
import { ProgressRadial } from '@itwin/itwinui-react';
import './loadingOverlay.scss';
import cx from 'classnames';

interface LoadingOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  loading: boolean;
}

export const LoadingOverlay = (props: LoadingOverlayProps) => {
  const { loading, className, children, ...rest } = props;
  return (
    <div
      data-testid={'loading-overlay'}
      className={cx('loading-overlay-wrapper', className, {
        'is-loading': loading,
      })}
      {...rest}
    >
      {children}
      {loading && <ProgressRadial indeterminate />}
    </div>
  );
};

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchPW365Connection } from '../../hooks/useFetchPW365Connection/useFetchPW365Connection';
import './pw365Connections.scss';
import { LabeledSelect, Text } from '@itwin/itwinui-react';

export interface Pw365Connection {
  canned: boolean;
  id: string;
  name: string;
  url: string;
  disabled: boolean;
}

export interface Pw365ConnectionDTO {
  canned: boolean;
  defaultTab: string;
  description: string;
  disabled: boolean;
  displayLabel: string;
  id: string;
  legacyId: string;
  name: string;
  type: string;
  url: string;
  workAreaName: string;
}
interface Pw365ConnectionProps {
  projectId: string;
  onSelect: (selected: Pw365Connection) => void;
  onClose: () => void;
  selectedConnection?: Pw365Connection;
}

export const Pw365Connections = (props: Pw365ConnectionProps) => {
  const { onSelect, projectId, onClose, selectedConnection } = props;
  const [pwConnections, isLoading, , getPW365ConnectionData] =
    useFetchPW365Connection(projectId);

  const { t } = useTranslation();
  useEffect(() => {
    const getConnectionData = async () => {
      const connections = await getPW365ConnectionData();
      if (connections != null && connections?.length > 0) {
        onSelect(connections[0]);
      }
    };
    getConnectionData();
  }, [getPW365ConnectionData]);

  return (
    <div className="pw365-connections">
      <Text variant="subheading" as="h3">
        {t('SpecifyConnection_Headline')}
      </Text>
      <LabeledSelect<Pw365Connection>
        label={t('PW365Select_Label')}
        data-testid="pw365-selector"
        className="pw365-connections-select"
        onChange={(selected: Pw365Connection) => {
          !selected ? onClose() : onSelect(selected);
        }}
        disabled={isLoading}
        placeholder={
          isLoading ? t('PWDataSourceLoading_Label') : t('PW365DefaultValue')
        }
        options={pwConnections.map(pc => {
          return {
            label: pc.name,
            value: pc,
            disabled: pc.disabled,
            title: pc.disabled
              ? t('PWDataSourceOptionDisabled_Title')!
              : pc.name,
          };
        })}
        setFocus={true}
        title={t('PW365Typeahead_Label')!}
        value={selectedConnection}
      />
    </div>
  );
};

import { RouteComponentProps } from '@reach/router';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { JobDefinition } from '../../externalComponents/entities/jobDefinition/jobDefinition';
import { JobView } from '../../components/jobView/jobView';
import { isValidGuid } from '../../externalComponents/services/uiUtils/uuidUtils';
import { iModel } from '../../externalComponents/entities/iModel';
import { useTranslation } from 'react-i18next';
import { SpatialRoot } from '../../externalComponents/entities/spatialRootSetting';
import { navigate } from '../../externalComponents/services/uiUtils/navigation';
import { NonIdealState } from '@itwin/itwinui-react';
import { Svg404, SvgError } from '@itwin/itwinui-illustrations-react';

export interface JobPageRoutedProps {
  jobId: string;
}

export interface JobPageProps {
  areJobsLoading: boolean;
  jobs: JobDefinition[];
  projectId: string;
  iModel: iModel;
  spatialRoot: SpatialRoot | null;
  refetchJobs: () => any;
}

export const JobPage = (
  props: RouteComponentProps<JobPageRoutedProps> & JobPageProps
) => {
  const { jobId, jobs, areJobsLoading, projectId, iModel, refetchJobs } = props;
  const { t } = useTranslation();
  const jobIdValid = jobId && isValidGuid(jobId);

  if (!jobIdValid) {
    return (
      <NonIdealState
        svg={<SvgError />}
        heading={t('FullPageError_InvalidConnectionId_Message')}
      />
    );
  }

  if (areJobsLoading) {
    return (
      <FullPageLoader>
        {t('FullPage_LoadingConnections_Message')}
      </FullPageLoader>
    );
  }

  const job = jobs.find(x => x.id === jobId);
  if (!job) {
    return (
      <NonIdealState
        svg={<Svg404 />}
        heading={t('FullPageError_ConnectionNotFound_Message')}
      />
    );
  }

  return (
    <JobView
      job={job}
      jobs={jobs}
      projectId={projectId}
      iModel={iModel}
      refetchJobs={refetchJobs}
      changeMapping={() => navigate('changeMapping', true, props.navigate!)}
    />
  );
};

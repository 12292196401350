export enum Events {
  FeedbackSubmit = 'FeedbackSubmit',
  SynchronizationFeedback = 'SynchronizationFeedbackEvent',
  EmailNotificationSubscriptionStatus = 'EmailNotificationSubscriptionStatusEvent',
  EmailNotificationButtonClicked = 'EmailNotificationModalOpenedEvent',
  EmailNotificationFeedback = 'EmailNotificationFeedbackEvent',
  IssueArticleFeedback = 'IssueArticleFeedbackEvent',
  IssueArticleOpened = 'IssueArticleOpenedEvent',
  SyncReportOpened = 'SyncReportOpenedEvent',
}

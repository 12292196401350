import { iModel } from '../../externalComponents/entities/iModel';
import './iModelsList.scss';
import { useTranslation } from 'react-i18next';
import { Button, Tile } from '@itwin/itwinui-react';
import { SvgImodelHollow } from '@itwin/itwinui-icons-react';
import cx from 'classnames';
import { Icon } from '../../externalComponents/components/icons/icon';
import { TruncatedText } from '../../externalComponents/components/truncatedText/TruncatedText';
interface IModelListsProps {
  iModels: iModel[];
  onNavigate: (iModelId: string) => void;
  onCreateIModelClick: () => any;
}

export const IModelsList = (props: IModelListsProps) => {
  const { iModels, onNavigate, onCreateIModelClick: onCreateIModel } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="imodel-toolbar">
        <Button
          styleType="high-visibility"
          onClick={onCreateIModel}
          title={t('CreateIModelBtn_Tooltip')}
        >
          {t('CreateIModelBtn_Label')}
        </Button>
      </div>
      <div className="imodel-list">
        {iModels.map(iModel => (
          <div className={cx('iModel')}>
            <div
              className={cx('iModel-clickable')}
              onClick={() => onNavigate(iModel.id)}
            >
              <Tile
                name={
                  <TruncatedText onClick={() => onNavigate(iModel.id)}>
                    {iModel.displayName}
                  </TruncatedText>
                }
                thumbnail={
                  <Icon
                    className="icon"
                    icon={SvgImodelHollow}
                    color="default"
                    data-testid="iModel-tile-svg"
                  />
                }
                variant="default"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

import { useEffect, useState } from 'react';
import { useToast } from '../../externalComponents/context/toastContext/toastContext';
import { Alert, Tile } from '@itwin/itwinui-react';
import {
  JobDefinition,
  ApiVersion,
} from '../../externalComponents/entities/jobDefinition/jobDefinition';
import {
  JobRun,
  JobRunState,
} from '../../externalComponents/entities/jobRunStatus/jobRunStatus';
import { useRunJob } from '../../externalComponents/hooks/useRunJob/useRunJob';
import './jobView.scss';
import { iModel } from '../../externalComponents/entities/iModel';
import { getJobTokenType } from '../../externalComponents/services/bridgeLogic/bridgeLogic';
import { getRunJobAuthenticationCallbackUrl } from '../../externalComponents/services/uiLogic/urlLogic';
import { useModal } from '../../externalComponents/hooks/useModal/useModal';
import { RenameJobModal } from '../../externalComponents/components/renameJobModal/renameJobModal';
import { useRenameJob } from '../../externalComponents/hooks/useRenameJob/useRenameJob';
import { useGetJobSchedule } from '../../externalComponents/hooks/useGetJobSchedule/useGetJobSchedule';
import { useFeatureToggleContext } from '../../externalComponents/context/featureToggleContext/featureToggleContext';
import { useTranslation } from 'react-i18next';
import { useTrackFeature } from '../../externalComponents/hooks/useTrackFeature/useTrackFeature';
import { Features } from '../../externalComponents/context/usageLoggerContext/featureConstants';
import {
  embeddedFileBasenameBridgeArgs,
  embeddedFileBasenameRegexV2,
  jobStatusUpdateIntervalInSeconds,
} from '../../externalComponents/constants/constants';
import { JobSchedule } from '../../externalComponents/entities/jobSchedule';
import { ConnectionDefinitionRunStatusTable } from '../../externalComponents/components/connectionDefinitionRunStatusTable/connectionDefinitionRunStatusTable';
import { ScheduleIntervalV2 } from '../../externalComponents/components/schedule/scheduleV2/jobScheduleSelectionv2/jobScheduleSelectionV2';
import { getFormattedDateAndTime } from '../../externalComponents/services/uiUtils/dateFormatter';
import { usePermissionContext } from '../../externalComponents/context/iModelPermissionContext/iModelPermissionContext';
import { ConnectionInputFileTable } from '../../externalComponents/components/connectionInputFileTable/connectionInputFileTable';
import { EditScheduleModal } from '../../externalComponents/components/modals/editScheduleModal/editScheduleModal';
import { toString as cronToString } from 'cronstrue';
import { getFailureCountV2 } from './failureCount';
import { IconButton, Button, Tooltip, Text } from '@itwin/itwinui-react';
import { RegexModal } from '../../externalComponents/components/regexModal/regexModal';
import { useUpdateFileRegex } from '../../hooks/useUpdateFileRegex/useUpdateFileRegex';
import { ButtonGroup } from '../../externalComponents/components/iTwinUI/buttonGroup';
import { SvgEdit, SvgImodelHollow } from '@itwin/itwinui-icons-react';
import { useOrchestratorAuthenticateUser } from '../../externalComponents/hooks/useOrchestratorAuthenticateUser/useOrchestratorAuthenticateUser';
import {
  MenuAction,
  getToolbarActions,
} from '../../externalComponents/components/jobView/toolbarActions';
import { useIModelContext } from '../../context/navigationContext/iModelNameContext';
import { useProjectContext } from '../../context/projectContext/projectContext';
import { useInputFilesContext } from '../../externalComponents/context/inputFilesContext/inputFilesContext';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { Icon } from '../../externalComponents/components/icons/icon';
import { useMemo } from 'react';
import { TruncatedText } from '../../externalComponents/components/truncatedText/TruncatedText';

export interface JobViewProps {
  job: JobDefinition;
  jobs: JobDefinition[];
  projectId: string;
  iModel: iModel;
  refetchJobs: () => any;
  changeMapping: () => any;
}
export interface FileMap {
  [key: string]: string;
}
export const JobView = (props: JobViewProps) => {
  useTrackFeature(Features.ViewJobConfigurationPage);

  const { job, jobs, projectId, iModel, refetchJobs, changeMapping } = props;
  const { toastError, toastSuccess } = useToast();
  const { currentProject } = useProjectContext();
  const { currentIModel } = useIModelContext();

  const [isJobRunning, setIsJobRunning] = useState(false);
  const [refetchStatuses, setRefetchStatuses] = useState<() => Promise<void>>();

  const { t } = useTranslation();

  const { isregexInputEnabled } = useFeatureToggleContext();

  const { canModifyiModel, canManageiModel } = usePermissionContext();

  const [jobStatuses, setJobStatuses] = useState<JobRun[]>([]);
  const [areJobStatusesLoading, setAreJobStatusesLoading] = useState(true);

  useEffect(() => {
    if (refetchStatuses) {
      setAreJobStatusesLoading(true);
      refetchStatuses();
      setAreJobStatusesLoading(false);
    }
  }, [refetchStatuses]);

  const [jobSchedule, isScheduleLoading, , fetchV1Schedule] = useGetJobSchedule(
    projectId,
    iModel.id
  );

  useEffect(() => {
    if (
      job.apiVersion === ApiVersion.v1 &&
      jobSchedule != null &&
      jobSchedule.scheduleNextRunDate == null &&
      jobStatuses.length > 0 &&
      jobStatuses[0].jobStatus === JobRunState.Completed
    ) {
      fetchV1Schedule(job);
    }
  }, [fetchV1Schedule, job, jobSchedule, jobStatuses]);

  const [areInputFilesLoading, setAreInputFilesLoading] = useState(true);
  const { fetchInputFiles } = useInputFilesContext();
  useEffect(() => {
    const fetchInputFilesAsync = async (job: JobDefinition) => {
      setAreInputFilesLoading(true);
      await fetchInputFiles(job);
      setAreInputFilesLoading(false);
    };

    if (job?.apiVersion === ApiVersion.v2) {
      fetchInputFilesAsync(job);
    } else {
      setAreInputFilesLoading(false);
    }
  }, [fetchInputFiles, job]);

  const [isScheduleInitializing, setIsScheduleInitializing] = useState(true);

  const fileRegex =
    job.apiVersion === ApiVersion.v2
      ? job.bridgeParameters != null &&
        job.bridgeParameters[embeddedFileBasenameRegexV2] != null
        ? job.bridgeParameters[embeddedFileBasenameRegexV2]
        : null
      : job.bridgeParameters != null &&
        job.bridgeParameters[embeddedFileBasenameBridgeArgs] != null
      ? job.bridgeParameters[embeddedFileBasenameBridgeArgs]
      : null;

  useEffect(() => {
    if (!isScheduleLoading) {
      setIsScheduleInitializing(false);
    }
  }, [isScheduleLoading]);

  const [, isRunJobRequestLoading, , runJob] = useRunJob(
    projectId,
    iModel.id
  );
  const [, isAuthenticateRequestLoading, , orchestratorAuthenticateUser] =
    useOrchestratorAuthenticateUser();

  const {
    closeModal: closeRenameJobModal,
    openModal: openRenameModal,
    modalProps: renameModalProps,
  } = useModal();

  const {
    closeModal: closeRegexModal,
    openModal: openRegexModal,
    modalProps: regexModalProps,
  } = useModal();

  const [, , , renameJob] = useRenameJob(projectId, iModel.id);

  const [isUpdateInProgress, , updateFileRegex] = useUpdateFileRegex(
    projectId,
    iModel.id,
    job
  );

  const runJobAndHandle = async (collectDiagnostics: boolean = false) => {
    const jobTokenType = getJobTokenType(job.repositoryType);
    const redirectUrl = getRunJobAuthenticationCallbackUrl(
      projectId,
      iModel.id,
      job.id
    );
    const authenticated = await orchestratorAuthenticateUser(
      jobTokenType,
      redirectUrl
    );

    if (!authenticated) {
      return;
    }
    await runJob(job, collectDiagnostics);
    if (refetchStatuses) {
      await refetchStatuses();
    }
  };

  useEffect(() => {
    const initialize = async () => {
      await fetchV1Schedule(job);
    };
    if (job.apiVersion === ApiVersion.v1) {
      initialize();
    }
  }, [fetchV1Schedule, job]);

  useEffect(() => {
    const refreshSchedulesAndStatusesAfterScheduledJobRun = () => {
      const isScheduledJobDue =
        jobSchedule?.scheduleNextRunDate &&
        jobSchedule.scheduleNextRunDate < new Date();
      if (!isScheduledJobDue) {
        return;
      }

      if (refetchStatuses) {
        refetchStatuses();
      }

      if (job.apiVersion === ApiVersion.v1) {
        fetchV1Schedule(job);
      }
    };

    const timer = window.setInterval(
      refreshSchedulesAndStatusesAfterScheduledJobRun,
      jobStatusUpdateIntervalInSeconds * 1000
    );
    return () => window.clearInterval(timer);
  }, [fetchV1Schedule, refetchStatuses, job, jobSchedule]);

  useEffect(() => {
    const jobStatusValues = jobStatuses.map(x => x.jobStatus);
    const isJobNotFinished =
      jobStatusValues.includes(JobRunState.Queued) ||
      jobStatusValues.includes(JobRunState.InProgress) ||
      jobStatusValues.includes(JobRunState.NotStarted);

    let timer = 0;
    setIsJobRunning(isJobNotFinished);

    if (isJobNotFinished && refetchStatuses) {
      timer = window.setInterval(async () => {
        await refetchStatuses();
      }, jobStatusUpdateIntervalInSeconds * 1000);
    }

    return () => {
      window.clearInterval(timer);
    };
  }, [jobStatuses, refetchStatuses]);

  const isRunJobLoading =
    isRunJobRequestLoading || isAuthenticateRequestLoading;

  const disableRunJobButton =
    isRunJobLoading || areJobStatusesLoading || isJobRunning;

  const {
    modalProps: editScheduleModalProps,
    openModal: openEditScheduleModal,
  } = useModal();

  const scheduleInfo = useMemo(
    () => (job.scheduleInfo ? job.scheduleInfo : '0'),
    [job]
  );

  const disableChangeMappings = jobStatuses.some(
    x =>
      x.jobStatus === JobRunState.InProgress ||
      x.jobStatus === JobRunState.Queued ||
      x.jobStatus === JobRunState.NotStarted
  );

  const toolbarActions = getToolbarActions(
    t,
    disableRunJobButton,
    runJobAndHandle,
    openRenameModal,
    openRegexModal,
    changeMapping,
    isregexInputEnabled,
    canModifyiModel,
    canManageiModel,
    disableChangeMappings,
    job.isDeletePending
  );

  return (
    <>
      <EditScheduleModal
        {...editScheduleModalProps}
        jobDefinition={job}
        projectId={projectId}
        schedule={
          job.apiVersion === ApiVersion.v2
            ? job.scheduleInfo
              ? job.scheduleInfo
              : '0'
            : jobSchedule?.schedule ?? ''
        }
        onScheduleChange={async () => {
          job.apiVersion === ApiVersion.v2
            ? await refetchJobs()
            : await fetchV1Schedule(job);
        }}
      />
      {renameModalProps.isOpen && (
        <RenameJobModal
          {...renameModalProps}
          job={job}
          jobs={jobs}
          closeModal={closeRenameJobModal}
          renameJobName={async (job, newName) => {
            const result = await renameJob(job, newName);
            closeRenameJobModal();
            if (!result.ok) {
              toastError(t('JobRenameFailed_Toast'));
              return;
            }
            await refetchJobs();
            toastSuccess(t('JobRenameSuccess_Toast'));
          }}
        />
      )}
      <RegexModal
        {...regexModalProps}
        closeModal={closeRegexModal}
        submitRegex={async regex => {
          await updateFileRegex(regex);
          closeRegexModal();
          await refetchJobs();
        }}
        regexInitialValue={fileRegex ?? ''}
        hasLoadingOverlay={isUpdateInProgress}
      />
      {areInputFilesLoading ? (
        <FullPageLoader />
      ) : (
        <div className="job-view-page">
          <JobViewFirstRow
            iModel={iModel}
            job={job}
            jobStatuses={jobStatuses}
            toolbarActions={toolbarActions}
            jobSchedule={jobSchedule}
            scheduleInfo={scheduleInfo}
            openEditScheduleModal={openEditScheduleModal}
            isScheduleInitializing={isScheduleInitializing}
          />
          <JobViewSecondRow
            setRefetchStatusesMethod={refetchStatusesMethod => {
              setRefetchStatuses(() => refetchStatusesMethod);
            }}
            areStatusesLoading={areJobStatusesLoading}
            jobDefinition={job}
            currentProjectName={currentProject.name}
            currentiModelName={currentIModel.displayName}
            onRunStatusUpdated={updatedStatuses => {
              setJobStatuses(updatedStatuses);
            }}
          />
        </div>
      )}
    </>
  );
};

const JobViewFirstRow = (props: {
  iModel: iModel;
  job: JobDefinition;
  jobSchedule?: JobSchedule;
  scheduleInfo?: string;
  jobStatuses: JobRun[];
  toolbarActions: MenuAction[];
  openEditScheduleModal: () => any;
  isScheduleInitializing: boolean;
}) => {
  const { t } = useTranslation();
  const { canModifyiModel } = usePermissionContext();
  const {
    iModel,
    job,
    toolbarActions,
    jobSchedule,
    jobStatuses,
    scheduleInfo,
    openEditScheduleModal,
    isScheduleInitializing,
  } = props;
  const isV2Job = job.apiVersion === ApiVersion.v2;

  const jobFailureCount = getFailureCountV2(jobStatuses);
  const getScheduleInfoString = () => {
    let nextScheduledTime;
    const scheduledTime =
      job.scheduleStartTimeUtc == null
        ? job.scheduledTimeUtc
        : job.scheduleStartTimeUtc;

    if (scheduledTime) {
      const timeElapsedSinceScheduled =
        Date.now().valueOf() - scheduledTime.valueOf();

      const scheduleLoopInMs = Number.parseInt(scheduleInfo!) * 1000;

      if (job.scheduleStartTimeUtc != null && timeElapsedSinceScheduled < 0) {
        nextScheduledTime = new Date(scheduledTime.valueOf());
      } else if (timeElapsedSinceScheduled < scheduleLoopInMs) {
        nextScheduledTime = new Date(
          scheduledTime.valueOf() + scheduleLoopInMs
        );
      } else {
        const timesRunIncludingNext = Math.ceil(
          timeElapsedSinceScheduled / scheduleLoopInMs
        );
        nextScheduledTime = new Date(
          scheduledTime.valueOf() + timesRunIncludingNext * scheduleLoopInMs
        );
      }
    }

    let intervalString = '';
    switch (Number.parseInt(scheduleInfo!)) {
      case ScheduleIntervalV2.Every4Hours:
        intervalString = 'Every 4 hours';
        break;
      case ScheduleIntervalV2.Hourly:
        intervalString = 'Hourly';
        break;
      case ScheduleIntervalV2.Daily:
        intervalString = 'Daily';
        break;
      case ScheduleIntervalV2.Weekly:
        intervalString = 'Weekly';
        break;
      default:
        intervalString =
          'Every ' + Number.parseInt(scheduleInfo!) / 60 + ' minutes';
        break;
    }

    return `${intervalString} ${
      nextScheduledTime
        ? `(${t('NextRunOn_Label')} ${getFormattedDateAndTime(
            nextScheduledTime
          )})`
        : ''
    }`;
  };

  return (
    <div className="job-view-flex-row-1">
      <div className="tile-card tile-card-low iModel-static flex-rows flex-with-items-margin-large">
        <Tile
          thumbnail={
            <Icon
              className="icon"
              icon={SvgImodelHollow}
              color="default"
              data-testid="iModel-tile-svg"
            />
          }
          name={<TruncatedText>{iModel.displayName}</TruncatedText>}
          variant="default"
        />
      </div>
      <div className="tile-card tile-card-low greedy-flex-grow filepicker-wrapper">
        <Text variant="subheading" as="h3">
          {job.name}
        </Text>
        {!isV2Job ? (
          !isScheduleInitializing && (
            <>
              <div className="flex-rows flex-with-items-margin-small schedule-row">
                <Text variant="body" as="p" className="schedule-text">
                  {t('ScheduleInUtc_Label')}:{' '}
                  {jobSchedule?.schedule
                    ? cronToString(jobSchedule?.schedule)
                    : t('ScheduleNotScheduled_Message')}
                  .
                </Text>
                {jobSchedule && jobSchedule.scheduleNextRunDate != null && (
                  <div>
                    {t('ScheduleNextRunAt_Label')}:{' '}
                    {jobSchedule!.scheduleNextRunDate
                      .toUTCString()
                      .replace('GMT', 'UTC')}
                  </div>
                )}
                <Tooltip content={t('ScheduleEditBtn_Tooltip')}>
                  <div>
                    <IconButton
                      onClick={() => {
                        openEditScheduleModal();
                      }}
                      data-testid="scheduleEdit"
                      disabled={!canModifyiModel}
                      size="small"
                      styleType="borderless"
                    >
                      <SvgEdit />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
              {jobSchedule && jobSchedule.scheduleNextRunDate == null && (
                <Alert type="warning" className="schedule-alert">
                  {t('SuspendedFailingSchedule_Alert', {
                    failureCount: jobSchedule.failureCount,
                  })}
                </Alert>
              )}
            </>
          )
        ) : (
          <>
            <div className="flex-rows flex-with-items-margin-small schedule-row">
              <Text variant="body" as="p" className="schedule-text">
                {t('Schedule_Label')}
                {scheduleInfo && scheduleInfo !== '0'
                  ? getScheduleInfoString()
                  : t('ScheduleNotScheduled_Message')}
              </Text>
              <Tooltip content={t('ScheduleEditBtn_Tooltip')} placement="top">
                <div>
                  <IconButton
                    onClick={() => {
                      openEditScheduleModal();
                    }}
                    data-testid="scheduleEdit"
                    disabled={!canModifyiModel}
                    size="small"
                    styleType="borderless"
                  >
                    <SvgEdit />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
            {job.isScheduled &&
              job.isSchedulePaused &&
              (job.lastRunDetails?.state === JobRunState.Failed ? (
                <Alert type="warning" className="schedule-alert">
                  {t('SuspendedFailingSchedule_Alert', {
                    failureCount: jobFailureCount,
                  })}
                </Alert>
              ) : (
                <Alert type="informational" className="schedule-alert">
                  {t('SuspendedSchedule_Alert')}
                </Alert>
              ))}
          </>
        )}
        <div className="flex-columns job-schedule-mini-tile">
          <div className="table-toolbar">
            <ButtonGroup>
              {toolbarActions.map(action => {
                return (
                  <Tooltip
                    key={action.title}
                    content={action.title}
                    placement="top"
                  >
                    <div>
                      <IconButton
                        //@ts-ignore
                        data-testid={action['data-testid']}
                        disabled={action.disabled}
                        onClick={action.onClick}
                      >
                        {action.icon}
                      </IconButton>
                    </div>
                  </Tooltip>
                );
              })}
            </ButtonGroup>
          </div>
        </div>

        <div className="job-view-filepicker filepicker-wrapper">
          <ConnectionInputFileTable connectionDefinitionId={job.id} />
        </div>
      </div>
    </div>
  );
};

const JobViewSecondRow = (props: {
  areStatusesLoading: boolean;
  setRefetchStatusesMethod: (refetchStatusMethod: () => Promise<void>) => void;
  jobDefinition: JobDefinition;
  currentProjectName: string;
  currentiModelName: string;
  onRunStatusUpdated: (updatedStatuses: JobRun[]) => void;
}) => {
  const {
    setRefetchStatusesMethod,
    jobDefinition,
    currentProjectName,
    currentiModelName,
    onRunStatusUpdated,
  } = props;
  const { t } = useTranslation();

  const [refetchStatuses, setRefetchStatuses] = useState<() => Promise<void>>();
  const [areStatusesLoading, setAreStatusesLoading] = useState(false);

  return (
    <div className="job-view-row-2">
      <div className="tile-card tile-card-high table-wrapper filepicker-wrapper job-run-details-table">
        <div className="table-toolbar">
          <Text variant="subheading" as="h3">
            {t('JobRunDetailsTable_Name')}
          </Text>
          <Button
            styleType="high-visibility"
            onClick={async () => {
              if (refetchStatuses) {
                setAreStatusesLoading(true);
                await refetchStatuses();
                setAreStatusesLoading(false);
              }
            }}
            disabled={areStatusesLoading}
            title={t('RefreshStatusBtn_Tooltip')}
          >
            {t('RefreshStatusBtn_Label')}
          </Button>
        </div>
        <ConnectionDefinitionRunStatusTable
          orchAuthRedirectUrl={window.location.href}
          connectionDefinitionId={jobDefinition.id}
          currentProjectName={currentProjectName}
          currentiModelName={currentiModelName}
          setRefetchStatusesMethod={refetchStatusesMethod => {
            setRefetchStatuses(() => refetchStatusesMethod);
            setRefetchStatusesMethod(refetchStatusesMethod);
          }}
          onRunStatusUpdated={onRunStatusUpdated}
        />
      </div>
    </div>
  );
};

import {
  JobRun,
  JobRunState,
  JobRunTriggerType,
} from '../../externalComponents/entities/jobRunStatus/jobRunStatus';

export const getFailureCountV2 = (jobStatuses: JobRun[]) => {
  let failure = 0;
  if (jobStatuses.length > 0) {
    const isManualScheduleStarted =
      jobStatuses[0].triggerType === JobRunTriggerType.Manual &&
      jobStatuses[0].jobStatus !== JobRunState.Failed;
    for (let i = isManualScheduleStarted ? 1 : 0; i < jobStatuses.length; i++) {
      if (jobStatuses[i].jobStatus === JobRunState.Failed) failure++;
      else break;
    }
  }
  return failure;
};

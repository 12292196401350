import { useState } from 'react';

export type InputStatus = 'positive' | 'warning' | 'negative' | undefined;

export const useInputField = () => {
  return useInputFieldWithInitialValues(undefined, '', true);
};

export const useOptionalInputField = () => {
  return useInputFieldWithInitialValues(undefined, '', false);
};

const useInputFieldWithInitialValues = (
  initialStatus: InputStatus,
  initialErrorMessage: string,
  initialIsPrimaryDisabled: boolean
): [
  InputStatus,
  string,
  boolean,
  (status: InputStatus, errorMessage: string) => void,
  () => void
] => {
  const [status, setStatus] = useState<InputStatus>(initialStatus);
  const [errorMessage, setErrorMessage] = useState<string>(initialErrorMessage);
  const [isPrimaryDisabled, setIsPrimaryDisabled] = useState<boolean>(
    initialIsPrimaryDisabled
  );

  const setInputFieldState = (status: InputStatus, errorMessage: string) => {
    setStatus(status);
    setErrorMessage(errorMessage);
    setIsPrimaryDisabled(status === 'negative');
  };
  const invalidateInputFieldState = () => {
    setStatus(initialStatus);
    setErrorMessage(initialErrorMessage);
    setIsPrimaryDisabled(initialIsPrimaryDisabled);
  };
  return [
    status,
    errorMessage,
    isPrimaryDisabled,
    setInputFieldState,
    invalidateInputFieldState,
  ];
};

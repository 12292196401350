import React, { useState } from 'react';
import { Repository } from '../../../entities/repository';
import {
  WizardButtonRow,
  WizardButtonRowProps,
} from '../wizardButtonRow/wizardButtonRow';
import {
  JobFiles,
  JobDefinition,
} from '../../../entities/jobDefinition/jobDefinition';
import {
  CreateConnectionContent,
  ConnectionConfiguration,
} from '../createConnectionContent/createConnectionContent';
import './createConnectionStep.scss';
import { BridgeType } from '../../../entities/bridge';
import { MasterContext } from '../../../hooks/useGetMasterReferencesTree/useGetMasterReferencesTree';

interface CreateConnectionStepProps {
  bridge?: BridgeType;
  jobs: JobDefinition[];
  masterContext: MasterContext[] | null;
  jobFiles: JobFiles;
  repository: Repository;
  isJobBeingCreated: boolean;
  isAffinityUIEnabled: boolean;
}

export const CreateConnectionStep = (
  props: CreateConnectionStepProps & WizardButtonRowProps
) => {
  const {
    bridge,
    isJobBeingCreated,
    jobs,
    jobFiles,
    masterContext,
    repository,
    backHandle,
    cancelHandle,
    primaryButtonLabel,
    primaryButtonHandle,
    isAffinityUIEnabled,
  } = props;
  const [configuration, setConfiguration] = useState<ConnectionConfiguration>();
  const [isConfigurationInValid, setIsConfigurationValid] =
    useState<boolean>(false);
  return (
    <>
      <div className="create-connection-step-content">
        <CreateConnectionContent
          bridge={bridge}
          jobs={jobs}
          jobFiles={jobFiles}
          masterContext={masterContext}
          repository={repository}
          isJobBeingCreated={isJobBeingCreated}
          onConnectionConfigurationChange={setConfiguration}
          onDataValidationStateChange={setIsConfigurationValid}
          isAffinityUIEnabled={isAffinityUIEnabled}
        />
      </div>
      <WizardButtonRow
        backHandle={backHandle}
        cancelHandle={cancelHandle}
        primaryButtonHandle={() => primaryButtonHandle(configuration)}
        primaryButtonLabel={primaryButtonLabel}
        primaryDisabled={isConfigurationInValid}
      />
    </>
  );
};

import React from 'react';
import './navigatableHeader.scss';
import { Link, Router, RouteComponentProps } from '@reach/router';
import { useAppContext } from '../../context/appContext/appContext';
import { useIModelContext } from '../../context/navigationContext/iModelNameContext';
import { FeedbackButton } from '../feedbackButton/feedbackButton';
import { Tooltip } from '@itwin/itwinui-react';
import { SvgChevronRight, SvgMoon, SvgSun } from '@itwin/itwinui-icons-react';
import { useTranslation } from 'react-i18next';
import { useFeatureToggleContext } from '../../externalComponents/context/featureToggleContext/featureToggleContext';
import {
  ThemeType,
  useThemeContext,
} from '../../context/themeContext/themeContext';
import { mergeWithGlobalQueryParams } from '../../externalComponents/services/uiUtils/navigation';
import { Icon } from '../../externalComponents/components/icons/icon';

interface NavigationProps {
  labels: string[];
}
interface SecondaryHeaderProps {
  isIFramed?: boolean;
}

const Navigation = (
  props: RouteComponentProps<{ projectId: string }> & NavigationProps
) => {
  const { labels, projectId, location } = props;
  const {
    configuration: { buddiUrls },
  } = useAppContext();

  let acc = '';
  const to = labels
    .map(() => {
      const el = acc;
      acc += '../';
      return el;
    })
    .reverse();

  const imodelPageUrl =
    location?.hostname === 'localhost'
      ? mergeWithGlobalQueryParams(`${location?.origin}/${projectId}`)
      : `${buddiUrls.iModelHubPortal}Context/${projectId}`;
  return (
    <ul className="link-wrapper">
      {labels.map((label, index) => (
        <React.Fragment key={index}>
          {index !== 0 ? (
            <>
              <SvgChevronRight className="header-arrow" />
              <Link to={mergeWithGlobalQueryParams(to[index])}>{label}</Link>
            </>
          ) : (
            <a href={imodelPageUrl}>{label}</a>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export const SecondaryHeader = (props: SecondaryHeaderProps) => {
  const { currentIModel } = useIModelContext();
  const { theme, setAppTheme } = useThemeContext();
  const { showThemeToggle } = useFeatureToggleContext();
  const { t } = useTranslation();
  return (
    <div className="secondary-header">
      <Router>
        <Navigation path="/" labels={['iModels']} />
        <Navigation
          path="/:imodelId/*"
          labels={['iModels', currentIModel.displayName]}
        />
        <Navigation
          path="/:imodelId/createConnection"
          labels={['iModels', currentIModel.displayName, 'Create connection']}
        />
        <Navigation
          path="/:imodelId/:connectionId/*"
          labels={['iModels', currentIModel.displayName, 'Connection Configuration']}
        />
        <Navigation
          path="/:imodelId/:connectionId/:synchronizationReportId/*"
          labels={[
            'iModels',
            currentIModel.displayName,
            'Connection Configuration',
            'Synchronization Report',
          ]}
        />
      </Router>
      {!props.isIFramed ? (
        <>
          {showThemeToggle && (
            <Tooltip content={t('ToggleThemeButton_Tooltip')}>
              <div className="theme-toggle-wrapper">
                <Icon
                  icon={theme === 'light' ? SvgSun : SvgMoon}
                  className="header-icon"
                  onClick={() => {
                    setAppTheme(
                      theme === 'light' ? ThemeType.Dark : ThemeType.Light
                    );
                  }}
                />
              </div>
            </Tooltip>
          )}
          <FeedbackButton />
        </>
      ) : (
        <> </>
      )}
    </div>
  );
};

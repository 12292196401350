import React from 'react';
import { TFunction } from 'i18next';
import {
  SvgPlay,
  SvgRename,
  SvgSettings,
  SvgUnlink,
} from '@itwin/itwinui-icons-react';

export type MenuAction = {
  icon?: JSX.Element;
  onClick: () => void;
  title: string;
  disabled?: boolean;
  'data-testid'?: string;
};

export function getToolbarActions(
  t: TFunction,
  disableRunJob: boolean,
  runJobAndHandle: () => any,
  openRenameModal: () => any,
  openRegexModal: () => void,
  changeMapping: () => any,
  showEditRegexButton: boolean,
  canModifyiModel: boolean,
  canManageiModel: boolean,
  disableChangeMappings: boolean,
  isDeletePending: boolean
) {
  const actions: MenuAction[] = [
    {
      title: t('RunJobBtn_Tooltip'),
      'data-testid': 'RunJob',
      icon: <SvgPlay />,
      disabled: disableRunJob || !canModifyiModel,
      onClick: async () => {
        await runJobAndHandle();
      },
    },
  ];
  actions.push({
    title: t('ChangeMappingBtn_Title'),
    'data-testid': 'ChangeMapping',
    icon: <SvgUnlink />,
    onClick: changeMapping,
    disabled:
      !canModifyiModel ||
      !canManageiModel ||
      disableChangeMappings ||
      isDeletePending,
  });
  actions.push({
    title: t('RenameJobBtn_Tooltip'),
    'data-testid': 'RenameJob',
    icon: <SvgRename />,
    disabled: !canModifyiModel,
    onClick: () => {
      openRenameModal();
    },
  });
  if (showEditRegexButton) {
    actions.push({
      title: t('MatchUniqueFilesBtn_Title'),
      'data-testid': 'match-unique-files',
      icon: <SvgSettings />,
      onClick: () => {
        openRegexModal();
      },
      disabled: !canModifyiModel,
    });
  }

  return actions;
}

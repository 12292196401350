import React, { useCallback, useMemo } from 'react';
import {
  LogDispatcherClient,
  LogType,
} from '@bentley/log-dispatcher-client-js';
import { useBentleyContext } from '../bentleyContext/bentleyContext';
import { useUserContext } from '../userContext/userContext';
import { useBuddiContext } from '../buddiContext/buddiContext';

export type LogLevel =
  | 'Trace'
  | 'Debug'
  | 'Information'
  | 'Warning'
  | 'Error'
  | 'Critical';

export interface ILogContext {
  logError: (message: string, params: any) => void;
  logInformation: (message: string, params: any) => void;
}

export const LoggerContext = React.createContext<ILogContext>({
  logError: () => {},
  logInformation: () => {},
});

export const LoggerContextProvider = (props: any) => {
  const { projectId, iModelId } = useBentleyContext();
  const { user } = useUserContext();
  const { buddiUrls } = useBuddiContext();

  const logger = useMemo(
    () =>
      new LogDispatcherClient({
        application: 'iTwin Federated Design Data Portal',
        gprId: 2996,
        url: buddiUrls.logDispatcherUrl,
        tokenProvider: async () => user!.access_token,
        intervalMs: 4000,
        retryCount: 2,
      }),
    [user]
  );

  const log = useCallback(
    (level: LogType, message: string, params?: any[]) => {
      if (logger != null) {
        logger.log(message, level, `${message}`, {
          ContextId: projectId,
          iModelId,
          UserId: user!.profile!.sub,
          ...params,
        });
      }
    },
    [logger, projectId, iModelId]
  );

  const logInformation = useCallback(
    (message: string, params: any) => {
      log('Info', message, params);
    },
    [log]
  );

  const logError = useCallback(
    (message: string, params: any) => {
      log('Error', message, params);
    },
    [log]
  );

  return (
    <LoggerContext.Provider value={{ logError, logInformation }} {...props} />
  );
};

export const useLogger = () => {
  const context = React.useContext(LoggerContext);
  if (context == null) {
    throw new Error('useLogger must be used inside provider');
  }
  return context;
};

export const utcTimeStringToFormattedDateTime = (time: string | null) => {
  return time != null ? getFormattedDateAndTime(new Date(time)) : '';
};

export const getFormattedDateAndTime = (dateTime: Date): string =>
  dateTime.toLocaleTimeString('en-GB', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });

export const getTotalTimeDifferenceOfFromStringValues = (
  startTime?: string,
  endTime?: string
): number | null => {
  if (!endTime || !startTime) {
    return null;
  }
  const startTimeDate = new Date(startTime);
  const endTimeDate = new Date(endTime);
  const timeDifferenceInSeconds =
    (endTimeDate.valueOf() - startTimeDate.valueOf()) / 1000;
  return timeDifferenceInSeconds ? timeDifferenceInSeconds : 1;
};

import { BridgeArg } from './jobDefintionDTO';

export enum ExecutionStrategy {
  Undetermined = 'Undetermined', // Do not run preprocessor, but use Affinity.json if exists
  NonAffinity = 'NonAffinity', // Do not run preprocessor, do not use Affinity.json
  ExistingAffinity = 'ExistingAffinity', // Do not run preprocessor, but use Affinity.json
  Affinity = 'Affinity', // Run preprocessor, use Affinity.json
}
export interface ConnectionDefinitionV2Dto {
  id: string;
  name: string;
  isScheduled: boolean;
  scheduleLoopIntervalInSeconds: number | null;
  priorityLevel: string;
  ownerId: string;
  gprId: number;
  inputFiles: InputFileV2Dto[];
  repositoryType: string;
  projectShareLocation?: ProjectShareLocationV2Dto;
  projectWiseLocation?: ProjectWiseLocationV2Dto;
  bridgeParameters?: BridgeArg;
  executionStrategy?: ExecutionStrategy;
}

export interface InputFileV2Dto {
  isSpatialRoot: boolean;
  lastAddedToSyncRunTimestamp?: string;
  iModelBridgeType: string;
  fileId: string;
}

export interface ProjectShareLocationV2Dto {
  projectId: string;
}

export interface ProjectWiseLocationV2Dto {
  fullDatasourceName: string;
  wsgProjectUrl: string;
}

export enum RepositoryTypeV2 {
  ProjectShare = 'ProjectShare',
  ProjectWiseWsg = 'ProjectWiseWsg',
  ProjectWiseLegacy = 'ProjectWiseLegacy',
  PW365 = 'ProjectWise365',
  Manifest = 'Manifest',
}

import { useDataApi } from '../useDataApi/useDataApi';
import { useCallback } from 'react';
import { RequestData } from '../useDataApi/requestData';
import { JobDefinition } from '../../entities/jobDefinition/jobDefinition';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useDeleteLocks = (
  iModelId: string,
  projectId: string
): [null, boolean, any, (jobDefinition: JobDefinition) => Promise<void>] => {
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = { initialData: null, initialIsLoading: false };
  const [data, isLoading, error, fetchData] = useDataApi(useDataSettings);

  const deleteLocks = useCallback(
    async (jobDefinition: JobDefinition) => {
      const briefcaseRequestData: RequestData<any> = {
        url: `${buddiUrls.productSettingsServiceUrl}/v1.0/Application/2661/Context/${projectId}/iModel/${iModelId}/Setting/DocumentMapping/Documents`,
        method: 'GET',
      };
      const briefcasesResponse: any = await fetchData(briefcaseRequestData);
      if (briefcasesResponse.ok) {
        const mapping = JSON.parse(briefcasesResponse.data);
        jobDefinition.files.forEach(file => {
          const document = mapping?.properties?.documents?.find(
            (doc: any) => doc.id.toLowerCase() === file.file.id.toLowerCase()
          );
          if (document != null && document.briefcaseId != null) {
            const url = `${buddiUrls.iModelHubUrl}/sv1.1/Repositories/iModel--${iModelId}/iModelScope/Lock/DeleteAll-${document.briefcaseId}`;
            const requestData: RequestData<any> = {
              url: url,
              method: 'DELETE',
            };
            fetchData(requestData);
          }
        });
      }
    },
    [iModelId, buddiUrls, fetchData]
  );

  return [data, isLoading, error, deleteLocks];
};

import React, { ReactNode } from 'react';
import cx from 'classnames';
import './navigatableCell.scss';
import { useTranslation } from 'react-i18next';
import { Body, Tooltip } from '@itwin/itwinui-react';
import { withConditionalWrapper } from '../../services/uiUtils/withConditionalWrapper';
import { TruncatedText } from '../truncatedText/TruncatedText';

export interface NavigatableCellProps {
  name: string;
  onNavigate?: (...args: any[]) => any;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  isItemDeleted?: boolean;
}

export const NavigatableCell = (props: NavigatableCellProps): JSX.Element => {
  const { name, onNavigate, leftIcon, rightIcon, isItemDeleted } = props;
  const { t } = useTranslation();
  const ConditionalTooltip = withConditionalWrapper(Tooltip);
  return (
    <div className={'cell-style'} role="gridcell">
      {leftIcon}
      <ConditionalTooltip
        content={t('DataSource_DeletedFile')}
        isWrapperDisplayed={!!isItemDeleted}
      >
        <Body
          isMuted={isItemDeleted}
          className={cx('navigatable-item-name', { itemLink: onNavigate })}
          title={isItemDeleted ? undefined : name}
          tabIndex={0}
          onClick={onNavigate}
        >
          {name}
        </Body>
      </ConditionalTooltip>
      {rightIcon}
    </div>
  );
};

export const NavigatableCellIUI = (
  props: NavigatableCellProps
): JSX.Element => {
  const { name, onNavigate, leftIcon, rightIcon, isItemDeleted } = props;
  const { t } = useTranslation();
  const ConditionalTooltip = withConditionalWrapper(Tooltip);
  return (
    <div className="icon-and-text-wrapper" role="gridcell">
      {leftIcon}
      <ConditionalTooltip
        content={t('DataSource_DeletedFile')}
        isWrapperDisplayed={!!isItemDeleted}
      >
        <TruncatedText
          isMuted={isItemDeleted}
          className={cx('navigatable-item-name', { itemLink: onNavigate })}
          title={isItemDeleted ? undefined : name}
          tabIndex={0}
          onClick={onNavigate}
        >
          {name}
        </TruncatedText>
      </ConditionalTooltip>
      {rightIcon}
    </div>
  );
};

import { Text } from '@itwin/itwinui-react';
import React from 'react';
import { TextLink } from '../textLink/textLink';
import './explanation.scss';

interface ExplanationProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
  linkUrl: string;
  linkText: string;
}

export const Explanation = (props: ExplanationProps) => {
  const { text, linkUrl, linkText } = props;

  return (
    <div className="explanation-div">
      <Text variant="body" as="p">
        {text} <TextLink url={linkUrl}>{linkText}</TextLink>
      </Text>
    </div>
  );
};

import cx from 'classnames';
import Select, { Props as ReactSelectProps } from 'react-select';
import { InputStatus } from '../../hooks/useInputField/useInputField';
import './Typeahead.scss';
import React from 'react';

/**
 * Prop Description
 */
export type TypeaheadProps<T> = {
  label?: string;
  message?: string;
  status?: InputStatus;
} & ReactSelectProps<T>;

export const Typeahead = <T extends {}>(props: TypeaheadProps<T>) => {
  const { label, message, status, ...rest } = props;
  return (
    <div className={cx('bwc-typeahead', status)}>
      <div className="bwc-typeahead-label">{label}</div>
      <Select classNamePrefix={cx(status, 'bwc-typeahead-select')} {...rest} />
      <div className={cx('bwc-typeahead-message', status)}>{message}</div>
    </div>
  );
};

export default Typeahead;

import { useCallback } from 'react';
import {
  IModelPermissionDto,
  IModelPermissionsDto,
} from '../../entities/rbacPermissions/rbacPermissions';
import { RequestData } from '../useDataApi/requestData';
import { useDataApi } from '../useDataApi/useDataApi';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
export const useGetIModelPermissions = (): [
  (currentIModel: string) => Promise<IModelPermissionsDto[] | null>
] => {
  const { buddiUrls } = useBuddiContext();
  const { ignoreImodelLevelPermissions } = useFeatureToggleContext();
  const useDataSettings = {
    initialIsLoading: true,
    initialData: [],
  };
  const [, , , fetchData] = useDataApi<IModelPermissionsDto[]>(useDataSettings);
  const getIModelPermissions = useCallback(
    async (currentIModel: string) => {
      const requestData: RequestData<IModelPermissionsDto[]> = {
        url: `${buddiUrls.rbacServiceUrl}/v2.5/Repositories/BentleyCONNECT--Main/RBAC/Object/${currentIModel}?$select=ObjectRole.*,ObjectRole-forward-Role.*`,
        method: 'GET',
        map: response =>
          response.instances
            ? response.instances[0].relationshipInstances.map(
                (instance: IModelPermissionDto) => {
                  return {
                    objectRoleId: instance.properties.ObjectRoleId,
                    relatedInstanceId: instance.relatedInstance.instanceId,
                    relatedInstanceName:
                      instance.relatedInstance.properties.Name,
                  };
                }
              )
            : null,
      };
      if (ignoreImodelLevelPermissions) {
        return null;
      }
      const result = await fetchData(requestData);
      return result.data;
    },
    [fetchData]
  );
  return [getIModelPermissions];
};

import React, { useEffect, useState } from 'react';
import { TimePicker } from '../../../timePicker/timePicker';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n/i18n';
import { ScheduleIntervalV2 } from '../jobScheduleSelectionv2/jobScheduleSelectionV2';
import { Radio, Select } from '@itwin/itwinui-react';

interface weeklyJobScheduleSelectionV2Props {
  initialScheduleInterval: string;
  initialStart?: Date | null;
  isSelected: boolean;
  setSchedule: (nextRun: string) => void;
  currentSchedule?: Date | null;
}
export enum WeeklySchedule {
  WeeklySunday = 0,
  WeeklyMonday = 1,
  WeeklyTuesday = 2,
  WeeklyWednesday = 3,
  WeeklyThursday = 4,
  WeeklyFriday = 5,
  WeeklySaturday = 6,
}
const WeeklyScheduleDisplayNames = [
  {
    value: WeeklySchedule.WeeklySunday,
    label: i18n.t('WeeklySelectDropDown_Sunday'),
  },
  {
    value: WeeklySchedule.WeeklyMonday,
    label: i18n.t('WeeklySelectDropDown_Monday'),
  },
  {
    value: WeeklySchedule.WeeklyTuesday,
    label: i18n.t('WeeklySelectDropDown_Tuesday'),
  },
  {
    value: WeeklySchedule.WeeklyWednesday,
    label: i18n.t('WeeklySelectDropDown_Wednesday'),
  },
  {
    value: WeeklySchedule.WeeklyThursday,
    label: i18n.t('WeeklySelectDropDown_Thursday'),
  },
  {
    value: WeeklySchedule.WeeklyFriday,
    label: i18n.t('WeeklySelectDropDown_Friday'),
  },
  {
    value: WeeklySchedule.WeeklySaturday,
    label: i18n.t('WeeklySelectDropDown_Saturday'),
  },
];

export const WeeklyJobScheduleSelectionV2 = (
  props: weeklyJobScheduleSelectionV2Props
) => {
  const {
    isSelected,
    setSchedule,
    currentSchedule,
    initialScheduleInterval,
  } = props;

  const { t } = useTranslation();
  const [hourIn24HrFormat, setHourIn24HrFormat] = useState('0');
  const [weekDay, setWeekDay] = useState<number>(WeeklySchedule.WeeklyMonday);

  useEffect(() => {
    if (!isSelected) {
      return;
    }
    if (
      currentSchedule &&
      Number.parseInt(initialScheduleInterval) === ScheduleIntervalV2.Weekly
    ) {
      setHourIn24HrFormat(currentSchedule.getHours().toString());
      setWeekDay(currentSchedule.getDay());
    }
  }, [isSelected, currentSchedule]);

  const onTimeChanged = (timeIn24HrFormat: string) => {
    setHourIn24HrFormat(timeIn24HrFormat);
    setSchedule(getNextDate(new Date(), weekDay, parseInt(timeIn24HrFormat)));
  };

  return (
    <div className="schedule-option">
      <Radio
        label={t('JobScheduleWeekly_Label')}
        name="schedule-sync"
        onChange={() => {
          setSchedule(
            getNextDate(new Date(), weekDay, parseInt(hourIn24HrFormat))
          );
        }}
        checked={isSelected}
      />
        <div className="day-time-picker">
          <Select<WeeklySchedule>
            data-testid="weekday-select"
            className="time-select-long"
            options={WeeklyScheduleDisplayNames}
            value={weekDay}
            onChange={value => {
              setWeekDay(value);
              setSchedule(
                getNextDate(new Date(), value, parseInt(hourIn24HrFormat))
              );
            }}
            disabled={!isSelected}
          />
          <TimePicker
            hourIn24HrFormat={hourIn24HrFormat}
            disable={!isSelected}
            onTimeChanged={onTimeChanged}
          />
        </div>
    </div>
  );
};

export const getNextDate = (
  now: Date,
  weekDay: number,
  hour: number
): string => {
  const start = new Date(now);

  let daysOffset = (weekDay - now.getDay() + 7) % 7;
  if (daysOffset === 0 && hour <= start.getHours()) {
    daysOffset += 7;
  }

  start.setDate(now.getDate() + daysOffset);
  start.setHours(hour);
  start.setMinutes(0);

  return start.toISOString();
};

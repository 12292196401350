import { assertUnreachable } from '../services/uiUtils/assertUnreachable';
import { BridgeType } from './bridge';

export interface AffinityReport {
  Results: AffinityReportEntry[];
}

export interface AffinityReportEntry {
  Affinity: AffinityEntry;
  Details: AffinityDetails;
}

export interface AffinityEntry {
  AuthoringProductStamp: ProductStamp;
  File: string;
  FileId: string;
  FileType: AffinityFileType;
  MasterAffinity: string;
  MasterId: string;
}

export interface AffinityDetails {
  ConfigurationVersion: string;
  ErrorMessage: string;
  ExistingStampInFile: ProductStamp;
  FilePath: string;
  Processed: boolean;
  ReStamp: boolean;
  RuleType: string;
  Status: string;
  TimeStamp: string;
}

export enum ProductStamp {
  None = '',
  MSTN = 'iModelBridgeForMstn',
  ORD = 'OpenRoadsDesigner',
  OBD = 'OpenBuildingsDesigner',
  CIVIL = 'Civil',
  DWG = 'RealDwgBridge',
  IFC = 'IfcBridge',
  REVIT = 'RevitBridge',
  CIVIL3D = 'C3dBridge',
  VUE = 'SPxReviewBridge',
  AVEVAPID = 'AvevaPIDBridge',
  SPPID = 'SPPIDBridge',
  AVEVADIAGRAMS = 'AvevaDiagramsBridge',
  GEOSPATIAL = 'GeoBridge',
  AUTOPLANT = 'AutoPLANTBridge',
  OPENTOWER = 'OPENTOWER',
  NWD = 'NwdBridge',
  SHELLDWCSV = 'ShellEDWCSVBridge',
  PSEXCEL = 'PSEXCEL',
  Prostructures = 'ProStructures',
  IntelliPid = 'INTELLIPID',
  OpenTowerLite = 'OPENTOWERLITE',
}

export enum AffinityFileType {
  None = '',
  SpatialRoot = 'SpatialRoot',
  Master = 'Master',
  Reference = 'Reference',
}

export const mapBridgeTypeToProductStamp = (
  bridge: BridgeType
): ProductStamp => {
  switch (bridge) {
    case BridgeType.AffinityChecker:
      return ProductStamp.None;
    case BridgeType.Preprocessor:
      return ProductStamp.None;
    case BridgeType.Microstation:
      return ProductStamp.MSTN;
    case BridgeType.OBD:
      return ProductStamp.OBD;
    case BridgeType.Civil:
      return ProductStamp.CIVIL;
    case BridgeType.Dwg:
      return ProductStamp.DWG;
    case BridgeType.Revit:
      return ProductStamp.REVIT;
    case BridgeType.Ifc:
      return ProductStamp.IFC;
    case BridgeType.AutoPlant:
      return ProductStamp.AUTOPLANT;
    case BridgeType.AvevaDiagrams:
      return ProductStamp.AVEVADIAGRAMS;
    case BridgeType.AvevaPid:
      return ProductStamp.AVEVAPID;
    case BridgeType.Civil3D:
      return ProductStamp.CIVIL3D;
    case BridgeType.Geospatial:
      return ProductStamp.GEOSPATIAL;
    case BridgeType.OpenTower:
      return ProductStamp.OPENTOWER;
    case BridgeType.SPPID:
      return ProductStamp.SPPID;
    case BridgeType.Vue:
      return ProductStamp.VUE;
    case BridgeType.Nwd:
      return ProductStamp.NWD;
    case BridgeType.ShellDWCSV:
      return ProductStamp.SHELLDWCSV;
    case BridgeType.PSEXCEL:
      return ProductStamp.PSEXCEL;
    case BridgeType.Prostructures:
      return ProductStamp.Prostructures;
    case BridgeType.IntelliPid:
      return ProductStamp.IntelliPid;
    case BridgeType.OpenTowerLite:
      return ProductStamp.OpenTowerLite;
    default:
      assertUnreachable(bridge);
      return ProductStamp.None;
  }
};

export const mapProductStampToBridgeType = (
  productStamp: ProductStamp
): BridgeType => {
  switch (productStamp) {
    case ProductStamp.None:
    case ProductStamp.MSTN:
      return BridgeType.Microstation;
    case ProductStamp.OBD:
      return BridgeType.OBD;
    case ProductStamp.ORD:
    case ProductStamp.CIVIL:
      return BridgeType.Civil;
    case ProductStamp.DWG:
      return BridgeType.Dwg;
    case ProductStamp.REVIT:
      return BridgeType.Revit;
    case ProductStamp.IFC:
      return BridgeType.Ifc;
    case ProductStamp.CIVIL3D:
      return BridgeType.Civil3D;
    case ProductStamp.VUE:
      return BridgeType.Vue;
    case ProductStamp.AVEVAPID:
      return BridgeType.AvevaPid;
    case ProductStamp.SPPID:
      return BridgeType.SPPID;
    case ProductStamp.AVEVADIAGRAMS:
      return BridgeType.AvevaDiagrams;
    case ProductStamp.GEOSPATIAL:
      return BridgeType.Geospatial;
    case ProductStamp.AUTOPLANT:
      return BridgeType.AutoPlant;
    case ProductStamp.OPENTOWER:
      return BridgeType.OpenTower;
    case ProductStamp.NWD:
      return BridgeType.Nwd;
    case ProductStamp.SHELLDWCSV:
      return BridgeType.ShellDWCSV;
    case ProductStamp.PSEXCEL:
      return BridgeType.PSEXCEL;
    case ProductStamp.Prostructures:
      return BridgeType.Prostructures;
    default:
      assertUnreachable(productStamp as never);
      return BridgeType.Microstation;
  }
};

import React, { useCallback } from 'react';
import { doFetch } from '../../hooks/useDataApi/useDataApi';
import { useBentleyContext } from '../bentleyContext/bentleyContext';
import { useUserContext } from '../userContext/userContext';

export interface IUsageLoggerContext {
  logFeature: (featureName: string, properties?: PropertyInfo[]) => void;
  logEvent: (eventName: string, properties?: PropertyInfo[]) => void;
  logUsage: () => void;
}

interface PropertyInfo {
  name: string;
  value: string;
}

interface Feature {
  projectId: string;
  name: string;
  userEmail: string;
  properties?: PropertyInfo[];
}

interface Event {
  projectId: string;
  name: string;
  userEmail: string;
  properties?: PropertyInfo[];
}

export const UsageLoggerContext = React.createContext<IUsageLoggerContext>({
  logFeature: () => {},
  logEvent: () => {},
  logUsage: () => {},
});

export const UsageLoggerContextProvider = (props: any) => {
  const { projectId } = useBentleyContext();
  const { user } = useUserContext();

  const logFeature = useCallback(
    (featureName: string, properties?: PropertyInfo[]) => {
      const feature = {
        projectId: projectId,
        name: featureName,
        userEmail: user?.profile.email,
        properties: properties,
      } as Feature;

      return doFetch(
        '/api/usage/logFeature',
        user!,
        'POST',
        JSON.stringify(feature)
      );
    },
    [projectId, user]
  );

  const logEvent = useCallback(
    (eventName: string, properties?: PropertyInfo[]) => {
      const event = {
        projectId: projectId,
        name: eventName,
        userEmail: user?.profile.email,
        properties: properties,
      } as Event;

      return doFetch(
        '/api/usage/logEvent',
        user!,
        'POST',
        JSON.stringify(event)
      );
    },
    [projectId, user]
  );

  const logUsage = useCallback(() => {
    doFetch(
      '/api/usage/logUsage',
      user!,
      'POST',
      JSON.stringify({ projectId: projectId })
    );
  }, [projectId, user]);

  return (
    <UsageLoggerContext.Provider value={{ logFeature, logEvent, logUsage }} {...props} />
  );
};

export const useUsageLogger = () => {
  const context = React.useContext(UsageLoggerContext);
  if (context == null) {
    throw new Error('useUsageLogger must be used inside provider');
  }
  return context;
};

import * as React from 'react';
export const SvgLogicalSet = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <g transform="translate(-53.920306,13.615225)" />
    <g style={{ display: 'none' }}>
      <g
        transform="matrix(3.9999999,0,0,4,2e-7,-192)"
        style={{ display: 'inline' }}
      >
        <path
          d="M 2,64 14,64 14,50.465143 11.564088,48 2,48"
          style={{ fill: '#00aeff', fillOpacity: 1 }}
        />
        <polygon
          points="13.313,3.157 11.156,3.157 11.156,1"
          transform="matrix(1.1293055,0,0,1.1428571,-1.0344438,46.857143)"
          style={{ opacity: 0.3 }}
        />
      </g>
      <path
        d="m 28.967356,19.301335 -5.105472,0.04404 -2.068252,30.323476 6.029447,-0.440275 0.125841,-6.022158 9.876545,-0.56928 3.156726,5.491229 5.28139,-0.528076 -17.296225,-28.298976 z m -0.660254,6.777504 6.744223,11.732415 -6.996218,0.317924 0.251995,-12.050339 z"
        style={{
          display: 'inline',
          fill: '#ffffff',
          fillRule: 'evenodd',
          stroke: 'none',
          strokeWidth: '0.1861151px',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
        }}
      />
    </g>
    <g style={{ display: 'inline' }}>
      <g>
        <g
          transform="matrix(1,0,0,1,-0.8,-28)"
          style={{ opacity: 1, fill: '#ffffff' }}
        >
          <rect
            y="30"
            x="12"
            height="3"
            width="10"
            style={{
              opacity: 1,
              fill: '#ffffff',
              fillOpacity: 1,
              stroke: 'none',
              strokeWidth: 5,
              strokeMiterlimit: 4,
              strokeDasharray: 'none',
            }}
          />
          <rect
            y="-18.666666"
            x="32"
            height="3"
            width="13"
            style={{
              opacity: 1,
              fill: '#ffffff',
              fillOpacity: 1,
              stroke: 'none',
              strokeWidth: 5,
              strokeMiterlimit: 4,
              strokeDasharray: 'none',
            }}
            transform="matrix(0,1,-1,0,0,0)"
          />
          <rect
            y="36"
            x="18.666666"
            height="3"
            width="10"
            style={{
              opacity: 1,
              fill: '#ffffff',
              fillOpacity: 1,
              stroke: 'none',
              strokeWidth: 5,
              strokeMiterlimit: 4,
              strokeDasharray: 'none',
            }}
          />
          <rect
            y="42"
            x="18.666666"
            height="3"
            width="10"
            style={{
              opacity: 1,
              fill: '#ffffff',
              fillOpacity: 1,
              stroke: 'none',
              strokeWidth: 10,
              strokeMiterlimit: 10,
              strokeDasharray: 'none',
            }}
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgLogicalSet;

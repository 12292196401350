import { useDataApi } from '../../hooks/useDataApi/useDataApi';
import { RequestData, ResponseData } from '../../hooks/useDataApi/requestData';
import { useCallback } from 'react';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

interface DiagnosticsDto {
  ContextID: string;
  CreatedAt: string;
  ID: string;
  IModelID: string;
  IsUploaded: boolean;
  JobID: string;
  SASUri: string;
}

interface Diagnostics {
  contextId: string;
  createdAt: string;
  id: string;
  iModelId: string;
  isUploaded: boolean;
  jobId: string;
  SASUri: string;
}
export const useBadgers = (
  projectId: string,
  iModelId: string
): [
  Diagnostics,
  boolean,
  any,
  (activityId: string) => Promise<ResponseData<Diagnostics> | null>
] => {
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = {
    initialData: {} as Diagnostics,
    initialIsLoading: false,
  };
  const [result, isLoading, error, fetchData] =
    useDataApi<any>(useDataSettings);

  const baseUrl = `${buddiUrls.badgersUrl}/v2.0/context/${projectId}/imodel/${iModelId}/diagnosticrecords`;

  const getDiagnostics = useCallback(
    async (activityId: string) => {
      const url = `${baseUrl}/${activityId}/reports`;
      const take = '?pageNumber=1&pageSize=20';
      const requestData: RequestData<any> = {
        url: `${url}${take}`,
        map: x => x,
        method: 'GET',
      };

      const response = await fetchData(requestData);

      if (!response.ok || response.data.length === 0) {
        return null;
      }
      const filteredReports = response.data.filter((report: any) =>
        ['NORMAL_UPDATE', 'UNMAP_INPUT_FILE'].includes(
          report.Properties.operationType
        )
      );
      if (filteredReports.length === 0) {
        return null;
      }

      return fetchData({
        url: `${url}/${filteredReports[filteredReports.length - 1].ID}${take}`,
        map: mapDiagnosticsDtoToDiagnostics,
        method: 'GET',
      });
    },
    [baseUrl, fetchData]
  );

  return [result, isLoading, error, getDiagnostics];
};

const mapDiagnosticsDtoToDiagnostics = (
  response: DiagnosticsDto
): Diagnostics => {
  return {
    contextId: response.ContextID,
    createdAt: response.CreatedAt,
    id: response.ID,
    iModelId: response.IModelID,
    isUploaded: response.IsUploaded,
    jobId: response.JobID,
    SASUri: response.SASUri,
  };
};

import { useCallback, useState } from 'react';
import { useBadgers } from './useBadgers';
import { ReportData } from '@itwin/synchronization-report-react-dev';

export const useBadgersReport = (
  projectId: string,
  iModelId: string
): [
  ReportData | null,
  boolean,
  any,
  (activityId: string) => Promise<ReportData | null>
] => {
  const [, , error, getDiagnostics] = useBadgers(projectId, iModelId);
  const [report, setReport] = useState<ReportData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getReport = useCallback(
    async (activityId: string) => {
      setIsLoading(true);

      const response = await getDiagnostics(activityId);
      if (response != null && response.ok && response.data != null) {
        const reponse = await fetch(response.data?.SASUri);
        const reportResponse = (await reponse.json()) as ReportData;

        if (reportResponse.sourceFilesInfo?.dataSource === 'ProjectShare') {
          reportResponse.sourceFilesInfo.dataSource = 'iTwin File Service';
        }

        setReport(reportResponse);
        setIsLoading(false);

        return reportResponse;
      } else {
        setReport(null);
        setIsLoading(false);

        return null;
      }
    },
    [getDiagnostics]
  );

  return [report, isLoading, error, getReport];
};

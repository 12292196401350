import { useCallback } from 'react';
import {
  IModelRoles,
  IModelRolesDTO,
  IModelRolesRelationshipInstancesDTO,
} from '../../entities/rbacPermissions/rbacPermissions';
import { RequestData } from '../useDataApi/requestData';
import { useDataApi } from '../useDataApi/useDataApi';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';
export const useGetIModelRoles = (): [
  (objectTypeId: string) => Promise<IModelRoles[] | null>
] => {
  const { buddiUrls } = useBuddiContext();
  const useDataSettings = {
    initialIsLoading: true,
    initialData: [],
  };
  const [, , , fetchData] = useDataApi<IModelRoles[]>(useDataSettings);
  const getIModelRoles = useCallback(
    async (objectTypeId: string) => {
      const requestData: RequestData<IModelRoles[]> = {
        url: `${buddiUrls.rbacServiceUrl}/v2.5/Repositories/BentleyCONNECT--Main/RBAC/ObjectType/${objectTypeId}/Role?$select=*,Permission.*`,
        method: 'GET',
        map: response =>
          response.instances.map((instance: IModelRolesDTO) => {
            return {
              instanceId: instance.instanceId,
              relationshipInstances: instance.relationshipInstances.map(
                (relInstance: IModelRolesRelationshipInstancesDTO) =>
                  relInstance.relatedInstance.instanceId
              ),
            };
          }),
      };
      const result = await fetchData(requestData);
      return result.data;
    },
    [fetchData]
  );
  return [getIModelRoles];
};

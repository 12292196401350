import React from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-text-to-clipboard';
import { useToast } from '../../context/toastContext/toastContext';
import { IconButton, Tooltip } from '@itwin/itwinui-react';
import { SvgCopy } from '@itwin/itwinui-icons-react/cjs';

export const CopyToClipboardButton = (props: {
  value: string;
  options?: {
    errorMessage?: string;
    successMessage?: string;
    tooltipText?: string;
  };
}) => {
  const { value, options } = props;
  const { toastError, toastSuccess } = useToast();
  const { t } = useTranslation();

  const errorMessage = t(
    options?.errorMessage ?? 'CopyToClipboardFailure_Toast'
  );
  const successMessage = t(
    options?.successMessage ?? 'CopyToClipboardSuccess_Toast'
  );
  const tooltipText = t(options?.tooltipText ?? 'CopyToClipboard_Tooltip');

  const copyToClipboard = () => {
    const copyToClipboardSuccessful = copy(value);
    if (!copyToClipboardSuccessful) {
      toastError(errorMessage);
      return;
    }
    toastSuccess(successMessage);
  };

  return (
    <Tooltip content={tooltipText}>
      <IconButton
        onClick={() => {
          copyToClipboard();
        }}
        data-testid="copy-to-clipboard-button"
      >
        <SvgCopy />
      </IconButton>
    </Tooltip>
  );
};

import React, { useState, useEffect } from 'react';
import CONNECTHeader from '../headerFooter/CONNECTHeader';
import CONNECTFooter from '../headerFooter/CONNECTFooter';
import './layout.scss';
import { SecondaryHeader } from '../navigatableHeader/navigatableHeader';
import GenericErrorBoundary from '../errorBoundary/genericErrorBoundary';

const Layout: React.FunctionComponent<{
  children: JSX.Element | JSX.Element[];
  projectId?: string;
  isIFramed?: boolean;
}> = ({ children, projectId, isIFramed }) => {
  const [hideSecondaryHeader, setHideSecondaryHeader] = useState(false);

  useEffect(() => {
    if (!isIFramed) return;
    if (isIFramed && hideSecondaryHeader) return;
    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      const secondaryHeaderParam = urlParams.get('hide-secondary-header');
      if (
        secondaryHeaderParam &&
        secondaryHeaderParam.toString().toLowerCase() === 'true'
      ) {
        setHideSecondaryHeader(true);
      }
    }
  }, [isIFramed]);

  return (
    <div id="styled-layout">
      {!isIFramed ? <CONNECTHeader projectId={projectId} /> : <></>}
      {!(isIFramed && hideSecondaryHeader) ? (
        <SecondaryHeader isIFramed={isIFramed} />
      ) : (
        <></>
      )}
      <div id="connect-main" className="connect-main">
        <div id="modal-root" />
        <GenericErrorBoundary>{children}</GenericErrorBoundary>
      </div>
      {!isIFramed ? <CONNECTFooter /> : <></>}
    </div>
  );
};

export default Layout;

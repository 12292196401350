import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigatableCellIUI } from '../navigatableCell/navigatableCell';
import { getItemIcon } from '../itemIcon/itemIcon';
import {
  getColumnHeaderIUI,
  getTextColumnIUI,
} from '../../services/uiUtils/tableColumnWrappers';
import './jobDefinitionFilesTable.scss';
import './bridgeAssignmentTable.scss';
import { FlexTableWrapper } from '../flexTableWrapper/flexTableWrapper';
import { BridgeType } from '../../entities/bridge';
import { MasterContext } from '../../hooks/useGetMasterReferencesTree/useGetMasterReferencesTree';
import { BridgeSelection } from '../../hooks/useBridgeSelection/useBridgeSelection';
import { SpatialRootIcon } from '../spatialRootIcon/spatialRootIcon';
import { SvgDeletePendingIcon } from '../icons/SvgDeletePending';
import {
  JobDefinitionFile,
  LastRunDetails,
} from '../../entities/jobDefinition/jobDefinition';
import { Repository, RepositoryType } from '../../entities/repository';
import {
  IconButton,
  Select,
  Tooltip,
  Table,
  SelectionColumn,
} from '@itwin/itwinui-react';
import { Icon } from '../icons/icon';
import { SvgChevronRight, SvgUnlink } from '@itwin/itwinui-icons-react';
import { CellProps, Column, TableInstance } from 'react-table';
import { useCallback } from 'react';
import { Item } from '../../entities/item';
import { useEffect } from 'react';

export interface BridgeAssignementTableProps {
  spatialRootId: string;
  editable: boolean;
  itemsToBeRemoved?: { [id: string]: string };
  mastersContext: MasterContext[];
  isTreeLoading: boolean;
  isFilterApplied: boolean;
  setBridgeSelection: (
    mastersConext: MasterContext,
    masterId: string,
    selectedId: string,
    bridgeSelection: BridgeSelection
  ) => void;
  setSelectedItems: (items: Item[]) => void;
  selectedRepository: Repository;
  onRemoveCompositeModelClick?: (mastersConext: MasterContext) => void;
  isAffinityUIEnabled: boolean;
  mastersContextError?: any;
  inputFiles?: JobDefinitionFile[];
  isV2Job?: boolean;
  lastRunDetails?: LastRunDetails | null;
  disableConnectorChanging?: boolean;
}

export type BridgeAssignmentTableDataType = {
  masterContext: MasterContext;
  masterId: string;
  item: Item;
  expanded: boolean;
  subRows: BridgeAssignmentTableDataType[];
};

export const BridgeAssignmentTable = (props: BridgeAssignementTableProps) => {
  const {
    spatialRootId,
    editable,
    itemsToBeRemoved,
    mastersContext,
    isTreeLoading,
    isFilterApplied,
    setBridgeSelection,
    setSelectedItems,
    onRemoveCompositeModelClick,
    isAffinityUIEnabled,
    mastersContextError: masterContextError,
    inputFiles,
    selectedRepository,
    isV2Job,
    lastRunDetails,
    disableConnectorChanging,
  } = props;

  const { t } = useTranslation();
  const [expandedMap, setExpandedMap] = useState<Record<string, boolean>>({});
  const previousExpandedMap = useRef<Record<string, boolean>>({});
  const tableInstance = useRef<
    TableInstance<BridgeAssignmentTableDataType> | undefined
  >(undefined);

  useEffect(() => {
    if (isFilterApplied) {
      setExpandedMap(prevState => {
        previousExpandedMap.current = prevState;
        const newState = { ...prevState };
        if (tableInstance.current) {
          tableInstance.current.flatRows.forEach(row => {
            newState[row.id] = true;
          });
        }
        return newState;
      });
    } else {
      setExpandedMap(previousExpandedMap.current);
    }
  }, [isFilterApplied]);

  const getCurrentConnectorForFile = (
    masterContext: MasterContext,
    fileId: string
  ) => {
    return (
      (disableConnectorChanging &&
        inputFiles?.find(inputFile => inputFile.file.id === fileId)
          ?.bridgeType) ||
      masterContext.mastersMap[fileId]?.bridgeSelection[fileId].selectedBridge
    );
  };

  const getSubRows = useCallback(
    (
      original: Omit<BridgeAssignmentTableDataType, 'subRows'>,
      expandedMapIndexPrefix: string
    ): BridgeAssignmentTableDataType[] => {
      if ('original' in original) {
        return []; // A workaround to [https://github.com/tannerlinsley/react-table/issues/2609] (still not solved as of 2021-07-02)
      }
      const masterId = original.item.id;
      const masterContext = original.masterContext;

      return (
        masterContext.mastersMap[masterId]?.masterItem.references?.map(
          (item, index) => {
            const rowData = {
              item: item,
              masterId: masterId,
              masterContext: masterContext,
              expanded: expandedMap[`${expandedMapIndexPrefix}.${index}`],
            };

            return {
              ...rowData,
              subRows: getSubRows(
                rowData,
                `${expandedMapIndexPrefix}.${index}`
              ),
            };
          }
        ) ?? []
      );
    },
    [expandedMap]
  );

  const inputFileIds = inputFiles?.map(y => y.file.id);
  const isFileMapped = (tableRow: BridgeAssignmentTableDataType) => {
    return inputFileIds?.includes(tableRow.item.id);
  };

  const isCheckboxDisabled = useCallback(
    (rowData: BridgeAssignmentTableDataType) => {
      return (
        (disableConnectorChanging && isFileMapped(rowData)) ||
        rowData.masterId !== rowData.item.id
      );
    },
    []
  );

  const compositeModelsColumns: Column<BridgeAssignmentTableDataType>[] = [
    SelectionColumn({ isDisabled: isCheckboxDisabled }),
    {
      ...getColumnHeaderIUI<any, 'name'>(
        t('JobDefinitionTableHeader_CompositeModel'),
        'name'
      ),
      Cell: ({
        row: { original },
      }: CellProps<BridgeAssignmentTableDataType>) => {
        return (
          <NavigatableCellIUI
            name={original.item.name}
            leftIcon={getItemIcon(original.item)}
            isItemDeleted={original.item.isDeleted}
          />
        );
      },
      minWidth: 150,
    },
  ];

  if (
    (selectedRepository?.type === RepositoryType.PWDI ||
      selectedRepository?.type === RepositoryType.PWDI_LEGACY) &&
    isV2Job
  ) {
    compositeModelsColumns.push({
      ...getTextColumnIUI<any, 'version'>(
        t('VersionColumnHeader_Label'),
        'version'
      ),
      Cell: ({
        row: { original },
      }: CellProps<BridgeAssignmentTableDataType>) => (
        <NavigatableCellIUI name={original.item.version} />
      ),
      minWidth: 150,
    });
  }
  if (isAffinityUIEnabled) {
    compositeModelsColumns.push({
      ...getColumnHeaderIUI<any, 'connector'>(
        t('Processed_With_Column'),
        'connector'
      ),
      Cell: ({
        row: { original },
        row,
      }: CellProps<BridgeAssignmentTableDataType>) => (
        <Select<BridgeType>
          value={
            row.depth !== 0
              ? getCurrentConnectorForFile(
                  original.masterContext,
                  original.masterContext.master.id
                )
              : getCurrentConnectorForFile(
                  original.masterContext,
                  original.item.id
                )
          }
          data-testid="process-with-selector"
          disabled={
            original.item.isDeleted ||
            row.depth !== 0 ||
            (disableConnectorChanging && isFileMapped(original))
          }
          placeholder={
            row.depth === 0 && original.item.isDeleted
              ? original.masterContext.mastersMap[original.masterId]
                  ?.bridgeSelection[original.item.id].selectedBridge
              : ''
          }
          onChange={value => {
            setBridgeSelection(
              original.masterContext,
              original.masterId,
              original.item.id,
              {
                ...original.masterContext.mastersMap[original.masterId]
                  .bridgeSelection[original.item.id],
                selectedBridge: value,
              }
            );
          }}
          options={Object.entries(
            (original.masterContext.mastersMap[original.masterId] &&
              original.masterContext.mastersMap[original.masterId]
                .bridgeSelection[original.item.id].bridges) ??
              {}
          ).map(([value, label]: [any, any]) => ({ value, label }))}
          onShow={undefined}
          onHide={undefined}
          menuClassName={'connector-selector-menu'}
        />
      ),
      minWidth: 150,
    });
  }

  compositeModelsColumns.push({
    id: 'spatial-icon',
    columnClassName: 'iui-slot',
    cellClassName: 'iui-slot',
    Cell: ({
      row: { original },
      row,
    }: CellProps<BridgeAssignmentTableDataType>) =>
      original.item.id === spatialRootId && row.depth === 0 ? (
        <SpatialRootIcon
          toolTipText={t('SpatialIcon_BridgAssigment_Tooltip')}
        />
      ) : null,
    minWidth: 40,
    maxWidth: 40,
  });

  if (editable) {
    compositeModelsColumns.push({
      id: 'change-mappings-actions',
      columnClassName: 'iui-slot',
      cellClassName: 'iui-slot',
      Cell: ({
        row: { original },
        row,
      }: CellProps<BridgeAssignmentTableDataType>) => {
        if (row.depth > 0) {
          return null;
        }

        return itemsToBeRemoved &&
          itemsToBeRemoved[original.item.id] != null ? (
          <Tooltip content={t('DeleteFilePending_Title')}>
            <div>
              <Icon
                icon={SvgDeletePendingIcon}
                color="default"
                data-testId="svg-delete-pending"
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip content={t('UnmapFile_Tooltip')}>
            <div>
              <IconButton
                onClick={() =>
                  onRemoveCompositeModelClick!(original.masterContext)
                }
                styleType="borderless"
                size="small"
                disabled={
                  (isV2Job &&
                    original.item.id !== spatialRootId &&
                    !isFileMapped(original)) ||
                  (isV2Job &&
                    original.item.id === spatialRootId &&
                    !lastRunDetails)
                }
              >
                <SvgUnlink data-testid="svg-unmap-icon" />
              </IconButton>
            </div>
          </Tooltip>
        );
      },
      maxWidth: 64,
      minWidth: 64,
    });
  }

  const columns = useMemo(
    (): Column<BridgeAssignmentTableDataType>[] => [...compositeModelsColumns],
    [isFileMapped]
  );
  const data = useMemo<BridgeAssignmentTableDataType[]>(
    () =>
      mastersContext &&
      mastersContext.map((mc, index) => {
        const rowData = {
          item: mc.master,
          masterId: mc.master.id,
          masterContext: mc,
          expanded: expandedMap[index],
        };

        return {
          ...rowData,
          subRows: getSubRows(rowData, index.toString()),
        };
      }),
    [expandedMap, getSubRows, mastersContext]
  );

  return (
    <div className={'job-files-table'}>
      <FlexTableWrapper>
        <Table<BridgeAssignmentTableDataType>
          className="bridge-assignment-table iui-condensed"
          data={!masterContextError ? data : []}
          columns={columns}
          isLoading={isTreeLoading}
          emptyTableContent={
            isTreeLoading
              ? ''
              : editable
              ? t('NoFilesFound_Label')
              : masterContextError != null
              ? t('NoFilesFound_Label')
              : t(
                  isAffinityUIEnabled
                    ? 'FailedToLoadReferences'
                    : 'FailedToLoadReferencesCanSkip'
                )
          }
          autoResetPage={false}
          autoResetHiddenColumns={false}
          autoResetSortBy={false}
          autoResetExpanded={false}
          autoResetSelectedRows={false}
          stateReducer={(newState, _, __, instance) => {
            if (instance) {
              tableInstance.current = instance;
            }

            return newState;
          }}
          expanderCell={(
            cellProps: CellProps<BridgeAssignmentTableDataType>
          ) => (
            <IconButton
              style={{ marginRight: 8 }}
              data-testid="expander-button"
              className="iui-row-expander"
              styleType="borderless"
              size="small"
              onClick={e => {
                e.stopPropagation();
                cellProps.row.toggleRowExpanded();
              }}
              disabled={false}
            >
              {
                <SvgChevronRight
                  style={{
                    transform: cellProps.row.isExpanded
                      ? 'rotate(90deg)'
                      : undefined,
                  }}
                />
              }
            </IconButton>
          )}
          isRowDisabled={(rowData: BridgeAssignmentTableDataType) =>
            !rowData.masterContext.mastersMap[rowData.masterId].bridgeSelection[
              rowData.item.id
            ]?.selectable
          }
          selectRowOnClick={false}
          selectSubRows={false}
          isSelectable={isAffinityUIEnabled}
          onSelect={(
            selectedData: BridgeAssignmentTableDataType[] | undefined,
            _
          ) => {
            setSelectedItems(
              selectedData ? selectedData.map(sd => sd.item) : []
            );
          }}
          density="condensed"
        />
      </FlexTableWrapper>
    </div>
  );
};

export const iModelHubAppId = 2661;
export const iTwinBridgePortalAppId = 2996;

export const jobStatusUpdateIntervalInSeconds = 10;

export const maxCharactersAllowedInModelName = 100;

export const maxCharactersAllowedInJobName = 100;

export const maxCharactersAllowedInDescription = 100;

export const countOfJobStatusesToBeFetched = 30;

export const pageSizeForJobStatusTable = 11;

export const embeddedFileBasenameBridgeArgs =
  'iModelBridge_MatchOnEmbeddedFileBasename';
export const fileBasenameBridgeArgs = 'iModelBridge_MatchOnFileBasename';

export const embeddedFileBasenameRegexV2 = 'embeddedFileBasenameRegex';
export const fileBasenameRegexV2 = 'fileBasenameRegex';

export const oneDay = 60 * 60 * 24 * 1000;

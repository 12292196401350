import React, { createContext, ProviderProps, useContext } from 'react';
import { User } from 'oidc-client';

export interface IUserContext {
  user: User;
}

export const UserContext = createContext<IUserContext>({
  user: {} as User,
});

export interface UserContextProviderProps
  extends Omit<ProviderProps<IUserContext>, 'value'> {
  user: User;
}

export const UserContextProvider = ({ ...props }: UserContextProviderProps) => {
  const { user } = props;

  return (
    <UserContext.Provider
      value={{
        user,
      }}
      {...props}
    />
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context == null) {
    throw new Error('useUserContext must be used inside provider');
  }
  return context;
};

import { useState } from 'react';
import {
  maxCharactersAllowedInModelName,
  maxCharactersAllowedInDescription,
} from '../../externalComponents/constants/constants';
import {
  useInputField,
  useOptionalInputField,
} from '../../externalComponents/hooks/useInputField/useInputField';
import { useTranslation } from 'react-i18next';
import { iModel } from '../../externalComponents/entities/iModel';
import { maxCharactersAllowedInJobName } from '../../externalComponents/constants/constants';
import { ModalProps } from '../../externalComponents/hooks/useModal/useModal';
import {
  Button,
  LabeledInput,
  LabeledTextarea,
  Modal,
  ModalButtonBar,
} from '@itwin/itwinui-react';

type CreateNewIModelModalProps = ModalProps & {
  iModels: iModel[];
  onCreateIModel: (name: string, description: string) => any;
};

export const CreateNewIModelModal = (props: CreateNewIModelModalProps) => {
  const { iModels, onCreateIModel, onClose, ...modalProps } = props;
  const [iModelName, setIModelName] = useState('');
  const [iModelDescription, setIModelDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const existingIModelNames = iModels.map(iModel => iModel.displayName);
  const [
    nameInputStatus,
    nameInputErrorMessage,
    isNameInputInvalid,
    setNameInputFieldState,
    invalidateInputFieldState,
  ] = useInputField();
  const [
    descriptionStatus,
    descriptionErrorMessage,
    isDescriptionInvalid,
    setDescriptionFieldState,
    invalidateDescriptionFieldState,
  ] = useOptionalInputField();

  const onConfirm = async () => {
    setIsLoading(true);
    await onCreateIModel(iModelName, iModelDescription);
    setIsLoading(false);
  };

  const close = () => {
    setIModelName('');
    setIModelDescription('');
    invalidateInputFieldState();
    invalidateDescriptionFieldState();
    onClose();
  };

  const validateName = (modelName: string) => {
    if (!modelName.trim()) {
      setNameInputFieldState('negative', t('EmptyField_ErrorMessage'));
    } else if (existingIModelNames.indexOf(modelName) !== -1) {
      setNameInputFieldState('negative', t('ExistingIModelName_ErrorMessage'));
    } else if (modelName.length > maxCharactersAllowedInModelName) {
      setNameInputFieldState(
        'negative',
        t('IModelNameExceedsMaximumCharacters_ErrorMessage', {
          maxCharactersAllowedInModelName: maxCharactersAllowedInModelName,
        })
      );
    } else {
      setNameInputFieldState(undefined, '');
    }
  };

  const validateDescription = (description: string) => {
    if (description.length > maxCharactersAllowedInDescription) {
      setDescriptionFieldState(
        'negative',
        t('JobNameExceedsMaximumCharacters_ErrorMessage', {
          maxCharactersAllowedInJobName: maxCharactersAllowedInJobName,
        })
      );
    } else {
      setDescriptionFieldState(undefined, '');
    }
  };

  return (
    <Modal {...modalProps} title={t('CreateIModelModal_Title')} onClose={close}>
      <LabeledInput
        label={t('IModelNameInputField_Label')}
        value={iModelName}
        onChange={(e: any) => {
          validateName(e.target.value);
          setIModelName(e.target.value);
        }}
        required
        disabled={isLoading}
        status={nameInputStatus}
        message={nameInputErrorMessage}
      />

      <LabeledTextarea
        label={t('DescriptionTextArea_Label')}
        value={iModelDescription}
        onChange={(e: any) => {
          validateDescription(e.target.value);
          setIModelDescription(e.target.value);
        }}
        style={{ resize: 'none' }}
        disabled={isLoading}
        status={descriptionStatus}
        message={descriptionErrorMessage}
      />
      <ModalButtonBar>
        <Button
          styleType="high-visibility"
          onClick={onConfirm}
          disabled={isNameInputInvalid || isDescriptionInvalid}
        >
          {t('CreateBtn_Label')}
        </Button>
        <Button onClick={close}>{t('CancelBtn_Label')}</Button>
      </ModalButtonBar>
    </Modal>
  );
};

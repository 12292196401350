import { RouteComponentProps } from '@reach/router';
import { useCallback } from 'react';
import { useAppContext } from '../../context/appContext/appContext';
import { CreateConnectionWizard } from '../../externalComponents/components/createConnectionWizard/createConnectionWizard';
import { RepositoryType } from '../../externalComponents/typedef';

export interface CreateConnectionsPageProps {
  orchAuthRedirectUrl: string;
  onConnectionCreatedCallback: () => void;
  onCancelCallback: () => void;
}

export const CreateConnectionsPage = (
  props: RouteComponentProps<any> & CreateConnectionsPageProps
) => {
  const { orchAuthRedirectUrl, onConnectionCreatedCallback, onCancelCallback } =
    props;

  const { isIFramed } = useAppContext();

  const getApplication = useCallback(() => {
    const applicationQParam = new URL(window.location?.href).searchParams.get(
      'application'
    );

    return isIFramed && typeof applicationQParam === 'string'
      ? applicationQParam === 'SYNCHROPWIC'
        ? 'PW365'
        : (applicationQParam as RepositoryType)
      : undefined;
  }, [isIFramed]);

  return (
    <CreateConnectionWizard
      orchAuthRedirectUrl={orchAuthRedirectUrl}
      predefinedRepositoryType={getApplication()}
      onConnectionCreatedCallback={onConnectionCreatedCallback}
      onCancelCallback={onCancelCallback}
    />
  );
};

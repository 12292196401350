import { BridgeRepositoryType } from './bridge';
import { assertUnreachable } from '../services/uiUtils/assertUnreachable';

export const COMMUNITIES_LINK =
  'https://communities.bentley.com/products/digital-twin-cloud-services/itwin-services/w/imodel-bridges-wiki/48714/integrations';

export const COMMUNITIES_TRANSFER_OWNERSHIP =
  'https://communities.bentley.com/products/digital-twin-cloud-services/itwin-services/w/imodel-bridges-wiki/51355/transfer-the-ownership';

export const COMMUNITIES_EDIT_MAPPING =
  'https://communities.bentley.com/products/digital-twin-cloud-services/itwin-services/w/synchronization-wiki/51447/edit-a-connection';

export const REGEXEXPRESSIONINFO_LINK =
  'https://communities.bentley.com/products/digital-twin-cloud-services/itwin-services/w/imodel-bridges-wiki/50622/match-files-based-on-name-pattern';

export const DELETECONNECTION_IDEA_LINK =
  'https://itwin.ideas.aha.io/ideas/IDEA-I-40';

export const COMMUNITIES_CREATECONNECTION_LINK =
  'https://communities.bentley.com/products/digital-twin-cloud-services/itwin-services/w/synchronization-wiki/48860/creating-a-connection';

export const COMMUNITIES_ONE_CONNECTOR_PER_CONNECTION =
  'https://communities.bentley.com/products/digital-twin-cloud-services/itwin-services/w/synchronization-wiki/48860/creating-a-connection#OneConnectorPerConnection';

export enum RepositoryType {
  PROJECTSHARE = 'PROJECTSHARE',
  PWDI = 'PWDI',
  PWDI_LEGACY = 'PWDI_LEGACY',
  SHAREPOINT = 'SHAREPOINT',
  PW365 = 'PW365',
  MANIFEST = 'MANIFEST',
}

export const bridgeRepositoryTypeToRepositoryTypeMap = (
  bridgeRepositoryType: BridgeRepositoryType
) => {
  switch (bridgeRepositoryType) {
    case BridgeRepositoryType.ProjectWise:
      return RepositoryType.PWDI;
    case BridgeRepositoryType.ProjectWiseLegacy:
      return RepositoryType.PWDI_LEGACY;
    case BridgeRepositoryType.ShareFile:
    case BridgeRepositoryType.ShareFolder:
      return RepositoryType.PROJECTSHARE;
    case BridgeRepositoryType.PW365:
      return RepositoryType.PW365;
    default:
      throw assertUnreachable(bridgeRepositoryType);
  }
};

export const getDatasourceTypeDisplayName = (
  repositoryType: RepositoryType
) => {
  switch (repositoryType) {
    case RepositoryType.PROJECTSHARE:
      return 'iTwin File Service';
    case RepositoryType.PWDI:
      return 'ProjectWise';
    case RepositoryType.PWDI_LEGACY:
      return 'ProjectWise Legacy';
    case RepositoryType.SHAREPOINT:
      return 'SharePoint';
    case RepositoryType.PW365:
      return 'ProjectWise 365';
    case RepositoryType.MANIFEST:
      return 'Manifest';
    default:
      throw assertUnreachable(repositoryType);
  }
};

export const getRepositoryTypeDisplayName = (
  repositoryType: RepositoryType
) => {
  switch (repositoryType) {
    case RepositoryType.PROJECTSHARE:
      return 'iTwin File Service';
    case RepositoryType.PWDI:
    case RepositoryType.PWDI_LEGACY:
      return 'ProjectWise';
    case RepositoryType.SHAREPOINT:
      return 'SharePoint';
    case RepositoryType.PW365:
      return 'ProjectWise 365';
    case RepositoryType.MANIFEST:
      return 'Manifest';
    default:
      throw assertUnreachable(repositoryType);
  }
};

export const getRepositoryTypeDescription = (
  repositoryType: RepositoryType
) => {
  switch (repositoryType) {
    case RepositoryType.PROJECTSHARE:
      return 'Cloud Services';
    case RepositoryType.PWDI_LEGACY:
    case RepositoryType.PWDI:
    case RepositoryType.PW365:
      return 'Design Integration';
    case RepositoryType.SHAREPOINT:
      return 'Microsoft File Collaboration';
    case RepositoryType.MANIFEST:
      return 'Manifest';
    default:
      assertUnreachable(repositoryType);
      return '';
  }
};

export const getRepositoryReadMoreLink = (repositoryType: RepositoryType) => {
  switch (repositoryType) {
    case RepositoryType.PROJECTSHARE:
      return `${COMMUNITIES_LINK}#PWShare`;
    case RepositoryType.PWDI_LEGACY:
    case RepositoryType.PWDI:
    case RepositoryType.PW365:
      return `${COMMUNITIES_LINK}#PWDI`;
    case RepositoryType.SHAREPOINT:
    case RepositoryType.MANIFEST:
      return COMMUNITIES_LINK;
    default:
      assertUnreachable(repositoryType);
      return '';
  }
};

export enum TokenType {
  OIDC = 'OIDC',
  SAML = 'SAML',
}

export interface Repository {
  id: string; // root folder id
  name?: string;
  location: string;
  hostName?: string;
  type: RepositoryType;
}

export const responseToRepositoriesMap = (response: any): Repository[] =>
  response.data.repositories;

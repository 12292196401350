import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from '../../../entities/item';
import {
  COMMUNITIES_CREATECONNECTION_LINK,
  Repository,
} from '../../../entities/repository';
import { useSelection } from '../../../hooks/useSelection/useSelection';
import { Explanation } from '../../explanation/explanation';
import {
  WizardButtonRowProps,
  WizardButtonRow,
} from '../wizardButtonRow/wizardButtonRow';
import { SpatialRootFileSelectionTable } from './spatialRootFileSelection';

export interface SpatialRootSelectionStepProps {
  id: string;
  selectedSpatialRoot: Item | null;
  selectedMasterFiles: Item[];
  isItemSelectable: (item: Item) => boolean;
  repository: Repository;
}
export const SpatialRootSelectionStep = (
  props: SpatialRootSelectionStepProps & WizardButtonRowProps
) => {
  const {
    repository,
    backHandle,
    cancelHandle,
    primaryButtonHandle,
    primaryButtonLabel,
    skipHandle,
    skipTooltip,
    selectedSpatialRoot,
    selectedMasterFiles,
    isItemSelectable,
  } = props;
  const { t } = useTranslation();

  const { selection, isSelected, setSelected } = useSelection();

  const select = (item: Item) => {
    selection.length === 0 ||
    (selection.length > 0 && selection[0].id !== item.id)
      ? setSelected([item])
      : setSelected([]);
  };

  useEffect(() => {
    if (selectedSpatialRoot) {
      setSelected([selectedSpatialRoot]);
    }
  }, [selectedSpatialRoot]);

  return (
    <>
      <div className="create-connection-container">
        <Explanation
          text={
            t('ReadMoreAboutGeoCoordinate_Text') +
            t('ReadMoreAboutGeoCoordinateCanSkip_Text') +
            t('ReadMoreAboutLimitations_Text')
          }
          linkText={t('ReadMoreAboutLimitations_Link')}
          linkUrl={COMMUNITIES_CREATECONNECTION_LINK}
        />
        <SpatialRootFileSelectionTable
          repository={repository}
          masterFiles={selectedMasterFiles}
          isItemSelectableAsSpatialRoot={isItemSelectable}
          isSelected={isSelected}
          onSelection={select}
        />
        <WizardButtonRow
          backHandle={() => {
            backHandle!(selection);
          }}
          cancelHandle={cancelHandle}
          primaryButtonHandle={() => {
            primaryButtonHandle(selection);
          }}
          primaryButtonLabel={primaryButtonLabel}
          primaryDisabled={selection.length < 1}
          skipHandle={skipHandle}
          skipTooltip={skipTooltip}
          skipHandleDisabled={selection.length > 0}
        />
      </div>
    </>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './connectionDefinintionsTable.scss';
import '../../scss/instantOn.scss';
import { Flex, NotificationMarker, Table } from '@itwin/itwinui-react';
import {
  ApiVersion,
  JobDefinition,
} from '../../entities/jobDefinition/jobDefinition';
import { NavigatableCellIUI } from '../navigatableCell/navigatableCell';
import { getColumnHeaderIUI } from '../../services/uiUtils/tableColumnWrappers';
import { ContextMenu } from '../contextMenu/contextMenu';
import {
  SvgDelete,
  SvgPlay,
  SvgRename,
  SvgStatusWarning,
  SvgUnlink,
  SvgUsers,
  SvgStatusPendingHollow,
  SvgCalendar,
  SvgEmail,
} from '@itwin/itwinui-icons-react';
import { getRepositoryTypeDisplayName } from '../../entities/repository';
import { getIconForRepository } from '../itemIcon/itemIcon';
import { useFormatters } from '../../services/uiUtils/jobStatusFormatters';
import {
  FileRunState,
  JobRunState,
} from '../../entities/jobRunStatus/jobRunStatus';
import { utcTimeStringToFormattedDateTime } from '../../services/uiUtils/dateFormatter';
import { SpatialRootIcon } from '../spatialRootIcon/spatialRootIcon';
import {
  iModelUser,
  useFetchiModelUsers,
} from '../../hooks/useFetchiModelUsers/useFetchiModelUsers';
import { useGetJobsLastStatuses } from '../../hooks/useGetJobsLastStatuses/useGetJobsLastStatuses';
import { Text, Button, IconButton, Tooltip } from '@itwin/itwinui-react';
import { useTranslation } from 'react-i18next';
import { getJobTokenType } from '../../services/bridgeLogic/bridgeLogic';
import { useToast } from '../../context/toastContext/toastContext';
import { useOrchestratorAuthenticateUser } from '../../hooks/useOrchestratorAuthenticateUser/useOrchestratorAuthenticateUser';
import { useRunJob } from '../../hooks/useRunJob/useRunJob';
import { useModal } from '../../hooks/useModal/useModal';
import { RenameJobModal } from '../renameJobModal/renameJobModal';
import { useRenameJob } from '../../hooks/useRenameJob/useRenameJob';
import { useDeleteJob } from '../../hooks/useDeleteJob/useDeleteJob';
import {
  ConfirmationType,
  ConfirmDeleteFilesModal,
} from '../confirmDeleteFilesModal/confirmDeleteFilesModal';
import { TransferOwnershipModal } from '../transferOwnershipModal/transferOwnershipModal';
import { CopyConnectionsJobModal } from '../copyConnectionsJobModal/copyConnectionsJobModal';
import { SvgAdd, SvgCopy } from '@itwin/itwinui-icons-react';
import { usePermissionContext } from '../../context/iModelPermissionContext/iModelPermissionContext';
import { useBentleyContext } from '../../context/bentleyContext/bentleyContext';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import { ButtonGroup } from '../iTwinUI/buttonGroup';
import { ConnectionDefinitionsTableProps } from '../../typedef/index';
import { useConnectionDefintionsContext } from '../../context/connectionDefinitionsContext/connectionsDefintionsContext';
import { useSpatialRootsContext } from '../../context/spatialRootsContext/spatialRootsContext';
import { CellProps, Column, Row } from 'react-table';
import { ScheduleIntervalV2 } from '../schedule/scheduleV2/jobScheduleSelectionv2/jobScheduleSelectionV2';
import { Icon } from '../icons/icon';
import { TruncatedText } from '../truncatedText/TruncatedText';
import { EditScheduleModal } from '../modals/editScheduleModal/editScheduleModal';
import { useGetJobSchedule } from '../../hooks/useGetJobSchedule/useGetJobSchedule';
import { useItemListWithToggle } from '../../hooks/useItemListWithToggle/useItemListWithToggle';
import { MenuAction } from '../jobView/toolbarActions';
import cx from 'classnames';
import { Entity } from '../../entities/entity';
import { SynchronizationFeedbackModal } from '../modals/synchronizationFeedbackModal/synchronizationFeedbackModal';
import { EmailNotificationsModal } from '../modals/emailNotificationsModal/emailNotificationsModal';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { Events } from '../../context/usageLoggerContext/eventConstants';
import { OnboardingPopover } from '../popover/onboardingPopover';
import { SvgTechnicalPreviewMini } from '@itwin/itwinui-icons-color-react';
import { EmailNotificationFeedbackModal } from '../modals/emailNotificationFeedbackModal/emailNotificationFeedbackModal';

export const ConnectionDefinitionsTable = (
  props: ConnectionDefinitionsTableProps
) => {
  const {
    orchAuthRedirectUrl,
    createDefinitionCallback,
    changeMappingCallback,
    onConnectionDefinitionClick,
  } = props;

  const { projectId, iModelId } = useBentleyContext();

  const {
    isCopyConnectionsUIEnabled,
    migrateToV2Api,
    apiVersionColumn,
    disableSupportForV1,
    enableEmailNotificationsModal,
    enableNotificationPopover,
  } = useFeatureToggleContext();

  const { logEvent } = useUsageLogger();

  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();

  const { canModifyiModel, canManageiModel } = usePermissionContext();

  const {
    connectionsDefintions,
    areConnectionDefintionsLoading: areJobsLoading,
    fetchConnectionDefintions: fetchJobDefinitions,
  } = useConnectionDefintionsContext();

  const jobs = useMemo(
    () => (areJobsLoading ? [] : connectionsDefintions),
    [areJobsLoading, connectionsDefintions]
  );

  const { spatialRoots } = useSpatialRootsContext();

  const [lastStatuses, areJobsStatusesLoading, , fetchJobLastStatuses] =
    useGetJobsLastStatuses();

  const [iModelUsers, areIModelUsersLoading, , fetchIModelUsers] =
    useFetchiModelUsers();

  const [, isRunJobRequestLoading, , runJob] = useRunJob(projectId, iModelId);

  const [, , , orchestratorAuthenticateUser] =
    useOrchestratorAuthenticateUser();

  useEffect(() => {
    if (jobs.length !== 0 && !disableSupportForV1) {
      const fetchJobLastStatusesAwaitWrapper = async () => {
        await fetchJobLastStatuses(jobs.map(j => j.id));
      };

      fetchJobLastStatusesAwaitWrapper();
    }
  }, [jobs, disableSupportForV1]);

  const [iModelUserMap, setiModelUserMap] = useState<{
    [userId: string]: iModelUser;
  }>({});

  useEffect(() => {
    fetchIModelUsers(iModelId);
  }, [fetchIModelUsers, iModelId, jobs]);

  useEffect(() => {
    for (const iModelUser of iModelUsers) {
      if (!iModelUserMap[iModelUser.Id]) {
        iModelUserMap[iModelUser.Id] = iModelUser;
      }
    }

    setiModelUserMap(iModelUserMap);
  }, [iModelUserMap, iModelUsers]);

  const { jobRunStatusToFormattedText, fileRunStatusToFormattedText } =
    useFormatters();

  const { selection, selectedIds, setSelected, resetSelection } =
    useItemListWithToggle();

  const {
    closeModal: closeRenameJobModal,
    openModal: openRenameJobModal,
    modalProps: renameModalProps,
  } = useModal();

  const [, , , renameJob] = useRenameJob(projectId, iModelId);

  const {
    closeModal: closeJobDeleteConfirmModal,
    openModal: openJobDeleteConfirmModal,
    modalProps: confirmJobDeleteModalProps,
  } = useModal();

  const [, isDeleteJobRequestLoading, , deleteJob] = useDeleteJob(
    projectId,
    iModelId
  );

  const {
    closeModal: closeTransferOwnershipModal,
    openModal: openTransferOwnershipModal,
    modalProps: transferOwnershipModalProps,
  } = useModal();

  const {
    closeModal: closeCopyConnectionsJobModal,
    openModal: openCopyConnectionsJobModal,
    modalProps: copyConnectionsJobModalProps,
  } = useModal();

  const {
    closeModal: closeEditScheduleModal,
    openModal: openEditScheduleModal,
    modalProps: editScheduleModalProps,
  } = useModal();

  const {
    closeModal: closeEmailNotificationFeedbackModal,
    openModal: openEmailNotificationFeedbackModal,
    modalProps: emailNotificationFeedbackModalProps,
  } = useModal({ initialIsOpen: true });

  const {
    closeModal: closeEmailNotificationModal,
    openModal: openEmailNotificationModal,
    modalProps: emailNotificationModalProps,
  } = useModal();

  const [isEmailNotificationsModalOpen, setIsEmailNotificationsModalOpen] =
    useState(false);

  const [areAnySyncsInProgress, setareAnySyncsInProgress] =
    useState<boolean>(false);

  const [jobSchedule, , ,] = useGetJobSchedule(projectId, iModelId);

  const [rowWithActiveMenu, setRowWithActiveMenu] =
    useState<Row<JobDefinition> | null>(null);

  const jobNotScheduled = (job: JobDefinition) => {
    if (job.isScheduled && !job.isSchedulePaused) {
      return (
        <span className="center-vertically">
          <Icon
            icon={SvgStatusPendingHollow}
            color="default"
            className="status-icon"
          />
          <TruncatedText>{t('FirstRunScheduled_Label')}</TruncatedText>
        </span>
      );
    } else {
      return (
        <span className="center-vertically">
          <Tooltip
            content={
              job.isSchedulePaused
                ? t('SuspendedSchedule_Alert')
                : t('JobNotRun_WarningMessage')
            }
            placement="left-start"
          >
            <div className="spatial-icon-wrapper">
              <Icon
                icon={SvgStatusWarning}
                color="warning"
                className="status-icon"
              />
            </div>
          </Tooltip>
          <TruncatedText>{t('JobLastSyncStatus_Label')}</TruncatedText>
          {canModifyiModel && (
            <>
              <div>&nbsp;-</div>
              <a
                className="jobStatusMore"
                href="#"
                onClick={async () => {
                  await runJobAndHandle(job.id);
                  fetchJobDefinitions();
                }}
              >
                {t('SyncNow_Link')}
              </a>
            </>
          )}
        </span>
      );
    }
  };

  useEffect(() => {
    const selectedJobs = jobs.filter(x => selectedIds.includes(x.id));
    const runningJobs = selectedJobs.filter(
      x =>
        x.lastRunDetails?.state === JobRunState.Queued ||
        x.lastRunDetails?.state === JobRunState.InProgress ||
        x.lastRunDetails?.state === JobRunState.NotStarted
    );
    setareAnySyncsInProgress(runningJobs.length > 0);
  }, [jobs, selectedIds, jobNotScheduled]);

  const isSpatialRoot = useMemo(() => {
    if (!spatialRoots || spatialRoots.length === 0) {
      return false;
    }

    return selectedIds.includes(spatialRoots[0].jobDefinitionId!);
  }, [selectedIds, spatialRoots]);

  const runJobAndHandle = async (jobToRunId: string) => {
    const job = jobs.find(x => x.id === jobToRunId)!;

    try {
      const tokenType = getJobTokenType(job.repositoryType);
      const authenticated = await orchestratorAuthenticateUser(
        tokenType,
        orchAuthRedirectUrl
      );

      if (!authenticated) {
        return;
      }
    } catch (e) {
      toastError(t('UserAuthenticationFailure_Toast', { error: e }));
      return;
    }

    const isSuccess = await runJob(job);
    if (!isSuccess) {
      return;
    }

    onConnectionDefinitionClick(jobToRunId);
    await fetchJobDefinitions();
  };

  const onRunToolbarHandle = async (isOneItemSelected: boolean) => {
    if (selection && isOneItemSelected) {
      await runJobAndHandle(selection[0].id);
    } else {
      let anyFailure = false;
      const organisedConnections = isSpatialRoot
        ? await organizeConnectionsToProcessSpatialRootFirstForGroupSync(
            selection
          )
        : selection;
      for (const jobToRun of organisedConnections) {
        const result = await runJob(jobToRun as JobDefinition, false, false);
        anyFailure = anyFailure || !result;
      }
      if (anyFailure) {
        toastError(t('MultipleJobRunsStartFailed_Toast'));
      } else {
        toastSuccess(t('MultipleJobRunsStarted_Toast'));
      }

      resetSelection();
      await fetchJobDefinitions();
    }
  };

  const onRunDropdownMenuHandle = async (row: Row<JobDefinition>) => {
    await runJobAndHandle(row.original.id);
  };

  const getConnectionActions = (row?: Row<JobDefinition>) => {
    const isOneItemSelected = selection.length === 1;
    const isDropdownMenuAction = row !== undefined;
    const mainToolbarActions: MenuAction[] = [
      {
        title: t('RunJobBtn_Tooltip'),
        'data-testid': 'RunJob',
        icon: <SvgPlay />,
        disabled: shouldDisableSynchronize(row),
        onClick: () =>
          isDropdownMenuAction
            ? onRunDropdownMenuHandle(row)
            : onRunToolbarHandle(isOneItemSelected),
      },
      {
        title: t('DeleteJobBtn_Tooltip'),
        'data-testid': 'DeleteJob',
        icon: <SvgDelete />,
        disabled: shouldDisableDelete(row),
        onClick: openJobDeleteConfirmModal,
      },
      {
        title: t('RenameJobBtn_Tooltip'),
        'data-testid': 'RenameJob',
        icon: <SvgRename />,
        disabled:
          (!isOneItemSelected && !isDropdownMenuAction) ||
          !canModifyiModel,
        onClick: async () => {
          openRenameJobModal();
        },
      },
    ];

    mainToolbarActions.push({
      title: t('ScheduleJobBtn_Tooltip'),
      //@ts-ignore
      'data-testid': 'ScheduleJob',
      icon: <SvgCalendar />,
      disabled:
        (!isOneItemSelected && !isDropdownMenuAction) ||
        !canModifyiModel ||
        (!isDropdownMenuAction &&
          (selection[0] as JobDefinition).apiVersion === ApiVersion.v1),
      onClick: async () => {
        openEditScheduleModal();
      },
    });
    mainToolbarActions.push({
      title: t('ChangeOwnerDropDown_Label'),
      'data-testid': 'ChangeOwner',
      icon: <SvgUsers />,
      disabled:
        (!isOneItemSelected && !isDropdownMenuAction) ||
        shouldDisableSynchronize(row) ||
        !canManageiModel ||
        (row && isJobRunning(row.original)),
      onClick: openTransferOwnershipModal,
    });

    mainToolbarActions.push({
      title: t('ChangeMappingBtn_Title'),
      'data-testid': 'ChangeMapping',
      disabled:
        (!isOneItemSelected && !isDropdownMenuAction) ||
        (areAnySyncsInProgress && !isDropdownMenuAction) ||
        !canModifyiModel ||
        !canManageiModel ||
        (isDropdownMenuAction && isJobRunning(row.original)) ||
        (row && row.original.isDeletePending) ||
        selection.find(job => (job as JobDefinition).isDeletePending) != null,
      icon: <SvgUnlink />,
      onClick: () =>
        changeMappingCallback(
          selection.length > 0
            ? (selection[0] as JobDefinition).id
            : row?.original?.id
            ? row.original.id
            : ''
        ),
    });

    if (isCopyConnectionsUIEnabled && !migrateToV2Api) {
      mainToolbarActions.push({
        title: t('CopyConnectionsBtn_Title'),
        'data-testid': 'CopyConnections',
        icon: <SvgCopy />,
        onClick: openCopyConnectionsJobModal,
      });
    }

    return mainToolbarActions;
  };

  const organizeConnectionsToProcessSpatialRootFirstForGroupSync = async (
    selection: Entity[]
  ) => {
    const connectionWithSpatialRootFile = selection.find(
      x => x.id === spatialRoots[0].jobDefinitionId
    );
    if (connectionWithSpatialRootFile != null) {
      selection = selection.filter(
        x => x.id !== connectionWithSpatialRootFile?.id
      );
      return [connectionWithSpatialRootFile, ...selection];
    }
    return selection;
  };

  const getJobLastRunStatus = (jobId: string) => {
    return lastStatuses.find(x => x.jobId === jobId);
  };

  const isJobRunning = (job: JobDefinition) => {
    return (
      job.lastRunDetails?.state === JobRunState.Queued ||
      job.lastRunDetails?.state === JobRunState.InProgress ||
      job.lastRunDetails?.state === JobRunState.NotStarted
    );
  };

  const isLoading: boolean = areJobsLoading || isDeleteJobRequestLoading;

  const shouldDisableSynchronize = (row?: Row<JobDefinition>) =>
    (selection.length < 1 && !row) ||
    isLoading ||
    !canModifyiModel ||
    (row && isJobRunning(row.original)) ||
    (areAnySyncsInProgress && !row);

  const shouldDisableDelete = (row?: Row<JobDefinition>) =>
    (selection.length < 1 && !row) ||
    (areAnySyncsInProgress && !row) ||
    (row && isJobRunning(row.original)) ||
    !canManageiModel;

  const deleteJobs = useCallback(
    async (deleteModels: boolean) => {
      let syncCount = 0;
      let isError = false;
      const jobs = rowWithActiveMenu
        ? [rowWithActiveMenu.original]
        : (selection as JobDefinition[]);
      for (const job of jobs) {
        const shouldDeleteModels = doesSelectedJobsHasSyncs
          ? job.lastRunDetails
            ? deleteModels
            : !deleteModels
          : deleteModels;

        const deleteJobResult = await deleteJob(job, shouldDeleteModels);
        if (!deleteJobResult.ok) {
          isError = true;
        } else if (shouldDeleteModels) {
          syncCount++;
        }
      }
      if (isError) {
        toastError(
          selection.length > 1
            ? t('MultipleDeleteConnectionDefinitionError_Toast')
            : t('DeleteConnectionDefinitionError_Toast')
        );
      } else {
        if (syncCount === 1) {
          toastSuccess(t('JobRunStarted_Toast'));
        } else if (syncCount > 1) {
          toastSuccess(t('MultipleJobRunsStarted_Toast'));
        }
      }
      closeJobDeleteConfirmModal();
      resetSelection();

      await fetchJobDefinitions();
    },
    [
      selection,
      deleteJob,
      closeJobDeleteConfirmModal,
      resetSelection,
      fetchJobDefinitions,
    ]
  );

  const deleteModalTitle = useMemo(
    () =>
      selectedIds.length === 1
        ? t('ConfirmDeleteJobModal_Title')
        : t('ConfirmDeleteJobsModal_Title'),
    [selectedIds]
  );

  const doesSelectedJobsHasSyncs = useMemo(
    () =>
      selectedIds.length > 0
        ? selectedIds
            .map(id => jobs.find(job => job.id === id))
            .map(x => {
              if (x?.apiVersion === ApiVersion.v1) {
                return lastStatuses.find(jobStatus => jobStatus.jobId === x.id)
                  ?.status !== FileRunState.NotFound
                  ? true
                  : false;
              } else {
                return x!.lastRunDetails ? true : false;
              }
            })
            .includes(true)
        : rowWithActiveMenu?.original.lastRunDetails
        ? true
        : false,

    [selectedIds, lastStatuses, jobs]
  );

  const getscheduleDetails = (job: JobDefinition) => {
    let intervalString = '';
    switch (Number.parseInt(job.scheduleInfo!)) {
      case ScheduleIntervalV2.Every4Hours:
        intervalString = 'Every 4 hours';
        break;
      case ScheduleIntervalV2.Hourly:
        intervalString = 'Hourly';
        break;
      case ScheduleIntervalV2.Daily:
        intervalString = 'Daily';
        break;
      case ScheduleIntervalV2.Weekly:
        intervalString = 'Weekly';
        break;
      default:
        intervalString =
          'Every ' + Number.parseInt(job.scheduleInfo!) / 60 + ' minutes';
        break;
    }
    return intervalString;
  };

  const getSchedule = (job: JobDefinition) => {
    if (!job) return '';
    if (job.apiVersion === ApiVersion.v2) {
      return job.scheduleInfo ? job.scheduleInfo : '';
    } else {
      return !disableSupportForV1 && jobSchedule.schedule
        ? jobSchedule.schedule
        : '';
    }
  };

  // This is a hack since columns update too fast
  const [updateTableTrigger, setUpdateTableTrigger] = useState<boolean>(false);

  useEffect(() => {
    setUpdateTableTrigger(!updateTableTrigger);
  }, [areIModelUsersLoading]);

  const columns = useMemo(
    (): Column<JobDefinition>[] => [
      {
        ...getColumnHeaderIUI<JobDefinition, 'name'>(
          t('NameColumnHeader_Label'),
          'name'
        ),
        Cell: ({ row: { original } }: CellProps<JobDefinition, any>) => {
          const iconValue = original.repositoryType;
          const icon = getIconForRepository(iconValue);
          return (
            <>
              <NavigatableCellIUI
                name={original.name}
                onNavigate={() => onConnectionDefinitionClick(original.id)}
                leftIcon={
                  <Tooltip
                    content={getRepositoryTypeDisplayName(
                      original.repositoryType
                    )}
                    placement="top"
                  >
                    <div className="icon-with-text ">{icon}</div>
                  </Tooltip>
                }
                rightIcon={
                  spatialRoots &&
                  spatialRoots.length > 0 &&
                  original.id === spatialRoots[0]?.jobDefinitionId ? (
                    <div className="spatial-root-icon-wrapper">
                      <SpatialRootIcon toolTipText={t('SpatialIcon_Tooltip')} />
                    </div>
                  ) : null
                }
              />
            </>
          );
        },
        sortType: (
          { original: a }: Row<JobDefinition>,
          { original: b }: Row<JobDefinition>
        ) => {
          return a.name
            .toLocaleLowerCase()
            .localeCompare(b.name.toLocaleLowerCase());
        },
        minWidth: 150,
        accessor: 'name',
      },
      {
        id: 'apiVersion',
        accessor: 'apiVersion',
        Cell: ({ row: { original } }: CellProps<JobDefinition, any>) => {
          return <>{original.apiVersion ?? null}</>;
        },
        minWidth: 65,
        maxWidth: 65,
      },
      {
        ...getColumnHeaderIUI<JobDefinition, 'scheduleInfo'>(
          t('ScheduleJobBtn_Tooltip'),
          'scheduleInfo'
        ),
        accessor: 'scheduleInfo',
        Cell: ({ row: { original } }: CellProps<JobDefinition, any>) => {
          if (original.apiVersion === ApiVersion.v2) {
            if (original?.scheduleInfo)
              return (
                <>
                  <TruncatedText>{getscheduleDetails(original)}</TruncatedText>
                </>
              );
            else
              return (
                <>
                  <TruncatedText>{t('NoSchedule_Label')}</TruncatedText>
                </>
              );
          } else {
            //for v1
            return null;
          }
        },
        minWidth: 120,
        maxWidth: 150,
      },
      {
        ...getColumnHeaderIUI<JobDefinition, 'userId'>(
          t('OwnerColumnHeader_Label'),
          'userId'
        ),
        accessor: 'userId',
        Cell: ({ row: { original } }: CellProps<JobDefinition, any>) => {
          return (
            <>
              <TruncatedText isSkeleton={areIModelUsersLoading}>
                {!areIModelUsersLoading
                  ? original.userId
                    ? iModelUserMap[original.userId]?.Email
                    : ''
                  : 'Placeholder skeleton text'}
              </TruncatedText>
            </>
          );
        },
        minWidth: 175,
        maxWidth: 280,
      },
      {
        ...getColumnHeaderIUI<JobDefinition, 'lastRunDetails'>(
          t('LastRunOnColumnHeader_Label'),
          'lastRunDetails'
        ),
        accessor: 'lastRunDetails',
        Cell: ({ row: { original } }: CellProps<JobDefinition, any>) => {
          if (original.apiVersion === ApiVersion.v2) {
            let lastSyncTime;
            if (original.lastRunDetails && original.lastRunDetails.state !== JobRunState.Queued) {
              if (original.lastRunDetails.processingStartTimeStamp != "0001-01-01T00:00:00Z")
                lastSyncTime = utcTimeStringToFormattedDateTime(original.lastRunDetails.processingStartTimeStamp);
              else
                lastSyncTime = utcTimeStringToFormattedDateTime(original.lastRunDetails.startTimestamp);
            }
            else {
              lastSyncTime = utcTimeStringToFormattedDateTime(null);
            }
            return (
              <>
                <TruncatedText>
                  {lastSyncTime.length !== 0
                    ? lastSyncTime
                    : t('Job_NotSynced_Label')}
                </TruncatedText>
              </>
            );
          } else {
            const jobStatus = getJobLastRunStatus(original.id);
            const jobStatusStartTime = utcTimeStringToFormattedDateTime(
              jobStatus?.startTime?.toString() || null
            );
            return (
              <>
                <Text
                  variant="body"
                  as="p"
                  className={cx('truncated-text-wrapper')}
                  isSkeleton={areJobsStatusesLoading}
                >
                  {!areJobsStatusesLoading
                    ? jobStatusStartTime.length !== 0
                      ? jobStatusStartTime
                      : t('Job_NotSynced_Label')
                    : 'Placeholder skeleton text'}
                </Text>
              </>
            );
          }
        },
        minWidth: 150,
        maxWidth: 280,
        sortType: (
          { original: a }: Row<JobDefinition>,
          { original: b }: Row<JobDefinition>
        ) => {
          const x1 = a.lastRunDetails?.startTimestamp
            ? a.lastRunDetails?.startTimestamp.toLocaleLowerCase()
            : 0;
          const x2 = b.lastRunDetails?.startTimestamp
            ? b.lastRunDetails?.startTimestamp.toLocaleLowerCase()
            : 0;
          const a1 = new Date(x1).getTime();
          const b1 = new Date(x2).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },

      {
        ...getColumnHeaderIUI<JobDefinition, 'lastRunStatus'>(
          t('LastRunStatusColumnHeader_Label'),
          'lastRunStatus'
        ),
        accessor: 'lastRunStatus',
        Cell: ({ row: { original } }: CellProps<JobDefinition, any>) => {
          if (original.apiVersion === ApiVersion.v2) {
            return (
              <>
                {original.lastRunDetails != null
                  ? jobRunStatusToFormattedText(
                      original.lastRunDetails.state,
                      original.isDeletePending
                    )
                  : jobNotScheduled(original)}
              </>
            );
          } else {
            const jobStatus = getJobLastRunStatus(original.id);
            const status = jobStatus?.status;
            return (
              <>
                <Text
                  variant="body"
                  as="p"
                  className={cx('truncated-text-wrapper')}
                  isSkeleton={areJobsStatusesLoading}
                >
                  {!areJobsStatusesLoading
                    ? status && fileRunStatusToFormattedText(status)
                    : 'Placeholder skeleton text'}
                </Text>
              </>
            );
          }
        },
        minWidth: 160,
        maxWidth: 280,
        sortType: (
          { original: a }: Row<JobDefinition>,
          { original: b }: Row<JobDefinition>
        ) => {
          const states = Object.keys(JobRunState);

          const [aIndex, bIndex] = [a, b].map(x =>
            x.lastRunDetails
              ? states.indexOf(x.lastRunDetails.state)
              : states.length
          );

          return aIndex === bIndex ? 0 : aIndex > bIndex ? 1 : -1;
        },
      },
      {
        id: 'contextMenuAction',
        Cell: ({ row }: CellProps<JobDefinition, any>) => {
          return (
            <>
              <ContextMenu
                menuOptions={getConnectionActions(row)}
                onToggle={() => setRowWithActiveMenu(row)}
              />
            </>
          );
        },
        minWidth: 44,
        maxWidth: 54,
      },
    ],
    [updateTableTrigger]
  );

  const filteredColumns = useMemo((): Column<JobDefinition>[] => {
    return columns.filter(col => {
      if (col.id === 'apiVersion') {
        return migrateToV2Api && apiVersionColumn;
      } else if (col.id === 'schedule') {
        return migrateToV2Api;
      }

      return true;
    });
  }, [apiVersionColumn, columns, migrateToV2Api]);

  const showSynchronizationFeedbackModal = true;
  const defaultModalProps = {
    initialIsOpen: showSynchronizationFeedbackModal,
  };

  const {
    openModal: openSynchronizationFeedbackModal,
    closeModal: closeSynchronizationFeedbackModal,
    modalProps: feedbackModalProps,
  } = useModal(defaultModalProps);

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  return (
    <>
      {
        <div className="table-wrapper">
          <Flex>
            <div className="table-toolbar">
              <Button
                styleType="high-visibility"
                onClick={createDefinitionCallback}
                title={t('AddNewDataSourceConnectionBtn_Tooltip')}
                data-testid={'CreateJob'}
                disabled={!canModifyiModel}
                startIcon={<SvgAdd />}
              >
                {t('AddNewDataSourceConnectionBtn_Label')}
              </Button>
              <ButtonGroup>
                {getConnectionActions().map(action => {
                  return (
                    <Tooltip key={action.title} content={action.title}>
                      <div>
                        <IconButton
                          data-testid={action['data-testid']}
                          disabled={action.disabled}
                          onClick={action.onClick}
                        >
                          {action.icon}
                        </IconButton>
                      </div>
                    </Tooltip>
                  );
                })}
              </ButtonGroup>
            </div>
            <Flex.Spacer />

            {enableEmailNotificationsModal && (
              <div className="email-notification-button">
                <OnboardingPopover
                  shouldShowPopover={enableNotificationPopover}
                  displayMessage={t('Email_Notification_Popover_Message')}
                  isVisible={isPopoverVisible}
                  placement="left"
                  show={() => setIsPopoverVisible(true)}
                  hide={() => setIsPopoverVisible(false)}
                >
                  <Button
                    endIcon={<SvgTechnicalPreviewMini />}
                    styleType="high-visibility"
                    startIcon={
                      <NotificationMarker status="white">
                        <SvgEmail />
                      </NotificationMarker>
                    }
                    onClick={() => {
                      if (isPopoverVisible) {
                        setIsPopoverVisible(false);
                      }
                      setIsEmailNotificationsModalOpen(true);
                      openEmailNotificationModal();
                      logEvent(Events.EmailNotificationButtonClicked);
                    }}
                  >
                    {t('Notification_btn_label')}
                  </Button>
                </OnboardingPopover>
              </div>
            )}
          </Flex>

          <Table<{ [P in keyof JobDefinition]: JobDefinition[P] }>
            density="condensed"
            data={jobs}
            emptyTableContent={
              isRunJobRequestLoading || areJobsLoading
                ? ''
                : t('NoJobRunsFound_Label')
            }
            isLoading={areJobsLoading}
            isSortable={true}
            columns={filteredColumns}
            isSelectable={true}
            onSelect={(selectedData: JobDefinition[] | undefined, _) => {
              setSelected(selectedData ? selectedData.map(sd => sd) : []);
            }}
            selectRowOnClick={false}
          />
        </div>
      }

      {enableEmailNotificationsModal && isEmailNotificationsModalOpen && (
        <EmailNotificationsModal
          {...emailNotificationModalProps}
          onClose={() => {
            closeEmailNotificationModal();
            setIsEmailNotificationsModalOpen(false);
          }}
        />
      )}

      {emailNotificationFeedbackModalProps.isOpen && (
        <EmailNotificationFeedbackModal
          {...{
            ...emailNotificationFeedbackModalProps,
            closeEmailNotificationFeedbackModal,
          }}
        />
      )}

      {showSynchronizationFeedbackModal && feedbackModalProps.isOpen && (
        <SynchronizationFeedbackModal
          {...{ ...feedbackModalProps, closeSynchronizationFeedbackModal }}
        />
      )}

      {editScheduleModalProps.isOpen && (
        <EditScheduleModal
          {...editScheduleModalProps}
          jobDefinition={
            rowWithActiveMenu?.original || (selection[0] as JobDefinition)
          }
          projectId={projectId}
          schedule={getSchedule(
            rowWithActiveMenu
              ? rowWithActiveMenu.original
              : (selection[0] as JobDefinition)
          )}
          onClose={() => {
            closeEditScheduleModal();
            setRowWithActiveMenu(null);
          }}
          onScheduleChange={async () => {
            await fetchJobDefinitions();
            resetSelection();
          }}
        />
      )}

      {renameModalProps.isOpen && (
        <RenameJobModal
          {...renameModalProps}
          job={rowWithActiveMenu?.original || (selection[0]! as JobDefinition)}
          jobs={jobs}
          closeModal={() => {
            closeRenameJobModal();
            setRowWithActiveMenu(null);
          }}
          renameJobName={async (job, newName) => {
            const result = await renameJob(job, newName);
            closeRenameJobModal();
            if (!result.ok) {
              toastError(t('JobRenameFailed_Toast'));
              return;
            }
            resetSelection();
            await fetchJobDefinitions();
            toastSuccess(t('JobRenameSuccess_Toast'));
          }}
        />
      )}

      {confirmJobDeleteModalProps.isOpen && (
        <ConfirmDeleteFilesModal
          {...confirmJobDeleteModalProps}
          title={deleteModalTitle}
          onConfirm={async (deleteModels: boolean) => {
            await deleteJobs(deleteModels);
          }}
          onCancel={() => {
            closeJobDeleteConfirmModal();
            setRowWithActiveMenu(null);
          }}
          type={ConfirmationType.DeleteJob}
          doesJobHasSyncs={doesSelectedJobsHasSyncs}
          noOfItemstoBeRemoved={selectedIds.length}
        />
      )}

      {transferOwnershipModalProps.isOpen && (
        <TransferOwnershipModal
          {...transferOwnershipModalProps}
          projectId={projectId}
          iModelId={iModelId}
          closeModal={() => {
            closeTransferOwnershipModal();
            setRowWithActiveMenu(null);
          }}
          onUserChange={async () => fetchJobDefinitions()}
          jobDefinition={
            rowWithActiveMenu?.original || (selection[0] as JobDefinition)
          }
        />
      )}

      {copyConnectionsJobModalProps.isOpen && (
        <CopyConnectionsJobModal
          {...copyConnectionsJobModalProps}
          projectId={projectId}
          currentIModelId={iModelId}
          spatialRoot={
            spatialRoots && spatialRoots.length > 0 ? spatialRoots[0] : null
          }
          jobs={jobs}
          closeModal={() => {
            closeCopyConnectionsJobModal();
            setRowWithActiveMenu(null);
          }}
        />
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import {
  Button,
  InputGroup,
  Modal,
  ModalButtonBar,
  Radio,
  Textarea,
} from '@itwin/itwinui-react';
import { ModalProps } from '../../../hooks/useModal/useModal';
import './synchronizationFeedbackModal.scss';
import { useTranslation } from 'react-i18next';
import { SyncFeedbackRating } from '../../../entities/feedbackRating';
import { useToast } from '../../../context/toastContext/toastContext';
import { Events } from '../../../context/usageLoggerContext/eventConstants';
import { useUsageLogger } from '../../../context/usageLoggerContext/usageLoggerContext';
import { useFeatureToggleContext } from '../../../context/featureToggleContext/featureToggleContext';

export const SynchronizationFeedbackModal: React.FC<ModalProps> = (
  props: ModalProps
) => {
  const { t } = useTranslation();
  const [rating, setRating] = useState<string>(SyncFeedbackRating.NoRating);
  const [feedbackMessage, setFeedbackMessage] = useState<string>('');
  const { isDismissible, onClose, ...otherProps } = props;
  const { toastSuccess } = useToast();
  const { logEvent } = useUsageLogger();
  const { enableSyncFeedbackModal, overrideEnableSyncFeedbackModal } = useFeatureToggleContext();

  const [
    showSynchronizationFeedbackModal,
    setShowSynchronizationFeedbackModal,
  ] = useState<boolean>(true);

  useEffect(() => {
    const [showSynchronizationFeedbackModalValue, overrideShowSynchronizationFeedbackModalValue] = 
      ['showSynchronizationFeedbackModal', 'overrideShowSynchronizationFeedbackModal'].map(item => JSON.parse(localStorage.getItem(item) || 'null'));
    
    if (showSynchronizationFeedbackModal === false && overrideShowSynchronizationFeedbackModalValue !== false && overrideEnableSyncFeedbackModal) {
      setShowSynchronizationFeedbackModal(true);
      localStorage.setItem('showSynchronizationFeedbackModal', 'true');
      localStorage.setItem('overrideShowSynchronizationFeedbackModal', 'false');
    } else if (showSynchronizationFeedbackModalValue !== null) {
        setShowSynchronizationFeedbackModal(JSON.parse(showSynchronizationFeedbackModalValue));
    }
  }, [overrideEnableSyncFeedbackModal, showSynchronizationFeedbackModal]);

  const submitFeedback = () => {
    logEvent(Events.SynchronizationFeedback, [
      { name: 'feedbackRating', value: rating },
      {
        name: 'feedbackMessage',
        value: feedbackMessage,
      },
    ]);

    localStorage.setItem('showSynchronizationFeedbackModal', 'false');
    setShowSynchronizationFeedbackModal(false);
    toastSuccess(t('Received_Feedback_Label'));
  };

  return enableSyncFeedbackModal && showSynchronizationFeedbackModal ? (
    <Modal
      isDismissible={false}
      {...otherProps}
      title={
        <div className="sync-feedback-title">{t('We_Want_Your_Thoughts')}</div>
      }
    >
      <div>{t('Synchronization_Experience')}</div>
      <InputGroup displayStyle="inline" className="rating-input-group">
        <div className="extreme-sentiment-label">Bad</div>
        <InputGroup className="input-group-option">
          <div className="div-rating-label">1</div>
          <Radio
            value={SyncFeedbackRating.Rating1}
            checked={rating === SyncFeedbackRating.Rating1}
            onChange={e => {
              setRating(e.target.value);
            }}
          />
        </InputGroup>

        <InputGroup className="input-group-option">
          <div className="div-rating-label">2</div>
          <Radio
            value={SyncFeedbackRating.Rating2}
            checked={rating === SyncFeedbackRating.Rating2}
            onChange={e => {
              setRating(e.target.value);
            }}
          />
        </InputGroup>

        <InputGroup className="input-group-option">
          <div className="div-rating-label">3</div>
          <Radio
            value={SyncFeedbackRating.Rating3}
            checked={rating === SyncFeedbackRating.Rating3}
            onChange={e => {
              setRating(e.target.value);
            }}
          />
        </InputGroup>

        <InputGroup className="input-group-option">
          <div className="div-rating-label">4</div>
          <Radio
            value={SyncFeedbackRating.Rating4}
            checked={rating === SyncFeedbackRating.Rating4}
            onChange={e => {
              setRating(e.target.value);
            }}
          />
        </InputGroup>

        <InputGroup className="input-group-option">
          <div className="div-rating-label">5</div>
          <Radio
            value={SyncFeedbackRating.Rating5}
            checked={rating === SyncFeedbackRating.Rating5}
            onChange={e => {
              setRating(e.target.value);
            }}
          />
        </InputGroup>
        <div className="extreme-sentiment-label">Excellent</div>
      </InputGroup>

      <div>{t('Why_this_rating')}</div>
      <Textarea
        className="feedback-text-message-area"
        value={feedbackMessage}
        maxLength={1024}
        onChange={e => {
          setFeedbackMessage(e.target.value);
        }}
      />

      <ModalButtonBar>
        <Button
          styleType="high-visibility"
          disabled={rating == SyncFeedbackRating.NoRating}
          onClick={() => submitFeedback()}
        >
          Submit
        </Button>
        <Button
          onClick={() => {
            localStorage.setItem('showSynchronizationFeedbackModal', 'false');
            setShowSynchronizationFeedbackModal(false);
            onClose();
          }}
        >
          Dismiss
        </Button>
      </ModalButtonBar>
    </Modal>
  ) : (
    <></>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from '../../hooks/useModal/useModal';
import { Text, Button, Modal, ModalButtonBar } from '@itwin/itwinui-react';

interface CollectDiagnosticsModalProps extends ModalProps {
  runJobAndHandle: () => Promise<void>;
}

export const CollectDiagnosticsModal: React.FC<CollectDiagnosticsModalProps> = (
  props: CollectDiagnosticsModalProps
) => {
  const { runJobAndHandle, ...modalProps } = props;
  const { onClose } = modalProps;
  const { t } = useTranslation();

  return (
    <Modal {...modalProps} title={t('CollectDiagnosticsModal_Title')}>
      <>
        <Text variant="leading" as="h3">
          {t('CollectDiagnostics_Message')}
        </Text>
        <ModalButtonBar>
          <Button
            styleType="high-visibility"
            onClick={() => {
              runJobAndHandle();
              onClose();
            }}
          >
            {t('OKBtn_Label')}
          </Button>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            {t('CancelBtn_Label')}
          </Button>
        </ModalButtonBar>
      </>
    </Modal>
  );
};

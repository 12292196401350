import React from 'react';
import {
  Repository,
  COMMUNITIES_EDIT_MAPPING,
} from '../../entities/repository';
import { Item } from '../../entities/item';
import { useTranslation } from 'react-i18next';
import { useItemListWithToggle } from '../../hooks/useItemListWithToggle/useItemListWithToggle';
import { TextLink } from '../textLink/textLink';
import { RepositoryFileSelection } from '../repositoryFileSelection/repositoryFileSelection';
import { useBridgeForFile } from '../../services/bridgeLogic/bridgeLogic';
import { JobDefinition } from '../../entities/jobDefinition/jobDefinition';
import { ExecutionStrategy } from '../../entities/jobDefinition/jobDefinitionDTOv2';
import { ModalProps } from '../../hooks/useModal/useModal';
import { Button, Modal, ModalButtonBar } from '@itwin/itwinui-react';

interface AddCompositeModelModalProps extends ModalProps {
  repository: Repository;
  items: Item[];
  onSave: (items: Item[]) => void;
  filesUsedByAnotherUser: string[];
  job: JobDefinition;
  jobDefinitionBridge: string;
  allowDifferentConnectorFilesForRepository?: boolean;
}

export const AddCompositeModelModal: React.FC<AddCompositeModelModalProps> = (
  props: AddCompositeModelModalProps
) => {
  const {
    repository,
    items,
    onSave,
    filesUsedByAnotherUser,
    job,
    jobDefinitionBridge,
    allowDifferentConnectorFilesForRepository,
    ...modalProps
  } = props;
  const { onClose } = modalProps;
  const { t } = useTranslation();

  const { isItemValidSpatialModel, getAllowedBridgeTypesForFile } =
    useBridgeForFile();
  const isItemSelectedAsCompositeModel = (item: Item): boolean => {
    const isItemCompositeModel = items.some(x => x && x.id === item.id);
    return isItemCompositeModel;
  };

  const isItemSelectable = (item: Item) => {
    return (
      isItemValidSpatialModel(item) &&
      !isItemSelectedAsCompositeModel(item) &&
      (job.executionStrategy === ExecutionStrategy.ExistingAffinity ||
        allowDifferentConnectorFilesForRepository ||
        getAllowedBridgeTypesForFile(item.name, repository).some(
          bridge => bridge === jobDefinitionBridge
        ))
    );
  };

  const {
    selection: compositeModelSelection,
    isSelected: isCompositeModelSelected,
    toggleIsSelected: toggleIsCompositeModelSelected,
    resetSelection,
  } = useItemListWithToggle();

  return (
    <Modal
      {...modalProps}
      title={t('ChangeMappingPage_AddCompositeModelModal_Title')}
      className="add-composite-model-modal"
    >
      <>
        <div className="add-composite-model-modal-container">
          <span className="select-composite-model-explanation">
            {t('ReadMoreAboutCompositeLimitations_Text')}
            <TextLink url={COMMUNITIES_EDIT_MAPPING}>
              {t('ReadMoreAboutLimitations_Link')}
            </TextLink>
          </span>
          <RepositoryFileSelection
            repository={repository}
            isItemSelectable={isItemSelectable}
            isSelected={isCompositeModelSelected}
            onSelection={toggleIsCompositeModelSelected}
            filesUsedByAnotherUser={filesUsedByAnotherUser}
            isAutoAffinityEnabled={
              job?.executionStrategy === ExecutionStrategy.Affinity
            }
          />
        </div>
        <ModalButtonBar>
          <Button
            styleType="high-visibility"
            onClick={() => {
              onSave(compositeModelSelection as Item[]);
              resetSelection();
            }}
            disabled={compositeModelSelection.length === 0}
          >
            {t('AddBtn_Label')}
          </Button>
          <Button
            onClick={() => {
              onClose();
              resetSelection();
            }}
          >
            {t('CancelBtn_Label')}
          </Button>
        </ModalButtonBar>
      </>
    </Modal>
  );
};

import * as LDClient from 'launchdarkly-js-client-sdk';
import React, { ProviderProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageLoader } from '../../components/fullPageLoader/fullPageLoader';
import { useBentleyContext } from '../bentleyContext/bentleyContext';
import { useUserContext } from '../userContext/userContext';

export interface FeatureToggles {
  projectShare: boolean;
  projectWise: boolean;
  projectWiseLegacy: boolean;
  minuteSchedule: boolean;
  adminJobDefCreate: boolean;
  obdBridgeOnlyPWLegacy: boolean;
  pwLegacyCheckbox: boolean;
  releaseLockOnDelete: boolean;
  defineBridgeStepEnabled: boolean;
  isregexInputEnabled: boolean;
  isCopyConnectionsUIEnabled: boolean;
  forceGeoSpatialBridgeAsLegacy: boolean;
  hostNameInput: boolean;
  migrateToV2Api: boolean;
  fileBridgeAssignment: boolean;
  apiVersionColumn: boolean;
  deleteModel: boolean;
  createV2Connection: boolean;
  showPreprocessorJob: boolean;
  showCopyJobId: boolean;
  autoAffinity: boolean;
  showThemeToggle: boolean;
  autoAffinityDgnOnly: boolean;
  connectorLogsPage: boolean;
  pw365: boolean;
  allowObdShare: boolean;
  allowProstructuresShare: boolean;
  disableSupportForV1: boolean;
  canRestoreAffinityReport: boolean;
  prostructuresConnector: boolean;
  ignoreImodelLevelPermissions: boolean;
  intelliPidConnector: boolean;
  filterV2Connections: boolean;
  workAreaIdInput: boolean;
  workAreaDropdown: boolean;
  hideFileReferences: boolean;
  isV2TokenStoreEnabled: boolean;
  syncReportUIv3: boolean;
  syncReportUIv3Feedback: boolean;
  enableSyncFeedbackModal: boolean;
  overrideEnableSyncFeedbackModal: boolean;
  enableSyncReportFullPage: boolean;
  enableEmailNotificationsModal: boolean;
  enableNotificationPopover: boolean;
  enableEmailNotificationFeedbackModal: boolean;
  enableIssueArticleFeedbackModal: boolean;
  enableSyncErrorTotals: boolean;
  enableOTLiteConnector: boolean;
  enablekmzFileSupport: boolean;
  showNewJobStatusLabel: boolean;
  shouldHideQueuedTime: boolean;
}

export const initialFeatures: FeatureToggles = {
  projectShare: false,
  projectWise: false,
  projectWiseLegacy: false,
  minuteSchedule: false,
  adminJobDefCreate: false,
  obdBridgeOnlyPWLegacy: false,
  pwLegacyCheckbox: false,
  releaseLockOnDelete: false,
  defineBridgeStepEnabled: false,
  isregexInputEnabled: false,
  isCopyConnectionsUIEnabled: false,
  forceGeoSpatialBridgeAsLegacy: false,
  hostNameInput: false,
  migrateToV2Api: false,
  fileBridgeAssignment: false,
  apiVersionColumn: false,
  deleteModel: false,
  createV2Connection: false,
  showPreprocessorJob: false,
  showCopyJobId: false,
  autoAffinity: false,
  showThemeToggle: false,
  autoAffinityDgnOnly: false,
  connectorLogsPage: false,
  pw365: false,
  allowObdShare: false,
  allowProstructuresShare: false,
  disableSupportForV1: false,
  canRestoreAffinityReport: false,
  prostructuresConnector: false,
  ignoreImodelLevelPermissions: false,
  intelliPidConnector: false,
  filterV2Connections: false,
  workAreaIdInput: false,
  workAreaDropdown: true,
  hideFileReferences: false,
  isV2TokenStoreEnabled: false,
  syncReportUIv3: false,
  syncReportUIv3Feedback: false,
  enableSyncFeedbackModal: false,
  overrideEnableSyncFeedbackModal: false,
  enableSyncReportFullPage: false,
  enableEmailNotificationsModal: false,
  enableNotificationPopover: false,
  enableEmailNotificationFeedbackModal: false,
  enableIssueArticleFeedbackModal: false,
  enableSyncErrorTotals: false,
  enableOTLiteConnector: false,
  enablekmzFileSupport: false,
  showNewJobStatusLabel: false,
  shouldHideQueuedTime: false,
};

export const FeatureToggleContext =
  React.createContext<FeatureToggles>(initialFeatures);

interface FeatureToggleContextProviderProps
  extends Omit<ProviderProps<FeatureToggles>, 'value'> {
  launchDarkleyClientId: string;
}

export function FeatureToggleContextProvider(
  props: FeatureToggleContextProviderProps
) {
  const { launchDarkleyClientId } = props;

  const { user } = useUserContext();
  const { projectId } = useBentleyContext();

  const [features, setFeatures] = useState<FeatureToggles>(initialFeatures);
  const [isLoaded, setIsLoaded] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const loadFeaturesAsyncWrapper = async () => {
      const toUpperCase = (str: string): string =>
        str ? str.toUpperCase() : '';

      // wait for user to load
      if (!user?.profile) {
        setIsLoaded(true);
        return;
      }

      const userData = {
        key: toUpperCase(user.profile.sub),
        name: toUpperCase(user.profile.email!),
        email: toUpperCase(user.profile.email!),
        custom: {
          UltimateId: !isNaN(user.profile.ultimate_site)
            ? Number(user.profile.ultimate_site)
            : 0,
          CountryIso: toUpperCase(user.profile.usage_country_iso),
          ImsId: toUpperCase(user.profile.sub),
          ContextId: toUpperCase(projectId),
        },
      };
      const client = await LDClient.initialize(launchDarkleyClientId, userData);

      await client.waitForInitialization();
      const features: FeatureToggles = {
        projectShare: client.variation('projectshare', false),
        projectWise: client.variation('projectwise', false),
        projectWiseLegacy: client.variation('projectwiselegacy', false),
        minuteSchedule: client.variation('minute-schedule', false),
        adminJobDefCreate: client.variation('admin-jobdef-create', false),
        obdBridgeOnlyPWLegacy: client.variation(
          'obd-bridge-only-pw-legacy',
          false
        ),
        pwLegacyCheckbox: client.variation('pw-legacy-checkbox', false),
        releaseLockOnDelete: client.variation('release-lock-on-delete', false),
        defineBridgeStepEnabled: client.variation('define-brigdes-step', false),
        isregexInputEnabled: client.variation('regex-input', false),
        isCopyConnectionsUIEnabled: client.variation('copy-connections', false),
        forceGeoSpatialBridgeAsLegacy: client.variation(
          'force-geospatial-pw-legacy',
          false
        ),
        hostNameInput: client.variation('host-name-input', false),
        migrateToV2Api: client.variation('migrate-to-v2-api', false),
        fileBridgeAssignment: client.variation('filebridgeassignment', false),
        apiVersionColumn: client.variation('api-version-column', false),
        deleteModel: client.variation('delete-model', false),
        createV2Connection: client.variation('create-v2-connection', false),
        showPreprocessorJob: client.variation('show-preprocessor-job', false),
        showCopyJobId: client.variation('show-copy-job-id', false),
        autoAffinity: client.variation('auto-affinity', false),
        showThemeToggle: client.variation('show-theme-toggle', false),
        autoAffinityDgnOnly: client.variation('auto-affinity-dgn-only', false),
        connectorLogsPage: client.variation('connector-logs-page', false),
        pw365: client.variation('pw365-tile', false),
        allowObdShare: client.variation('allow-obd-share', false),
        allowProstructuresShare: client.variation(
          'allow-prostructures-share',
          false
        ),
        disableSupportForV1: client.variation('disable-support-for-v1', false),
        canRestoreAffinityReport: client.variation(
          'can-restore-affinity-report',
          false
        ),
        prostructuresConnector: client.variation(
          'prostructures-connector',
          false
        ),
        ignoreImodelLevelPermissions: client.variation(
          'ignore-imodel-level-permissions',
          false
        ),
        intelliPidConnector: client.variation('intellipid-connector', false),
        filterV2Connections: client.variation(
          'filter-out-manifest-url-connections',
          false
        ),
        workAreaIdInput: client.variation('workarea-id-input', true), // A workaround when it is not possible to load all work areas
        workAreaDropdown: client.variation('workarea-dropdown', true), // should always be true, may just use to hide dropwdown for some users if they have workarea id input field enabled
        hideFileReferences: client.variation('hide-file-references', false),
        isV2TokenStoreEnabled: client.variation(
          'is-v2-token-store-enabled',
          false
        ),
        syncReportUIv3: client.variation('sync-report-ui-v3', false),
        syncReportUIv3Feedback: client.variation(
          'sync-report-ui-v3-feedback',
          false
        ),
        enableSyncFeedbackModal: client.variation(
          'show-sync-feedback-modal',
          false
        ),
        overrideEnableSyncFeedbackModal: client.variation(
          'override-show-sync-feedback-modal',
          false
        ),
        enableSyncReportFullPage: client.variation(
          'sync-report-full-page',
          false
        ),
        enableEmailNotificationsModal: client.variation(
          'enable-notifications-modal',
          false
        ),
        enableNotificationPopover: client.variation(
          'show-onboarding-popover',
          false
        ),
        enableEmailNotificationFeedbackModal: client.variation(
          'enable-notification-feedback-modal',
          false
        ),
        enableIssueArticleFeedbackModal: client.variation(
          'enable-issue-article-feedback-modal',
          false
        ),
        enableSyncErrorTotals: client.variation(
          'enable-sync-error-totals',
          false
        ),
        enableOTLiteConnector: client.variation(
          'enable-open-tower-lite-connector',
          false
        ),
        enablekmzFileSupport: client.variation(
          'enable-kmz-file-support',
          false
        ),
        showNewJobStatusLabel: client.variation(
          'show-new-job-status-label',
          false
        ),
        shouldHideQueuedTime: client.variation(
          'hide-queued-time',
          false
        )
      };
      setFeatures(features);
      setIsLoaded(true);
    };

    loadFeaturesAsyncWrapper();
  }, [user, launchDarkleyClientId, projectId]);

  return isLoaded ? (
    <FeatureToggleContext.Provider value={features} {...props} />
  ) : (
    <FullPageLoader>{t('FullPage_Loading_Message')}</FullPageLoader>
  );
}

export function useFeatureToggleContext() {
  const context = React.useContext(FeatureToggleContext);
  if (context == null) {
    throw new Error('useFeatureToggleContext must be used inside provider');
  }
  return context;
}

import React, { createContext, useCallback, useContext, useState } from 'react';
import { useBentleyContext } from '../bentleyContext/bentleyContext';
import {
  JobDefinition,
  JobDefinitionFile,
} from '../../entities/jobDefinition/jobDefinition';
import { useGetInputFiles } from '../../hooks/useGetInputFiles/useGetInputFiles';

export interface IInputFilesContext {
  connectionToFilesMap: Record<string, JobDefinitionFile[]>;
  areInputFilesLoading: boolean;
  fetchInputFiles: (
    jobDefinition: JobDefinition
  ) => Promise<JobDefinitionFile[] | null>;
  clearInputFilesMap: () => void;
}

export const InputFilesContext = createContext<IInputFilesContext>(
  {} as IInputFilesContext
);

export const InputFilesContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const { children } = props;

  const { projectId } = useBentleyContext();
  const [, areInputFilesLoading, , fetchInputFilesData] = useGetInputFiles(
    projectId
  );
  const [connectionToFilesMap, setConnectionToFileMap] = useState<
    Record<string, JobDefinitionFile[]>
  >({});

  const fetchInputFiles = useCallback(
    async (jobDefinition: JobDefinition) => {
      const inputFilesResponse = await fetchInputFilesData(jobDefinition);
      const fetchFilesError = JSON.parse(inputFilesResponse.error);

      if (
        inputFilesResponse.error &&
        fetchFilesError?.error?.ErrorCode === '404'
      ) {
        return [];
      }

      const newInputFiles = inputFilesResponse.data;
      if (newInputFiles) {
        setConnectionToFileMap(prev => ({
          ...prev,
          [jobDefinition.id]: newInputFiles,
        }));
      }
      return newInputFiles;
    },
    [fetchInputFilesData]
  );

  const clearInputFilesMap = useCallback(() => {
    setConnectionToFileMap({});
  }, []);

  return (
    <InputFilesContext.Provider
      value={{
        connectionToFilesMap,
        areInputFilesLoading,
        fetchInputFiles,
        clearInputFilesMap,
      }}
    >
      {children}
    </InputFilesContext.Provider>
  );
};

export const useInputFilesContext = () => {
  const context = useContext(InputFilesContext);
  if (context == null) {
    throw new Error('useInputFilesContext must be used inside provider');
  }
  return context;
};

import { JobDefinitionFile } from '../../entities/jobDefinition/jobDefinition';
import { useDataApi } from '../useDataApi/useDataApi';
import { RequestData, ResponseData } from '../useDataApi/requestData';
import { useCallback, useState } from 'react';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useDeleteInputFile = (
  projectId: string,
  iModelId: string
): [
  boolean,
  (jobId: string, inputFile: JobDefinitionFile) => Promise<ResponseData<null>>
] => {
  const { buddiUrls } = useBuddiContext();

  const useDataSettings = { initialData: null, initialIsLoading: true };
  const [, , , fetchData] = useDataApi<null>(useDataSettings);

  const [isLoading, setIsLoading] = useState(false);

  const deleteInputFile = useCallback(
    async (jobId: string, inputFile: JobDefinitionFile) => {
      const requestData: RequestData<null> = {
        url: `${buddiUrls.iModelBridgeServiceUrl}/api/ConnectionDefinitions('${jobId}')/InputFiles('${inputFile.id}')?contextId=${projectId}&iModelId=${iModelId}`,
        method: 'DELETE',
      };

      setIsLoading(true);
      let result = await fetchData(requestData);
      setIsLoading(false);

      return { ...result };
    },
    [fetchData]
  );

  return [isLoading, deleteInputFile];
};

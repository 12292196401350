import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BridgeType, getBridgeTypeDisplayName } from '../../../entities/bridge';
import { MasterContext } from '../../../hooks/useGetMasterReferencesTree/useGetMasterReferencesTree';
import { ModalProps } from '../../../hooks/useModal/useModal';
import {
  Button,
  LabeledSelect,
  Modal,
  ModalButtonBar,
} from '@itwin/itwinui-react';
import { Item } from '../../../entities/item';

type BridgeSelectionModalProps = ModalProps & {
  masterContexts: MasterContext[];
  selectedItems: Item[];
  setBridgeForSelected: (
    mastersContext: MasterContext[],
    bridge: BridgeType
  ) => void;
};

const getAvailableBridges = (
  masterContexts: MasterContext[],
  selectedItems: Item[]
): BridgeType[] => {
  let list: BridgeType[] = [];
  masterContexts.forEach(rootMaster => {
    Object.entries(rootMaster.mastersMap).forEach(([_, item]) => {
      Object.entries(item.bridgeSelection).forEach(([refKey, selection]) => {
        const bridgesToAdd = selectedItems.find((si: Item) => si.id === refKey)
          ? Object.entries(selection.bridges).map(
              ([bridgeType, _]) => bridgeType as BridgeType
            )
          : [];
        list = [...list, ...bridgesToAdd];
      });
    });
  });
  return [...new Set(list)];
};

export const BridgeSelectionModal = (props: BridgeSelectionModalProps) => {
  const { masterContexts, selectedItems, setBridgeForSelected, ...modalProps } =
    props;
  const { onClose } = modalProps;
  const { t } = useTranslation();

  const bridges = useMemo(
    () => getAvailableBridges(masterContexts, selectedItems),
    [masterContexts, selectedItems]
  );

  const [localBridge, setLocalBridge] = useState(bridges[0]);

  const handlePrimaryButton = () => {
    setBridgeForSelected(masterContexts, localBridge);
    onClose();
  };

  return (
    <Modal {...modalProps} title={t('ConnectorTypeDropDownInput_Label')}>
      <LabeledSelect
        label=""
        options={bridges.map(x => ({
          label: getBridgeTypeDisplayName(x),
          value: x,
        }))}
        value={localBridge}
        onChange={value => {
          setLocalBridge(value as BridgeType);
        }}
        style={{ width: '100%' }}
        data-testid="bridge-select"
      />
      <ModalButtonBar>
        <Button styleType="high-visibility" onClick={handlePrimaryButton}>
          {t('OKBtn_Label')}
        </Button>
        <Button onClick={onClose}>{t('CancelBtn_Label')}</Button>
      </ModalButtonBar>
    </Modal>
  );
};

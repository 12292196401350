import { assertUnreachable } from '../services/uiUtils/assertUnreachable';
import { Item } from './item';

export enum BridgeType {
  AffinityChecker = 'AFFINITYCHECKER',
  Preprocessor = 'PREPROCESSOR',
  Microstation = 'MSTN', //.dgn
  Revit = 'REVIT', //.rvt
  Dwg = 'DWG', //.dwg
  Vue = 'SPXREVIEW', //.vue
  Ifc = 'IFC', //.ifc
  AvevaPid = 'AVEVAPID', //.dwg
  SPPID = 'SPPID', //.zip, .pid
  AvevaDiagrams = 'AVEVADIAGRAMS', //.vsd
  Civil = 'CIVIL', // .dgn
  Civil3D = 'CIVIL3D', // .dwg
  Geospatial = 'GEOSPATIAL', // .shp
  AutoPlant = 'AUTOPLANT', // .dwg
  OBD = 'OBD', // .dgn
  OpenTower = 'OPENTOWER', // .xml
  Nwd = 'NWD', // .nwd .nwc
  ShellDWCSV = 'SHELLEDWCSV', // .cvs
  PSEXCEL = 'PSEXCEL',
  Prostructures = 'PROSTRUCTURES', // .dgn
  IntelliPid = 'INTELLIPID', // .json
  OpenTowerLite = 'OPENTOWERLITE', // .otxml
}

export const getBridgeTypeDisplayName = (bridgeType: BridgeType) => {
  switch (bridgeType) {
    case BridgeType.Preprocessor:
      return 'Preprocessor';
    case BridgeType.AffinityChecker:
      return 'Preprocessor';
    case BridgeType.Microstation:
      return 'MicroStation';
    case BridgeType.Dwg:
      return 'DWG';
    case BridgeType.Revit:
      return 'Revit';
    case BridgeType.Vue:
      return 'VUE';
    case BridgeType.Ifc:
      return 'IFC';
    case BridgeType.AvevaPid:
      return 'AvevaPID';
    case BridgeType.SPPID:
      return 'PlantSight SPPID';
    case BridgeType.AvevaDiagrams:
      return 'PlantSight AvevaDiagrams';
    case BridgeType.Civil:
      return 'Bentley Civil';
    case BridgeType.Civil3D:
      return 'Civil3D';
    case BridgeType.Geospatial:
      return 'Geospatial';
    case BridgeType.AutoPlant:
      return 'AutoPlant';
    case BridgeType.OBD:
      return 'OpenBuildings';
    case BridgeType.OpenTower:
      return 'OpenTower';
    case BridgeType.Nwd:
      return 'NWD';
    case BridgeType.ShellDWCSV:
      return 'EDW CSV';
    case BridgeType.PSEXCEL:
      return 'PSEXCEL';
    case BridgeType.Prostructures:
      return 'Prostructures';
    case BridgeType.IntelliPid:
      return 'IntelliPid';
    case BridgeType.OpenTowerLite:
      return 'OpenTower Lite';
    default:
      assertUnreachable(bridgeType);
      return '';
  }
};

export enum BridgeRepositoryType {
  ProjectWiseLegacy = '1',
  ShareFolder = '2',
  ShareFile = '3',
  ProjectWise = '4',
  PW365 = '5',
}

export interface FileBridgePair {
  file: Item;
  bridge: BridgeType;
}

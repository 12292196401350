import { useDataApi } from '../../hooks/useDataApi/useDataApi';
import {
  productSettingsJobsNamespace,
  JobDefinition,
} from '../../entities/jobDefinition/jobDefinition';
import { RequestData, ResponseData } from '../../hooks/useDataApi/requestData';
import { useCallback } from 'react';
import { v4 as newGuid } from 'uuid';
import { useUsageLogger } from '../../context/usageLoggerContext/usageLoggerContext';
import { iModelHubAppId } from '../../constants/constants';
import { Features } from '../../context/usageLoggerContext/featureConstants';
import { useFeatureToggleContext } from '../../context/featureToggleContext/featureToggleContext';
import {
  JobDefinitionDto,
  JobDefinitionPropertiesDto,
} from '../../entities/jobDefinition/jobDefintionDTO';
import { jobDefinitionToJobDefinitionPropertiesDtoMap } from '../../entities/jobDefinition/jobDefinitionMappings';
import { useBuddiContext } from '../../context/buddiContext/buddiContext';

export const useCopyConnection = (
  projectId: string,
  iModelId: string
): [
  JobDefinitionDto,
  boolean,
  any,
  (job: JobDefinition) => Promise<ResponseData<JobDefinitionDto>>
] => {
  const { logFeature } = useUsageLogger();
  const { buddiUrls } = useBuddiContext();
  const features = useFeatureToggleContext();

  const useDataSettings = { initialIsLoading: false, initialData: null as any };
  const [data, isLoading, error, fetchData] = useDataApi<JobDefinitionDto>(
    useDataSettings
  );

  const copyConnection = useCallback(
    (job: JobDefinition) => {
      const uniqueName = newGuid();

      const jobDef = getCopyJobBody(job);
      const name = job.name;
      const bridge = job.files[0].bridgeType;
      const repositoryType = job.repositoryType;
      const files = job.files;

      let requestData: RequestData<any> = {} as RequestData<any>;
      if (features.adminJobDefCreate) {
        requestData = {
          url: `/api/ProductSettings/CreateJobDefinition`,
          map: (x: any) => x,
          method: 'POST',
          body: JSON.stringify({
            projectId,
            iModelId,
            applicationId: iModelHubAppId,
            jobDefinition: jobDef,
          }),
        };
      } else {
        const url =
          `${buddiUrls.productSettingsServiceUrl}/v1.0/Application/` +
          `${iModelHubAppId}/Context/${projectId}/iModel/${iModelId}/Setting/${productSettingsJobsNamespace}/${uniqueName}`;
        requestData = {
          url: url,
          map: (x: any) => x,
          method: 'PUT',
          body: jobDef,
        };
      }

      logFeature(Features.CopyConnection, [
        { name: 'jobName', value: name },
        {
          name: 'bridgeType',
          value: bridge,
        },
        {
          name: 'repositoryType',
          value: repositoryType,
        },
        {
          name: 'fileCount',
          value: files.length.toString(),
        },
      ]);

      return fetchData(requestData);
    },
    [projectId, iModelId, logFeature, fetchData, buddiUrls]
  );

  return [data, isLoading, error, copyConnection];
};

export const getCopyJobBody = (job: JobDefinition) => {
  return JSON.stringify({
    id: '',
    name: job.uniqueSettingName,
    namespace: productSettingsJobsNamespace,
    properties: {
      ...jobDefinitionToJobDefinitionPropertiesDtoMap(job),
    } as JobDefinitionPropertiesDto,
  } as JobDefinitionDto);
};

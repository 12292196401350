import { SvgNetwork } from '@itwin/itwinui-icons-react';
import { Tooltip } from '@itwin/itwinui-react';
import React from 'react';
import { Icon } from '../icons/icon';
import './spatialRootIcon.scss';

interface SpatialRootIconProps {
  toolTipText: string;
}

export const SpatialRootIcon: React.FC<SpatialRootIconProps> = (
  props: SpatialRootIconProps
) => {
  const { toolTipText } = props;

  return (
    <Tooltip content={toolTipText}>
      <div className="spatial-icon-wrapper">
        <Icon
          icon={SvgNetwork}
          color="default"
          data-testid="spatial-root-icon"
        />
      </div>
    </Tooltip>
  );
};

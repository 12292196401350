import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  LabeledSelect,
  Modal,
  ModalProps,
} from '@itwin/itwinui-react';
import {
  SvgChevronDown,
  SvgChevronUp,
  SvgFeedback,
} from '@itwin/itwinui-icons-react';
import './feedbackModal.scss';
import { InputContainer } from '@itwin/itwinui-react/cjs/core/utils';
import { useToast } from '../../../context/toastContext/toastContext';
import { useTranslation } from 'react-i18next';
import { useUsageLogger } from '../../../context/usageLoggerContext/usageLoggerContext';
import { Events } from '../../../context/usageLoggerContext/eventConstants';
import { useFeatureToggleContext } from '../../../context/featureToggleContext/featureToggleContext';

export const FeedbackModal: React.FC<ModalProps> = (props: ModalProps) => {
  const [expandedView, setExpandedView] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [feedbackDetails, setFeedbackDetails] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const { logEvent } = useUsageLogger();
  const { toastSuccess } = useToast();
  const { t } = useTranslation();

  const { syncReportUIv3, syncReportUIv3Feedback } = useFeatureToggleContext();

  // workaround for disabling the feedback modal backdrop, when has selector will be enabled in firefox use has selector to write scss to apply the styles
  useEffect(() => {
    const waitForFeedback = async () => {
      await waitForFeedbackModalElement('.feedback-minimized-modal');

      if (document.getElementsByClassName('feedback-minimized-modal')[0]) {
        document
          .getElementsByClassName('feedback-minimized-modal')[0]
          .previousElementSibling?.previousElementSibling?.setAttribute(
            'style',
            'pointer-events: auto; height: 0'
          );
      }
    };
    waitForFeedback();
  }, []);

  const waitForFeedbackModalElement = (selector: any) => {
    return new Promise(resolve => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(mutations => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  const submitFeedback = () => {
    logEvent(Events.FeedbackSubmit, [
      { name: 'selectedFeedback', value: selectedOption },
      {
        name: 'feedbackDetails',
        value: feedbackDetails,
      },
      {
        name: 'contactEmail',
        value: contactEmail,
      },
      {
        name: 'synchronizationReportVersion',
        value: syncReportUIv3 ? '2.1.0-dev.3' : '2.0.2',
      },
    ]);

    setExpandedView(false);
    setSelectedOption('Select an option');
    toastSuccess(t('Received_Feedback_Label'));
  };

  return syncReportUIv3Feedback ? (
    <Modal
      {...props}
      className={
        expandedView ? 'feedback-expanded-modal' : 'feedback-minimized-modal'
      }
    >
      <Button
        className={
          expandedView
            ? 'feedback-expanded-button'
            : 'feedback-minimized-button'
        }
        styleType="high-visibility"
        onClick={() => setExpandedView(!expandedView)}
        startIcon={<SvgFeedback />}
        endIcon={expandedView ? <SvgChevronDown /> : <SvgChevronUp />}
      >
        {t('Give_Us_Feedback')}
      </Button>
      {expandedView && (
        <>
          <div
            className="feedback-expanded-subtext"
            style={{ paddingTop: '12px' }}
          >
            {t('How_Do_You_Compare_This_Report_To_The_Previous_Version')}
            <span style={{ color: 'red' }}>*</span>
          </div>
          <LabeledSelect
            className="feedback-expanded-labeled-select"
            placeholder={t('Select an option')}
            value={selectedOption}
            role="menu"
            options={[
              {
                label: t('Better_Label'),
                value: 'Better',
              },
              {
                label: t('Worse_Label'),
                value: 'Worse',
              },
              {
                label: t('Equally_Good_Label'),
                value: 'Equally good',
              },
              {
                label: t('Equally_Bad_Label'),
                value: 'Equally bad',
              },
              {
                label: t('Neither_Better_Or_Worse_Label'),
                value: 'Neither better or worse',
              },
            ]}
            onChange={value => {
              setSelectedOption(value);
            }}
          />
          <div className="feedback-expanded-subtext">{t('Feedback')}</div>
          <label className="feedback-input-container">
            <textarea
              rows={10}
              maxLength={1000}
              className="feedback-input"
              onChange={value => setFeedbackDetails(value.target.value)}
            ></textarea>
          </label>
          <div className="feedback-expanded-subtext">{t('Add_Your_Email')}</div>
          <InputContainer className="feedback-email-input">
            <Input
              placeholder="address@email.com"
              maxLength={50}
              onChange={value => setContactEmail(value.target.value)}
            ></Input>
          </InputContainer>
          <Button
            className="submit-feedback-expanded-button"
            styleType="high-visibility"
            onClick={() => submitFeedback()}
            disabled={selectedOption.length === 0}
          >
            {t('Submit_Feedback')}
          </Button>
        </>
      )}
    </Modal>
  ) : (
    <></>
  );
};

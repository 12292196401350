import React from 'react';
import { NonIdealState } from '@itwin/itwinui-react';
import './components/layout/layout.scss';
import { AppContextProvider } from './context/appContext/appContext';
import { InstantOnApplicationWrapper } from './pages/instantOnApplication/instantOnApplicationWrapper';
import GenericErrorBoundary from './components/errorBoundary/genericErrorBoundary';
import { ThemeContextProvider } from './context/themeContext/themeContext';
import { IModelContextProvider } from './context/navigationContext/iModelNameContext';
import { ProjectContextProvider } from './context/projectContext/projectContext';
import { SynchronizationComponentsContext } from './context/synchronizationComponentsContext/synchronizationComponentsContext';
import { Router } from '@reach/router';
import { PrivateRoute } from './components/privateRoute/privateRoute';
import { CompleteSignIn } from './pages/completeSignIn/completeSignIn';
import { CompleteForgeSignIn } from './pages/completeForgeSignIn/completeForgeSignIn';
import { CompleteSignOut } from './pages/completeSignOut/completeSignOut';
import { LogOut } from './pages/logout/logOut';
import { Login } from './pages/login/login';
import { Authenticate } from './pages/authenticate/authenticate';
import './App.scss';
import { SvgError } from '@itwin/itwinui-illustrations-react';
import { PublicRouteForError } from './components/publicRouteForError/publicRouteForError';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ThemeContextProvider>
      <GenericErrorBoundary>
        <AppContextProvider>
          <IModelContextProvider>
            <ProjectContextProvider>
              <SynchronizationComponentsContext>
                <Router style={{ height: '100%', position: 'relative' }}>
                  <PrivateRoute
                    component={InstantOnApplicationWrapper}
                    path="/:projectId/*"
                  />
                  <CompleteSignIn path="/signin-oidc" />
                  <CompleteForgeSignIn path="/forge-redirect" />
                  <CompleteSignOut path="/signout-oidc" />
                  <LogOut path="/logout/:projectId/:iModelId" />
                  <LogOut path="/logout/:projectId" />
                  <Login path="/login/*" />
                  <PrivateRoute
                    path="/"
                    component={() => (
                      <div className="fit-to-parent">
                        <NonIdealState
                          svg={<SvgError />}
                          heading={t("No_Project_Selected")}
                          description={t("No_Project_Selected_Description")}
                        />
                      </div>
                    )}
                  />
                  <PublicRouteForError path="/errorPage" />
                  <Authenticate path="/authenticate" />
                </Router>
              </SynchronizationComponentsContext>
            </ProjectContextProvider>
          </IModelContextProvider>
        </AppContextProvider>
      </GenericErrorBoundary>
    </ThemeContextProvider>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import {
  CronMinuteSchedule,
  CronMinuteScheduleDisplayNames,
  getMinuteCronExpression,
  getMinuteScheduleFromCronString as getEveryXMinuteScheduleFromCronString,
  CRON_IGNORE,
} from '../../../../entities/cronSchedule';
import { useTranslation } from 'react-i18next';
import { Radio, Select } from '@itwin/itwinui-react';

interface MinuteJobScheduleSelectionProps {
  isSelected: boolean;
  schedule: string;
  setSchedule: (schedule: string) => void;
}

export const MinuteJobScheduleSelection = (
  props: MinuteJobScheduleSelectionProps
) => {
  const { t } = useTranslation();
  const { setSchedule, schedule, isSelected } = props;

  const [minuteSchedule, setMinuteSchedule] = useState<string>(
    CronMinuteSchedule.Minute5
  );

  useEffect(() => {
    if (!isSelected) {
      return;
    }
    const newMinuteSchedule = getEveryXMinuteScheduleFromCronString(schedule);
    if (
      newMinuteSchedule !== CRON_IGNORE &&
      newMinuteSchedule !== CronMinuteSchedule.NotApplicable
    ) {
      setMinuteSchedule(newMinuteSchedule);
    }
  }, [isSelected, schedule]);

  return (
    <div className="schedule-option">
      <Radio
        label={t('JobScheduleEachMinute_Label')}
        name="schedule-sync"
        onChange={() => {
          setSchedule(getMinuteCronExpression(minuteSchedule));
        }}
        checked={isSelected}
      />
      <div className="day-time-picker">
        <Select
          data-testid="minute-select"
          className="time-select-long"
          options={CronMinuteScheduleDisplayNames}
          value={minuteSchedule}
          onChange={value => {
            setMinuteSchedule(value);
            setSchedule(getMinuteCronExpression(value));
          }}
          disabled={!isSelected}
        />
      </div>
    </div>
  );
};
